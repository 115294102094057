import React from "react"
import en from "json/locales/en.json"

interface ILocaleContextState {
    localize: (tag: string, replace?: Record<string, string>) => string;
}

export const LocaleContext = React.createContext<ILocaleContextState>({} as ILocaleContextState);

const locale = en;

const LocaleProvider: React.FC<{children?: React.ReactNode}> = ({children}) => {

    const localize = (tag: string, replace?: Record<string, string>): string => {
        const reduced = tag.split(".").reduce(
            (obj: Record<string, any>, key: string) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
            locale
        );
        
        const text = reduced ? String(reduced) : "";

        if (replace) {
            const re = new RegExp("#" + Object.keys(replace).join("|#"), "gi");
            return text.replace(re, (matched: string) => {
                return replace[matched.substring(1)];
            });
        }

        return text;
    }

    return (
        <LocaleContext.Provider value={{ localize }}>
            {children}
        </LocaleContext.Provider>
    )
}

export default LocaleProvider;