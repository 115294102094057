import { ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    ListItemIcon,
    Skeleton,
    Typography
} from "@mui/material";
import React from "react";

export enum DeviationType {
    Warning = "warning",
    Error = "error"
}


interface IStatusAccordionProps {
    icon?: React.ReactNode;
    loading: boolean;
    numberOfDeviations?: number;
    title: React.ReactNode;
    deviationType?: DeviationType;
    children?: React.ReactNode;
}

export const StatusAccordion: React.FC<IStatusAccordionProps> = ({
    icon,
    loading,
    title,
    numberOfDeviations=0,
    deviationType=DeviationType.Warning,
    children
}) => {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Accordion
            square
            disableGutters
            elevation={0}
            expanded={expanded}
            onChange={(event: React.SyntheticEvent, expanded: boolean) => setExpanded(expanded)}
            defaultExpanded={numberOfDeviations > 0}
            sx={{
                bgcolor: (theme) => theme.palette.secondary.light,
                ...(numberOfDeviations > 0 && {
                    bgcolor: (theme) => theme.palette.secondary.main,
                }),
            }}
        >
            <AccordionSummary
                sx={{padding: { xs: "0 16px", sm: "0 64px" }}}
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <ListItemIcon sx={{marginTop: "4.5px", color: (theme) => theme.palette.grey[800]}}>
                    <Badge
                        badgeContent={numberOfDeviations}
                        color={deviationType}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                    >
                        {icon}
                    </Badge>
                </ListItemIcon>
                <Typography variant="h4">
                    {loading ? <Skeleton variant="text" width="7rem" /> : title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
        
    );
};