
import { createSvgIcon } from "@mui/material";
import React from "react";

export const ActiveFireAlarmIcon = createSvgIcon(
    <path d="M13.66 8.7C13.43 8.4 13.15 8.14 12.89 7.88C12.22 7.28 11.46 6.85 10.82 6.22C9.33005 4.76 9.00005 2.35 9.95005 0.5C9.00005 0.73 8.17005 1.25 7.46005 1.82C4.87005 3.9 3.85005 7.57 5.07005 10.72C5.11005 10.82 5.15005 10.92 5.15005 11.05C5.15005 11.27 5.00005 11.47 4.80005 11.55C4.57005 11.65 4.33005 11.59 4.14005 11.43C4.08005 11.38 4.04005 11.33 4.00005 11.26C2.87005 9.83 2.69005 7.78 3.45005 6.14C1.78005 7.5 0.87005 9.8 1.00005 11.97C1.06005 12.47 1.12005 12.97 1.29005 13.47C1.43005 14.07 1.70005 14.67 2.00005 15.2C3.08005 16.93 4.95005 18.17 6.96005 18.42C9.10005 18.69 11.39 18.3 13.0301 16.82C14.8601 15.16 15.5 12.5 14.56 10.22L14.43 9.96C14.22 9.5 13.66 8.7 13.66 8.7ZM10.5 15C10.22 15.24 9.76005 15.5 9.40005 15.6C8.28005 16 7.16005 15.44 6.50005 14.78C7.69005 14.5 8.40005 13.62 8.61005 12.73C8.78005 11.93 8.46005 11.27 8.33005 10.5C8.21005 9.76 8.23005 9.13 8.50005 8.44C8.69005 8.82 8.89005 9.2 9.13005 9.5C9.90005 10.5 11.11 10.94 11.37 12.3C11.41 12.44 11.43 12.58 11.43 12.73C11.46 13.55 11.1 14.45 10.5 15Z" />,
    "ActiveFireAlarmIcon"
);


export const DisablementIcon = createSvgIcon(
    <>
        <defs />
        <path d="M19.9633 2.61374C19.9633 3.50506 19.2931 4.22748 18.4662 4.22748C17.6393 4.22748 16.9691 3.50506 16.9691 2.61374C16.9691 1.72242 17.6393 1 18.4662 1C19.2931 1 19.9633 1.72242 19.9633 2.61374Z" stroke="currentColor" fill="none" strokeWidth="1.34414" strokeMiterlimit="10"/>
        <path d="M19.9633 18.3907C19.9633 19.282 19.2931 20.0045 18.4662 20.0045C17.6393 20.0045 16.9691 19.282 16.9691 18.3907C16.9691 17.4994 17.6393 16.777 18.4662 16.777C19.2931 16.777 19.9633 17.4994 19.9633 18.3907Z" fill="currentColor" stroke="currentColor" strokeWidth="1.34414" strokeMiterlimit="10"/>
        <path d="M18.5063 18.3907H5.40771V2.6137H13.6128" fill="none" stroke="currentColor" strokeWidth="1.83217" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.81507 10.5023C9.81507 13.1262 7.84176 15.2532 5.40754 15.2532C2.97331 15.2532 1 13.1262 1 10.5023C1 7.87835 2.97331 5.75127 5.40754 5.75127C7.84176 5.75127 9.81507 7.87835 9.81507 10.5023Z" fill="currentColor" />
    </>,
    "DisablementIcon"
);




export const TechWarnIcon = createSvgIcon(
    <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.64779 0.134766V3.14344C7.79342 3.48614 7.01393 3.98162 6.3414 4.59703L3.78865 3.12335L0.983234 7.98234L3.82902 9.62519C3.80229 9.89139 3.78859 10.1616 3.78859 10.435C3.78859 10.8956 3.82746 11.347 3.90202 11.7858L3.87331 11.7361L0.983294 13.4044L3.78871 18.2634L6.67872 16.5951L6.64516 16.5369C7.24057 17.0274 7.90927 17.4285 8.63177 17.7202V20.5002H14.2426L14.2426 17.72C14.8931 17.4573 15.5001 17.1059 16.0491 16.6805L18.7913 18.2635L21.5967 13.4045L18.9554 11.8797C19.0407 11.4115 19.0854 10.9286 19.0854 10.435C19.0854 10.0201 19.0538 9.6128 18.9931 9.21538L19.11 9.41788L22 7.7495L19.1946 2.8905L16.4174 4.49375C15.7803 3.93368 15.0521 3.47912 14.2586 3.15655V0.134766L8.64779 0.134766ZM11.4372 4.02567C7.8973 4.02567 5.02779 6.89518 5.02779 10.4351C5.02779 13.975 7.8973 16.8446 11.4372 16.8446C14.9765 16.8446 17.846 13.975 17.846 10.4351C17.846 6.89518 14.9765 4.02567 11.4372 4.02567Z" />
        <path d="M11.437 6.14697V11.9424" stroke="currentColor" strokeWidth="1.82349" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M10.5423 14.6993C10.5423 14.2007 10.9461 13.7969 11.4447 13.7969C11.9434 13.7969 12.3472 14.2007 12.3472 14.6993C12.3472 15.198 11.9434 15.6018 11.4447 15.6018C10.9461 15.6018 10.5423 15.198 10.5423 14.6993Z" />
    </g>,
    "TechWarnIcon"
);


export const WebFunctionDisablementIcon = createSvgIcon(
    <path d="M11.82 12.5H7.66C7.56 11.84 7.5 11.18 7.5 10.5C7.5 9.82 7.56 9.15 7.66 8.5H12.34C12.43 9.15 12.5 9.82 12.5 10.5C12.5 11 12.46 11.5 12.4 11.96C13 11.46 13.72 11.07 14.5 10.82C14.5 10.71 14.5 10.61 14.5 10.5C14.5 9.82 14.44 9.16 14.36 8.5H17.74C17.9 9.14 18 9.81 18 10.5C18 10.56 18 10.62 18 10.68C18.7 10.85 19.35 11.13 19.95 11.5C20 11.18 20 10.84 20 10.5C20 5 15.5 0.5 10 0.5C4.47 0.5 0 5 0 10.5C0 16 4.5 20.5 10 20.5C10.34 20.5 10.68 20.5 11 20.45C10.59 19.79 10.29 19.05 10.13 18.25C10.09 18.32 10.05 18.39 10 18.46C9.17 17.26 8.5 15.93 8.09 14.5H10.5C10.81 13.75 11.26 13.08 11.82 12.5ZM16.92 6.5H13.97C13.65 5.25 13.19 4.05 12.59 2.94C14.43 3.57 15.96 4.84 16.92 6.5ZM10 2.53C10.83 3.73 11.5 5.07 11.91 6.5H8.09C8.5 5.07 9.17 3.73 10 2.53ZM2.26 12.5C2.1 11.86 2 11.19 2 10.5C2 9.81 2.1 9.14 2.26 8.5H5.64C5.56 9.16 5.5 9.82 5.5 10.5C5.5 11.18 5.56 11.84 5.64 12.5H2.26ZM3.08 14.5H6C6.35 15.75 6.8 16.95 7.4 18.06C5.57 17.43 4.03 16.15 3.08 14.5ZM6 6.5H3.08C4.03 4.84 5.57 3.56 7.4 2.94C6.8 4.05 6.35 5.25 6 6.5ZM16.5 12.5C14 12.5 12 14.5 12 17C12 19.5 14 21.5 16.5 21.5C19 21.5 21 19.5 21 17C21 14.5 19 12.5 16.5 12.5ZM16.5 20C14.84 20 13.5 18.66 13.5 17C13.5 16.44 13.65 15.92 13.92 15.5L18 19.58C17.58 19.85 17.06 20 16.5 20ZM19.08 18.5L15 14.42C15.42 14.15 15.94 14 16.5 14C18.16 14 19.5 15.34 19.5 17C19.5 17.56 19.35 18.08 19.08 18.5Z"/>,
    "WebFunctionDisablementIcon"
);

export const DoorOpenIcon = createSvgIcon(
    <path d="M12 3.5C10.89 3.5 10 4.39 10 5.5H3V19.5H2V21.5H22V19.5H21V5.5C21 4.39 20.11 3.5 19 3.5H12ZM12 5.5H19V19.5H12V5.5ZM5 11.5H7V13.5H5V11.5Z"/>,
    "DoorOpenIcon"
);

export const InterlockingIcon = createSvgIcon(
    <g>
        <path fill="none" d="M4.20862 15.4248H21.0448C22.5209 15.4248 23.7171 15.0911 23.7171 14.6797V11.3082" stroke="currentColor" strokeWidth="2.64845" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M1.14566 15.173L4.75992 13.086C5.05036 12.9183 5.41408 13.1284 5.41408 13.4639V17.3853C5.41408 17.7217 5.05036 17.9309 4.75992 17.7632L1.14566 15.6771C0.951445 15.565 0.951445 15.2851 1.14566 15.173Z" />
        <path fill="none" d="M20.7381 8.8971H3.9019C2.42583 8.8971 1.22961 9.2308 1.22961 9.64219V13.0137" stroke="currentColor" strokeWidth="2.64845" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M23.8009 9.14897L20.1866 11.236C19.8962 11.4037 19.5325 11.1936 19.5325 10.8581V6.93663C19.5325 6.60028 19.8962 6.39105 20.1866 6.55879L23.8009 8.64488C23.9951 8.757 23.9951 9.03685 23.8009 9.14897Z" />
    </g>,
    "InterlockingIcon"
);
