import { LocaleContext } from "contexts/LocaleContext";
import React from "react";

import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Skeleton, Typography } from "@mui/material";

import { StatusAccordionContainer } from "components/StyledComponents/Accordion";
import {
    FireAlarmStatusAccordion,
    PreFireAlarmStatusAccordion,
    FaultStatusAccordion,
    DisablementStatusAccordion,
    TechWarnStatusAccordion,
    InterlockingStatusAccordion,
    DoorOpenStatusAccordion,
    TestZonesStatusAccordion,
    ServiceSignalStatusAccordion,
    OutputActivationStatusAccordion,
    CommunicationErrorStatusAccordion
} from "components/status/accordions";
import useDateFormat from "hooks/useDateFormat";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";

const Status: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const params = useParams();
    const navigate = useNavigate();
    const { formatDate } = useDateFormat();
    const { alarmSystem, isLoading } = useGetAlarmSystem();
    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" sx={{
                    marginBottom: "1.375em",
                    color: (theme) => theme.palette.primary.main,
                    display: { xs: "none", sm: "inherit" }
                }}>
                    {isLoading ? <Skeleton width="10rem" /> : `${alarmSystem?.name} / ${localize("navigation.status")} / ${localize("navigation.deviations")}`}
                </Typography>
                <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={() => navigate(`/${alarmSystem?.local_office}/configuration/${params.id}`)}
                    sx={{
                        marginBottom: "2.750em",
                        padding: "12px 24px",
                        display: { xs: "none", sm: "inherit" }
                    }}>
                    <Typography variant="button">
                        {localize("status.detail-button")}
                    </Typography>
                </Button>
            </Box>
            <StatusAccordionContainer>
                <FireAlarmStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <PreFireAlarmStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <FaultStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <DisablementStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <TechWarnStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <InterlockingStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <DoorOpenStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <TestZonesStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <ServiceSignalStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <OutputActivationStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <CommunicationErrorStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
            </StatusAccordionContainer>
        </>
    );
};

export default Status;