
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';


export const StatusAccordionContainer = styled(Box)
    (({theme}) => ({
        [theme.breakpoints.up("sm")]: {
            position: "relative", 
            left: "-64px", 
            width: "calc(100% + 128px)",
        }
    })
);