import { IFireAlarmSystem } from "interfaces/fireAlarmSystem";

export const getMacAddress = (alarmSystem: IFireAlarmSystem | undefined): string => {
    if (alarmSystem) {
        const gateways = alarmSystem.gateways.filter(gw => gw.disconnected === null);
        if (gateways.length) {
            return gateways[0].mac_address;
        }
    }
    return "-";
};