import { fetchInvites } from "adapters/userManagement";
import { Delete, ReadMore } from "@mui/icons-material";
import { Box, IconButton, List, ListItem, ListItemText, Skeleton } from "@mui/material";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { BaseDialog } from "components/StyledComponents/Dialog";
import { IInvite } from "interfaces/userManagement";
import React from "react";
import ConfirmDeleteInvitation from "./ConfirmDeleteInvitation";
import { useHasPermission } from "hooks/useHasPermission";
import { useInView } from "react-intersection-observer";
import { isLastInArray } from "utils/arrays";
import GetInvitationDetails from "./GetInvitationDetails";
import theme from "styling/theme";
import InfiniteScrollList from "components/InfiniteScrollList";
import { LocaleContext } from "contexts/LocaleContext";

interface IListInvitationsProps {
    contextObjectId?: string
    organizationId?: string | null;
}

const ListInvitations: React.FC<IListInvitationsProps> = ({ contextObjectId, organizationId }) => {
    const { hasPermission } = useHasPermission();
    const { ref } = useInView()
    const [selectedInvitationForDeletion, setInvitationForDeletion] = React.useState<IInvite | null>(null);
    const [selectedInvitationForDetails, setInvitationForDetails] = React.useState<IInvite | null>(null);
    const { localize } = React.useContext(LocaleContext);

    const inviteQuery = useInfiniteQuery({
        queryKey: ["invites"],
        queryFn: async ({ pageParam }) => {
            const params = {
                page: pageParam,
                context_object_id: contextObjectId,
                org_id: organizationId
            };
            const res = await fetchInvites({ params: params })
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    const handleClose = (): void => {
        setInvitationForDeletion(null);
        setInvitationForDetails(null);
    };

    const renderInvitationList = (): React.ReactNode => {
        return (
            <Box height={"30vh"}>
                <InfiniteScrollList
                    fetchNextPage={inviteQuery.fetchNextPage}
                    isFetchingNextPage={inviteQuery.isFetchingNextPage}
                    hasNextPage={!!inviteQuery.hasNextPage}
                    isLoading={inviteQuery.isLoading}
                >
                    {inviteQuery.data?.pages.every(datablock => datablock.data.length === 0) ? (
                        <ListItem>
                            {localize("invitation.no-invitations-sent")}
                        </ListItem>
                    ) :
                        inviteQuery.data?.pages.map((group, i) => group.data.map((invite, j) => (
                            <ListItem
                                key={invite.id}
                                dense
                                disablePadding
                                ref={isLastInArray(inviteQuery.data?.pages, i) && isLastInArray(group.data, j) ? ref : null}
                                style={{
                                    backgroundColor: selectedInvitationForDetails?.id === invite.id || selectedInvitationForDeletion?.id === invite.id ?
                                        theme.palette.background.paper : theme.palette.background.default,
                                    width: "100%",
                                    borderRadius: "6px"
                                }}
                            >
                                <ListItemText style={{ wordBreak: "break-all" }} primaryTypographyProps={{ sx: { paddingRight: '20px' }, variant: "body1" }}>
                                    {invite.email}
                                </ListItemText>
                                <IconButton onClick={() => setInvitationForDetails(invite)}>
                                    <ReadMore />
                                </IconButton>
                                {hasPermission("roleassignmentinvitation", "delete_roleassignmentinvitation") && (
                                    <IconButton onClick={() => setInvitationForDeletion(invite)}>
                                        <Delete />
                                    </IconButton>
                                )}
                            </ListItem>
                        )))}
                    {inviteQuery.isFetchingNextPage && ref != null && (
                        <>
                            <ListItem dense disablePadding key={1}>
                                <Skeleton width={"100%"} />
                            </ListItem>
                        </>
                    )}
                </InfiniteScrollList>
            </Box>

        );
    };

    return (
        <>
            <List disablePadding sx={{ maxWidth: "500px" }}>
                {renderInvitationList()}
            </List>
            <BaseDialog open={!!selectedInvitationForDeletion} onClose={handleClose}>
                {selectedInvitationForDeletion && <ConfirmDeleteInvitation invitation={selectedInvitationForDeletion} onClose={handleClose} />}
            </BaseDialog>
            <BaseDialog open={!!selectedInvitationForDetails} onClose={handleClose} maxWidth={"xs"} fullWidth>
                {selectedInvitationForDetails && <GetInvitationDetails invitation={selectedInvitationForDetails} onClose={handleClose} />}
            </BaseDialog>
        </>
    )

};

export default ListInvitations;