
import { createTheme } from '@mui/material/styles';

const colors = {
  blueLight: "#035096",
  cynBlue: "#dbe2e9",
  darkBlue: "#003b68",
  error: "#FC1921",
  warning: "#FFB300",
  success: "#4FAD26",
  greyDark: "#484848",
  grey: "#A3A3A3",
  greyLight: "#C1C1C1",
  greySoft: "#F3F3F3",
  white: "#FFF"
}

const theme = createTheme({
  palette: {
    primary: {
      main: colors.darkBlue,
      light: colors.blueLight,
    },
    secondary: {
      main: colors.greySoft,
      light: colors.white
    },
    error: {
      main: colors.error,
      contrastText: colors.white
    },
    warning: {
      main: colors.warning,
      contrastText: colors.darkBlue
    },
    success: {
      main: colors.success,
    },
    text: {
      primary: colors.greyDark,
    },
    background: {
      default: colors.white,
      paper: colors.cynBlue
    },
    grey: {
      100: colors.greySoft,
      400: colors.greyLight,
      600: colors.grey,
      800: colors.greyDark,
    },
    divider: colors.greyLight
  },
  typography: {
    h1: {
      fontSize: "27px",
      fontWeight: "400",
      lineHeight: "40.5px"
    },
    h2: {
      fontSize: "25px",
      fontWeight: "700",
      lineHeight: "37.5px"
    },
    h3: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "36px"
    },
    h4: {
      fontSize: "22px",
      fontWeight: "700",
      lineHeight: "33px"
    },
    h5: {
      fontSize: "21px",
      fontWeight: "700",
      lineHeight: "31.5px"
    },
    h6: {
      fontSize: "19px",
      fontWeight: "700",
      lineHeight: "28.5px"
    },
    subtitle1: {
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "27px"
    },
    subtitle2: {
      fontSize: "17px",
      fontWeight: "700",
      lineHeight: "25.5px"
    },
    body1: {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px"
    },
    body2: {
      fontSize: "15px",
      fontWeight: "400",
      lineHeight: "22px"
    },
    button: {
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "16px",
      textTransform: "none",
    },
    caption: {
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "19.5px"
    },
    overline: {
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "12px",
      textTransform: "none",
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: colors.grey
        },
        head: {
          fontSize: "17px",
          fontWeight: "700",
          lineHeight: "25.5px"
        },
        body: {
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          padding: "16px 32px"
        },
        sizeMedium: {
          padding: "12px 24px"
        },
        sizeSmall: {
          padding: "7px 24px"
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        sizeLarge: {
          padding: "16px 32px"
        },
        sizeMedium: {
          padding: "12px 24px"
        },
        sizeSmall: {
          padding: "7px 24px"
        },
        root: ({ ownerState }) => ({
          color: theme.palette.grey[400],
          ...(ownerState.color === "primary" && {
            "&.Mui-selected": {
              backgroundColor: colors.darkBlue,
              color: colors.white,
              "&:hover": {
                background: colors.cynBlue,
             },
            }
          }),
          ...(ownerState.color === "secondary" && {
            "&.Mui-selected": {
              backgroundColor: colors.cynBlue,
              color: colors.darkBlue,
              "&:hover": {
                background: colors.greyLight,
             },
            }
          }),
          ...(ownerState.color === "warning" && {
            "&.Mui-selected": {
              backgroundColor: colors.warning,
              color: colors.darkBlue,
              "&:hover": {
                background: colors.greyLight,
             },
            }
          }),
        }),
      }
    }
  }
});

export default theme;