import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';


export const FormButtonWrapper = styled(Box)
    (({theme}) => ({
        display: "flex",
        gap: "8px",
        padding: "16px 24px",
        backgroundColor: theme.palette.secondary.main,
        justifyContent: "space-between"
    })
);