import webAPIRequest, { IPaginatedData } from "api";
import { AxiosRequestConfig } from "axios";
import { IOrganization, IRegion } from "interfaces/organization";


export const fetchOrganizations = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<IOrganization>>("GET", "/organizations/", opts);

export const createOrganization = async (data: Omit<IOrganization, 'id' | 'admin_count'>) =>
    webAPIRequest<IOrganization>("POST", "/organizations/", {data: data});

export const deleteOrganization = async (id: string) =>
    webAPIRequest<IOrganization>("DELETE", `/organizations/${id}/`);

export const exportOrganization = async (id: string) =>
    webAPIRequest<Record<string, unknown>>("GET", `/organizations/${id}/export/`);

export const fetchRegions = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<IRegion>>("GET", "/organizations/regions/", opts);
