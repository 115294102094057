import { Box, Button, Typography } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import QRCode from "qrcode";
import { QRCodeImage } from "components/StyledComponents/TotpSetup";

const RegisterAppStep: React.FC<{configUrl: string}> = ({configUrl}) => {
    const { localize } = React.useContext(LocaleContext);
    const [url, setUrl] = React.useState("");
    
    const createUrl = React.useCallback(async() => {
        const dataUrl = await QRCode.toDataURL(configUrl);
        setUrl(dataUrl);
    }, [configUrl]);

    React.useEffect(() => {
        createUrl();
    },[createUrl])

    return (
        <>
            <Typography gutterBottom color="primary" variant="h4">
                        {localize("totp.register-title")}
            </Typography>
            <Typography gutterBottom variant="body1">
                {localize("totp.register-description")}
            </Typography>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="1em">
                <QRCodeImage src={url} alt="QR code for 2FA" />
                <Button href={configUrl}>
                    {localize("totp.open-auth-on-this-device")}
                </Button>
            </Box>
        </>
    )
    
};

export default RegisterAppStep;
