import { Typography } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";


const VerifyTOTPRegistrationStep: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <>
            <Typography gutterBottom color="primary" variant="h4">
                {localize("totp.verify-title")}
            </Typography>
            <Typography gutterBottom variant="body1">
                {localize("totp.verify-description")}
            </Typography>
        </>
    )
};

export default VerifyTOTPRegistrationStep;