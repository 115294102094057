import { Button, CircularProgress, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import { deleteTOTPDevice, ITOTPDevice } from "adapters/auth";


interface IConfirmDeleteTOTPDeviceProps {
    onClose: () => void;
    device: ITOTPDevice;
}

const ConfirmDeleteTOTPDevice: React.FC<IConfirmDeleteTOTPDeviceProps> = ({ device, onClose }) => {
    const { localize } = React.useContext(LocaleContext);
    const queryClient = useQueryClient();
    const deleteDeviceMutation = useMutation({
        mutationFn: deleteTOTPDevice,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["devices"] });
            onClose();
        }
    });

    const onConfirmDelete = () => deleteDeviceMutation.mutate(device.id);

    return (
        <>
            <DialogTitle color="primary">
                {localize("account.confirm-delete-totp-device")}
            </DialogTitle>
            <DialogContent
                sx={{
                    xs: {
                        width: "250px"
                    },
                    sm: {
                        width: "370px"
                    }
                }}>
                <Typography gutterBottom>
                    {localize("account.delete-totp-device-content")}
                </Typography>
            </DialogContent>
            <BaseDialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {localize("generics.cancel")}
                </Button>
                <Button
                    variant="contained"
                    disabled={deleteDeviceMutation.isPending}
                    endIcon={deleteDeviceMutation.isPending && <CircularProgress size={"1rem"} />}
                    onClick={onConfirmDelete}>
                    {localize("generics.confirm")}
                </Button>
            </BaseDialogActions>
        </>
    )

};

export default ConfirmDeleteTOTPDevice;