import { Box, Divider } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createJournalEntry} from "adapters/fireAlarmSystem";
import { FormButtonWrapper } from "components/StyledComponents/Form";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";

interface CreateJournalEntryFormProps {
    alarmSystemId: string | undefined;
    onSuccess: () => void;
    onError: () => void;
    buttons: React.ReactNode;
}

const CreateJournalEntryForm: React.FC<CreateJournalEntryFormProps> = ({ onSuccess, onError, buttons, alarmSystemId }) => {
    const { localize } = React.useContext(LocaleContext);

    const createJournalEntryMutation = useMutation({
        mutationKey: ["create-journal-entry"],
        mutationFn: createJournalEntry,
        onSuccess: onSuccess,
        onError: onError
    });

    const [newJournalEntryFields, setNewJournalEntryFields] = React.useState({
        entry_text: "",
        fire_alarm_system: alarmSystemId
    });

    const onSubmitJournalEntry = (event: React.SyntheticEvent) => {
        event.preventDefault();
        createJournalEntryMutation.mutate({ ...newJournalEntryFields });
    };

    return (
        <form onSubmit={onSubmitJournalEntry}>
            <Box padding={"1em"} bgcolor={(theme) => theme.palette.background.default}>
                <EBLTextField
                    multiline
                    rows={4}
                    id="journal_entry"
                    variant="outlined"
                    label={localize("journal.write-here")}
                    inputProps={{ maxLength: 100 }}
                    value={newJournalEntryFields.entry_text}
                    helperText={`${newJournalEntryFields.entry_text?.length} / 100`}
                    onChange={(e) => setNewJournalEntryFields({ ...newJournalEntryFields, entry_text: e.target.value })}
                />
            </Box>
            <Divider />
            <FormButtonWrapper>
                {React.Children.map(Array.isArray(buttons) ? buttons : [buttons], (button) =>
                    React.cloneElement(button, { disabled: button.props.type === "submit" && !!!newJournalEntryFields.entry_text })
                )}
            </FormButtonWrapper>
        </form>

    );
};

export default CreateJournalEntryForm;