import { LocaleContext } from "contexts/LocaleContext";
import React from "react";

import { Box, Skeleton, Typography } from "@mui/material";
import { useHasPermission } from "hooks/useHasPermission";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import JournalTable from "components/journal/JournalTable";

const JournalView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);

    const { alarmSystemId, alarmSystem, isLoading } = useGetAlarmSystem();
    const { hasPermission } = useHasPermission("localoffice");

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" sx={{
                    marginBottom: "1.375em",
                    color: (theme) => theme.palette.primary.main,
                    display: { xs: "none", sm: "inherit" }
                }}>
                    {isLoading
                        ? <Skeleton width="10rem" />
                        : `${alarmSystem?.name} / ${localize("navigation.status")} / ${localize("navigation.journal")}`}
                </Typography>
            </Box>


            {hasPermission("journal", "view_journal") && (
                <JournalTable alarmSystemId={alarmSystemId} />
            )}
        </>
    );
};

export default JournalView;