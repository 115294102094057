import { Divider, Skeleton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchProfile } from "adapters/auth";
import AccountInformation from "components/account/AccountInformation";
import TwoFAInformation from "components/account/TwoFAInformation";
import UserPreferredDateFormat from "components/account/UserPreferredDateFormat";
import UserSelectedOrg from "components/account/UserSelectedOrg";
import { LocaleContext } from "contexts/LocaleContext";
import { useHasPermission } from "hooks/useHasPermission";
import React from "react";


const AccountView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { hasPermission } = useHasPermission();

    
    const profileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: fetchProfile
    });

    return (
        <>
            <Typography variant="h2" color="primary" gutterBottom>
                {localize("account.title")}
            </Typography>
            <AccountInformation account={profileQuery.data?.data} />
            {(hasPermission("firealarmsystem", "view_firealarmsystem")) && (
                <>
                    <Divider sx={{ margin: "1em 0" }} />
                    <Typography variant="h4" color="text" gutterBottom>
                        {localize("account.organization")}
                    </Typography>
                    <UserSelectedOrg />
                </>
            )}
            <Divider sx={{ margin: "1em 0" }} />
            <Typography variant="h4" color="text" gutterBottom>
                {localize("account.date-format")}
            </Typography>
            {!!!profileQuery.data?.data ? (
                <Skeleton />
            ) : (
                <UserPreferredDateFormat account={profileQuery.data?.data} />
            )}
            <Divider sx={{ margin: "1em 0" }} />
            <TwoFAInformation />
        </>
    );
};

export default AccountView;