import { Button, CircularProgress, DialogContent, DialogTitle, MenuItem, Skeleton } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createInvite, fetchRoles } from "adapters/userManagement";
import { IErrorWithDetail } from "api";
import { BaseDialogActions } from "components/StyledComponents/Dialog";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import { IRoleAssignment, IUser } from "interfaces/userManagement";
import React from "react";


interface IChangeRoleProps {
    onClose: () => void;
    user: IUser;
    currentRoleAssignment: IRoleAssignment;
}

const ChangeRole: React.FC<IChangeRoleProps> = ({ onClose, user, currentRoleAssignment }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const [selectedRole, setSelectedRole] = React.useState<string>(currentRoleAssignment.role.id);
    const queryClient = useQueryClient();

    const roleQuery = useQuery({
        queryKey: ["roles", currentRoleAssignment.model],
        queryFn: async () => fetchRoles({ params: { context_content_type: currentRoleAssignment.model } }),
        enabled: !!currentRoleAssignment.model
    });

    const userAddToGroupMutation = useMutation({
        mutationFn: createInvite,
        onSuccess: () => {
            openSnack(localize("user-management.role-changed-successfully"), "success");
            onClose();
            queryClient.invalidateQueries({ queryKey: ["role-assignments"] });
        },
        onError: (error: IErrorWithDetail) => {
            openSnack(error.response.data.detail, "error");
        }
    });

    const onConfirm = () => {
        userAddToGroupMutation.mutate({
            email: user.email,
            role: selectedRole,
            context_object_id: currentRoleAssignment.context_object_id || "",
            model: currentRoleAssignment.model ? "organization.LocalOffice" : "organization.Organization"
        });
    };

    const isLoading = userAddToGroupMutation.isPending;

    const total_roles = roleQuery.data?.data.results.length || 0;

    return (
        <>
            <DialogTitle color="primary">
                {localize("user-management.change-role-title")}
            </DialogTitle>
            <DialogContent>
                {roleQuery.isLoading ? (
                    <Skeleton variant="text" />
                ) :
                    (
                        <EBLTextField
                            fullWidth
                            variant="outlined"
                            label={localize("generics.role")}
                            id="role"
                            select
                            value={selectedRole}
                            onChange={e => setSelectedRole(e.target.value)}
                        >
                            {roleQuery.data?.data.results.map(role => (
                                <MenuItem
                                    key={role.id}
                                    value={role.id}
                                    disabled={currentRoleAssignment.role.id === role.id}
                                >
                                    {role.display_name}
                                </MenuItem>
                            ))}
                        </EBLTextField>
                    )
                }
            </DialogContent>
            <BaseDialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {localize("generics.cancel")}
                </Button>
                <Button
                    disabled={total_roles < 3 || isLoading}
                    variant="contained"
                    onClick={onConfirm}
                    endIcon={isLoading && <CircularProgress size={"1rem"} />}
                >
                    {localize("generics.confirm")}
                </Button>
            </BaseDialogActions>
        </>
    )
};

export default ChangeRole;