import { Grid, Typography } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import MicrosoftAuthImg from "assets/images/microsoft_auth.png"
import GoogleAuthImg from "assets/images/google_auth.png"
import AuthyAuthImg from "assets/images/authy_auth.png"

const InstallStep: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    return (
        <>
            <Typography gutterBottom color="primary" variant="h4">
                {localize("totp.install-title")}
            </Typography>
            <Typography gutterBottom variant="body1">
                {localize("totp.install-description")}
            </Typography>
            <Grid container alignItems="center" justifyContent="center" mt="1em">
                <Grid container item xs={4} alignItems="center" direction="column">
                    <img width={"64px"} src={MicrosoftAuthImg} alt="Microsoft Authenticator"/>
                    <Typography variant="caption">{localize("totp.microsoft-auth")}</Typography>
                </Grid>
                <Grid container item xs={4} alignItems="center" direction="column">
                    <img width={"64px"} src={AuthyAuthImg} alt="Authy Authenticator"/>
                    <Typography variant="caption">{localize("totp.authy-auth")}</Typography>
                </Grid>
                <Grid container item xs={4} alignItems="center" direction="column">
                    <img width={"64px"} src={GoogleAuthImg} alt="Google Authenticator"/>
                    <Typography variant="caption">{localize("totp.google-auth")}</Typography>
                </Grid>
            </Grid>
        </>
    )
};

export default InstallStep;