import { Button, Divider, Stack, Typography } from "@mui/material";
import { useIsFetching, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchProfile, IProfile, patchProfile } from "adapters/auth";
import { AxiosError } from "axios";
import ChangableField from "components/ChangableField";
import PasswordField from "components/inputFields/PasswordField";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import useFormValidation from "hooks/useFormValidation";
import { useHasPermission } from "hooks/useHasPermission";
import React from "react";
import { getFieldErrorMessage } from "utils/getFieldErrorMessage";


const AccountInformation: React.FC<{ account?: IProfile }> = ({ account }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const queryClient = useQueryClient();
    const {
        validatePhoneNumber,
        validatePassword,
        passwordIsNotValidTag,
        fieldIsNotEmpty,
        validateEmail,
    } = useFormValidation();
    const { hasPermission } = useHasPermission();

    const [profileData, setProfileData] = React.useState({
        email: account?.email || "",
        new_password: "",
        old_password: "",
        first_name: account?.first_name || "",
        last_name: account?.last_name || "",
        phone: account?.phone || ""
    });

    type ProfileDataKey = keyof typeof profileData;

    const profileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: async () => {
            const queryResult = await fetchProfile()
            if (queryResult.data) {
                setProfileData({ ...profileData, ...queryResult.data })
            }
            return queryResult
        }
    });

    const updateProfileMutation = useMutation({
        mutationFn: async (fields: ProfileDataKey[]) => {
            const userId = profileQuery.data?.data.id;
            if (userId) {
                const data = fields.reduce((res, key) => ({ ...res, [key]: profileData[key] }), {})
                await patchProfile(userId, data);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["profile"], });
            setProfileData({
                ...profileData,
                old_password: "",
                new_password: ""
            })
        },
        onError: (error: AxiosError, variables: ProfileDataKey[]) => {
            const fieldError = getFieldErrorMessage(error)
            if (variables.length && fieldError) {
                openSnack(localize(`account.error-${variables[0]}`) + `: ${fieldError.message}`, "error");
            }
            setProfileData({
                ...profileData,
                email: account?.email || "",
                new_password: "",
                old_password: "",
                first_name: account?.first_name || "",
                last_name: account?.last_name || "",
                phone: account?.phone || ""
            })
        }
    });

    const isLoading = !!useIsFetching({ queryKey: ["profile"] }) || updateProfileMutation.isPending;
    const profile = profileQuery.data?.data;
    const phoneNumberIsValid = validatePhoneNumber(profileData.phone);
    const newPasswordIsValid = validatePassword(profileData.new_password);
    const emailIsValid = validateEmail(profileData.email);
    const emailLengthIsValid = profileData.email !== undefined && profileData.email.length < 100;
    const firstNameIsValid = profileData.first_name !== undefined && profileData.first_name.length < 100;
    const lastNameIsValid = profileData.last_name !== undefined && profileData.last_name.length < 100;

    return (
        <Stack>
            <Typography variant="h4" color="text" gutterBottom>
                {localize("account.account-information")}
            </Typography>
            <ChangableField
                isLoading={isLoading}
                label={localize("generics.email")}
                data={profile?.email}
                onSubmit={() => updateProfileMutation.mutate(["email"])}
                popoverFormData={
                    <EBLTextField
                        variant="outlined"
                        fullWidth
                        label={localize("generics.email")}
                        type="email"
                        id="email"
                        value={profileData.email}
                        inputProps={{ maxLength: 100 }}
                        error={!emailIsValid}
                        helperText={!emailLengthIsValid && localize("text-validation.100")}
                        onChange={e => setProfileData({ ...profileData, email: e.target.value })}
                    />
                }
                popoverAction={
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={!validateEmail(profileData.email)}
                    >
                        {localize("account.update-email")}
                    </Button>
                }
                hasPermission={hasPermission("user", "view_profile")}
            />
            <ChangableField
                isLoading={isLoading}
                label={localize("generics.password")}
                data={"•••••••••••••"}
                onSubmit={() => updateProfileMutation.mutate(["new_password", "old_password"])}
                popoverFormData={
                    <Stack>
                        <PasswordField
                            variant="outlined"
                            label={localize("account.old-password")}
                            id="old_password"
                            value={profileData.old_password}
                            onChange={e => setProfileData({ ...profileData, old_password: e.target.value })}
                        />
                        <PasswordField
                            variant="outlined"
                            label={localize("account.new-password")}
                            id="new_password"
                            error={!!profileData.new_password && !newPasswordIsValid}
                            helperText={!newPasswordIsValid && localize(passwordIsNotValidTag(profileData.new_password))}
                            value={profileData.new_password}
                            onChange={e => setProfileData({ ...profileData, new_password: e.target.value })}
                        />
                    </Stack>
                }
                popoverAction={
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={(!profileData.old_password || !profileData.new_password) || !newPasswordIsValid}
                    >
                        {localize("account.update-password")}
                    </Button>
                }
                hasPermission={hasPermission("user", "view_profile")}
            />
            <Divider sx={{ margin: "1em 0" }} />
            <Typography variant="h4" color="text" gutterBottom>
                {localize("account.personal-information")}
            </Typography>
            <ChangableField
                isLoading={isLoading}
                label={localize("generics.first-name")}
                data={profile?.first_name}
                onSubmit={() => updateProfileMutation.mutate(["first_name"])}
                popoverFormData={
                    <EBLTextField
                        variant="outlined"
                        label={localize("generics.first-name")}
                        id="name"
                        value={profileData.first_name}
                        inputProps={{ maxLength: 100 }}
                        helperText={!firstNameIsValid && localize("text-validation.100")}
                        onChange={e => setProfileData({ ...profileData, first_name: e.target.value })}
                    />
                }
                popoverAction={
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!fieldIsNotEmpty(profileData.first_name)}
                    >
                        {localize("account.update-name")}
                    </Button>
                }
                hasPermission={hasPermission("user", "view_profile")}
            />
            <ChangableField
                isLoading={isLoading}
                label={localize("generics.last-name")}
                data={profile?.last_name}
                onSubmit={() => updateProfileMutation.mutate(["last_name"])}
                popoverFormData={
                    <EBLTextField
                        variant="outlined"
                        label={localize("generics.last-name")}
                        id="name"
                        value={profileData.last_name}
                        inputProps={{ maxLength: 100 }}
                        helperText={!lastNameIsValid && localize("text-validation.100")}
                        onChange={e => setProfileData({ ...profileData, last_name: e.target.value })}
                    />
                }
                popoverAction={
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!fieldIsNotEmpty(profileData.last_name)}
                    >
                        {localize("account.update-surname")}
                    </Button>
                }
                hasPermission={hasPermission("user", "view_profile")}
            />
            <ChangableField
                isLoading={isLoading}
                label={localize("generics.phone")}
                data={profile?.phone}
                onSubmit={() => updateProfileMutation.mutate(["phone"])}
                popoverFormData={
                    <EBLTextField
                        variant="outlined"
                        label={localize("generics.phone")}
                        id="name"
                        error={!phoneNumberIsValid}
                        helperText={localize("generics.phone-helper")}
                        value={profileData.phone}
                        onChange={e => setProfileData({ ...profileData, phone: e.target.value })}
                        inputProps={{ maxLength: 17 }}
                    />
                }
                popoverAction={
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!phoneNumberIsValid}
                    >
                        {localize("account.update-phone")}
                    </Button>
                }
                hasPermission={hasPermission("user", "view_profile")}
            />
        </Stack>
    );
};

export default AccountInformation;