import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchProfile } from "adapters/auth";
import React from "react"

const organization: string | null = sessionStorage.getItem("selectedOrganization");

interface IOrganizationState {
    currentOrganizationId: string | null;
    updateOrganization: (newId: string) => void;
}


export const OrganizationContext = React.createContext<IOrganizationState>({
    currentOrganizationId: organization,
    updateOrganization: () => null
});


const OrganizationProvider: React.FC<{ children?: React.ReactNode, is_authenticated?: boolean }> = ({ children, is_authenticated }) => {
    const selectedOrganization: string | null = sessionStorage.getItem("selectedOrganization")

    const [currentOrganizationId, setcurrentOrganizationId] = React.useState<string | null>(selectedOrganization);

    const queryClient = useQueryClient();

    const profileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: fetchProfile,
        enabled: !!is_authenticated
    });

    React.useEffect(() => {
        const stored = sessionStorage.getItem("selectedOrganization");

        if (stored) {
                return setcurrentOrganizationId(stored);
            } else if (profileQuery.data?.data.organization[0]) {
                return setcurrentOrganizationId(profileQuery.data?.data.organization[0].id)
            }
            return setcurrentOrganizationId(null);
    
    }, [profileQuery]);


    const updateOrganization = (newId: string) => {
        const isOrganizationFound = profileQuery.data?.data.organization.some((item) => item.id === newId);
        if (isOrganizationFound)
            setcurrentOrganizationId(newId);
        sessionStorage.setItem("selectedOrganization", newId);
        queryClient.resetQueries();
    }

    return (
        <OrganizationContext.Provider value={{ currentOrganizationId, updateOrganization }}>
            {children}
        </OrganizationContext.Provider>
    )
}

export default OrganizationProvider;