import webAPIRequest, { IPaginatedData } from "api";
import { IUser } from "interfaces/userManagement";
import { IOrganization } from "interfaces/organization";
import { AxiosRequestConfig } from "axios";

interface ICredentials {
    email: string;
    password: string;
}

export interface ITOTPDevice {
    id: string;
    name: string;
    confirmed: boolean;
    config_url: string;
    is_last_confirmed_device: boolean;
}

interface AuthenticateOTP extends ICredentials {
    client_id: string;
    token: string;
}


interface IOtpResponse {
    code: string;
    code_verifier: string;
}


interface ITokenData {
    client_id: string | null;
    grant_type: string;
    code: string;
    code_verifier: string;
    redirect_uri: string;
}

interface IRefetchTokenData {
    client_id: string | null;
    grant_type: string;
    refresh_token: string;
}


export interface IAccessTokenData {
    access_token: string;
    expires_in: number;
    token_type: string;
    scope: string;
    refresh_token: string;
}

interface IRevokeTokenData {
    client_id: string;
    token: string;
}

export interface IProfile extends IUser {
    date_joined: string;
    is_admin: boolean;
    organization: IOrganization[];
}

interface IApplication {
    name: string;
    client_id: string;
    redirect_uris: string;
}

export type ModelType =
    | "organization"
    | "user"
    | "gateway"
    | "firealarmsystem"
    | "activedeviation"
    | "notificationpreference"
    | "invoiceinput"
    | "roleassignment"
    | "group"
    | "role"
    | "roleassignmentinvitation"
    | "localoffice"
    | "command"
    | "region"
    | "firealarmcontactperson"
    | "totpdevice"
    | "journal"
    | "profile";


export type PermissionType<Key extends string> =
    | `view_${Key}`
    | `add_${Key}`
    | `change_${Key}`
    | `delete_${Key}`;


export type IClientPermission = {
    [Key in ModelType]: PermissionType<Key>[];
}

export interface IClientConfiguration {
    is_authenticated: boolean;
    dashboard: boolean;
    applications: IApplication[];
}

export interface IClientPermissions {
    permissions: IClientPermission;
}

interface IAcceptInvitation {
    email: string;
    password: string;
}

interface IConfirmResetPassword {
    id: string;
    token: string;
    password: string;
}

export const checkCredentials = async (data: ICredentials) =>
    webAPIRequest<ITOTPDevice | string>("POST", "/auth/otp/check-credentials/", { data: data });


export const authenticateOTP = async (data: AuthenticateOTP) =>
    webAPIRequest<IOtpResponse>("POST", "/auth/otp/verify/", { data: data });

export const fetchToken = async (data: ITokenData) =>
    webAPIRequest<IAccessTokenData>("POST", "/auth/token/", { data: data });

export const refetchToken = async (data: IRefetchTokenData) =>
    webAPIRequest<IAccessTokenData>("POST", "/auth/token/", { data: data });


export const revokeToken = async (data: IRevokeTokenData) =>
    webAPIRequest("POST", "/auth/revoke-token/", { data: data });

export const logout = async () =>
    webAPIRequest("POST", "/auth/logout/");

export const fetchProfile = async () => webAPIRequest<IProfile>("GET", "/users/profile/");
export const patchProfile = async (id: string, data: Partial<IProfile>) => webAPIRequest<IProfile>("PATCH", `/users/${id}/`, { data: data });

export const fetchClientConfiguration = async () =>
    webAPIRequest<IClientConfiguration>("GET", "/auth/client-configuration/");

export const fetchClientPermissions = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IClientPermissions>("GET", "/auth/client-permissions/", opts);

export const acceptInvitation = async (token: string, data: IAcceptInvitation) =>
    webAPIRequest<ITOTPDevice>("POST", `/drm/invitations/${token}/accept/`, { data: data });

export const fetchTOTPDevices = async () => webAPIRequest<IPaginatedData<ITOTPDevice>>("GET", "/auth/totp-devices/");
export const patchTOTPDevice = async (id: string, data: Partial<ITOTPDevice>) => webAPIRequest("PATCH", `/auth/totp-devices/${id}/`, { data: data });
export const deleteTOTPDevice = async (id: string) => webAPIRequest("DELETE", `/auth/totp-devices/${id}/`);
export const createTOTPDevice = async (name: string) => webAPIRequest<ITOTPDevice>("POST", "/auth/totp-devices/", { data: { "name": name } });
export const verifyTOTPDeviceToken = async (token: string) => webAPIRequest("POST", "/auth/totp-devices/verify-token/", { data: { "token": token } });

export const confirmResetPassword = async (data: IConfirmResetPassword) =>
    webAPIRequest("POST", "/auth/reset-password/confirm/", { data: data });

export const resetPassword = async (email: string) =>
    webAPIRequest("POST", "/auth/reset-password/", { data: { email: email } });
