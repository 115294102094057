import { Box, Divider, Grid, Typography, DialogTitle, Button } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchProfile } from "adapters/auth";
import CreateInvitationForm from "components/userManagement/CreateInvitationForm";
import ListInvitations from "components/userManagement/ListInvitations";
import ListUsers from "components/userManagement/ListUsers";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import { useHasPermission } from "hooks/useHasPermission";
import CreateLocalOfficeForm from "components/fireAlarmSystem/forms/CreateLocalOfficeForm";
import { BaseDialog } from "components/StyledComponents/Dialog";
import { SnackBarContext } from "contexts/SnackBarContext";
import ListLocalOffices from "components/userManagement/ListLocalOffices";
import { Create } from "@mui/icons-material";
import { OrganizationContext } from "contexts/OrganizationContext";
import { AxiosError } from "axios";
import { getFieldErrorMessage } from "utils/getFieldErrorMessage";


const UserManagementView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { currentOrganizationId } = React.useContext(OrganizationContext);

    const [configureLocalOfficeOpen, setconfigureLocalOfficeOpen] = React.useState(false);
    const profileQuery = useQuery({ queryKey: ["profile"], queryFn: fetchProfile });
    const context_object_id = currentOrganizationId
    const { openSnack } = React.useContext(SnackBarContext);
    const queryClient = useQueryClient();

    const onLocalOfficeSuccess = (): void => {
        setconfigureLocalOfficeOpen(false);
        openSnack(localize("configuration.create-local-office-success"), "success")
        queryClient.invalidateQueries({ queryKey: ["local-offices"] })
    };

    const onLocalOfficeError = (e: AxiosError): void => {
        const fieldError = getFieldErrorMessage(e)
        if (fieldError) {
            openSnack(`${fieldError.message}`, "error");
        }
        else {
            openSnack(localize("configuration.create-local-office-failed"), "error")
        }
    };

    const { hasPermission } = useHasPermission();

    const onCloseLocalOfficeConfiguration = (): void =>
        setconfigureLocalOfficeOpen(false);

    const onLocalOfficeConfigurationOpen = (): void => {
        setconfigureLocalOfficeOpen(true);
    };

    return (
        <>
            <Typography variant="h2" color="primary" gutterBottom>
                {localize("navigation.user-management")}
            </Typography>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{ pr: { lg: "64px" } }}
                >
                    <Typography variant="h4" color="text">
                        {localize("user-management.invite-user")}
                    </Typography>
                    <CreateInvitationForm />
                    <Box mt="1em">
                        <Typography variant="h4" color="text" gutterBottom>
                            {localize("user-management.pending")}
                        </Typography>
                        {profileQuery.data && (
                            <ListInvitations organizationId={context_object_id} />
                        )}
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={(theme) => ({
                        paddingLeft: { lg: "32px" },
                        borderLeft: { lg: `1px solid ${theme.palette.grey[600]}` }
                    })}
                >
                    <Divider sx={{ display: { lg: "none" }, mt: "1em", mb: "1em" }}></Divider>
                    <Typography variant="h4" color="text" gutterBottom>
                        {localize("user-management.current")}
                    </Typography>
                    {profileQuery.data && (
                        <Box >
                            <ListUsers context_object_id={context_object_id}
                            />
                        </Box>
                    )}
                    <Divider />
                    {(hasPermission("localoffice", "view_localoffice")) && (
                        <Box mt="1em">
                            <Typography variant="h4" color="text" gutterBottom>
                                {localize("user-management.local-offices")}
                            </Typography>
                            {profileQuery.data && (
                                <ListLocalOffices contextObjectId={context_object_id} />
                            )}
                        </Box>
                    )}
                    {hasPermission("localoffice", "add_localoffice") && (
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: "inherit", marginTop: "3%" }}>

                            <Button
                                variant="contained"
                                onClick={onLocalOfficeConfigurationOpen}
                                endIcon={<Create />}
                                size="small"
                            >
                                {localize("generics.add-local-office")}
                            </Button>
                        </Grid>
                    )}
                    <BaseDialog open={configureLocalOfficeOpen} onClose={onCloseLocalOfficeConfiguration}>
                        <DialogTitle color="primary">
                            {localize("generics.add-local-office")}
                        </DialogTitle>
                        <CreateLocalOfficeForm
                            onSuccess={onLocalOfficeSuccess}
                            onError={onLocalOfficeError}
                            context_object_id={context_object_id}
                            onClose={onCloseLocalOfficeConfiguration}
                        />
                    </BaseDialog>
                </Grid>
            </Grid>
        </>
    );
};

export default UserManagementView;