import webAPIRequest from "api";
import { AxiosRequestConfig } from "axios";
import { INotificationPreference, INotificationTrigger } from "interfaces/notifications";

export const fetchNotificationPreferences = async (opts: AxiosRequestConfig) =>
    webAPIRequest<INotificationPreference[]>("GET", "/notifications/preferences/", opts);

export const createNotificationPreference = async (data: Omit<INotificationPreference, "id">) =>
    webAPIRequest<INotificationPreference>("POST", "/notifications/preferences/", {data});

export const createWebPushNotificationPreference = async (data: {config: PushSubscriptionJSON}) =>
    webAPIRequest<INotificationPreference>("POST", "/notifications/preferences/web-push/", {data});


export const deleteNotificationPreference = async (id: string) =>
    webAPIRequest("DELETE", `/notifications/preferences/${id}/`);

export const fetchNotificationTriggers = async () =>
    webAPIRequest<INotificationTrigger[]>("GET", "/notifications/triggers/");

