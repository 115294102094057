import { Box, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createGatewayCredentials } from "adapters/fireAlarmSystem";
import PasswordField from "components/inputFields/PasswordField";
import { FormButtonWrapper } from "components/StyledComponents/Form";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";

interface CreateGatewayCredentialsFormProps {
    gatewayId: string;
    onClose: () => void;
}

const CreateGatewayCredentialsForm: React.FC<CreateGatewayCredentialsFormProps> = ({ gatewayId, onClose }) => {
    const { localize } = React.useContext(LocaleContext);
    const [password, changePassword] = React.useState("");

    const createCredentialsMutation = useMutation({
        mutationKey: ["create-gateway-credentials"],
        mutationFn: async () => await createGatewayCredentials(gatewayId, password),
    });


    const onSubmitGateway = (event: React.SyntheticEvent) => {
        event.preventDefault();
        createCredentialsMutation.mutate();
    };

    return (
        <form onSubmit={onSubmitGateway}>
            <Box padding={"1em"} width="450px" bgcolor={(theme) => theme.palette.background.default}>
                <PasswordField
                    variant="outlined"
                    required={false}
                    label={localize("generics.password")}
                    id="password"
                    value={password}
                    onChange={e => changePassword(e.target.value)}
                    helperText="Leave blank to auto-generate password"
                />
                <Stack>
                    <Stack direction="row" spacing="1em">
                        <Typography fontWeight="bold">
                            {localize("configuration.account-id")}:
                        </Typography>
                        <Typography>
                            {createCredentialsMutation.data?.data.id}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={"1rem"}>
                        <Typography fontWeight="bold">
                            {localize("generics.password")}:
                        </Typography>
                        <Typography>
                            {createCredentialsMutation.data?.data.password}
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
            <Divider />
            <FormButtonWrapper>
                <Button variant="outlined" onClick={onClose}>
                    {localize("generics.close")}
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={createCredentialsMutation.isPending || !!createCredentialsMutation.data}
                    endIcon={createCredentialsMutation.isPending && <CircularProgress size={"1rem"} />}
                >
                    {localize("generics.confirm")}
                </Button>
            </FormButtonWrapper>
        </form>

    );
};

export default CreateGatewayCredentialsForm;