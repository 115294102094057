import {
    useMediaQuery,
    useTheme
} from "@mui/material";
import { DeviationType, StatusAccordion } from "components/StatusAccordion";
import { ActiveFireAlarmIcon } from "components/svg/DeviationIcons";
import { LocaleContext } from "contexts/LocaleContext";
import { IDeviation } from "interfaces/fireAlarmSystem";
import React from "react";
import { IDeviationStatusHeader, DeviationStatusTable, DeviationStatusStack } from "components/status/DeviationInfo";
import { IStatusAccordionProps } from "interfaces/statusAccordionProps";
import useDateFormat from "hooks/useDateFormat";

const headers: IDeviationStatusHeader[] = [
    {
        locale: "status.time-title"
    },
    {
        locale: "status.zone-address-title",
        alignment: "right"
    },
    {
        locale: "status.type-title",
        alignment: "right"
    },
    {
        locale: "status.state-title",
        alignment: "right"
    },
    {
        locale: "status.text-title",
    }
];

export const FireAlarmStatus: React.FC<{ deviations: IDeviation[] }> = ({ deviations }) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const { formatDate } = useDateFormat();

    const rows = deviations.map(dev => [
        formatDate(dev.payload.timestamp),
        `${dev.payload.data.parameters.zoneaddress_desc || ""}`,
        dev.payload.data.parameters.alarmsource_desc || "-",
        dev.payload.data.parameters?.alarmstate_desc || "",
        dev.payload.text || "-"
    ]);

    if (isLargeScreen) {
        return (
            <DeviationStatusTable
                headers={headers}
                rows={rows}
            />
        );
    }
    return (
        <>
            {rows.map((row, rowIndex) => (
                <DeviationStatusStack
                    key={rowIndex}
                    headers={headers}
                    data={row}
                />
            ))}
        </>
    );

};


const FireAlarmStatusAccordion: React.FC<IStatusAccordionProps> = ({ alarmSystem }) => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <StatusAccordion
            icon={<ActiveFireAlarmIcon />}
            numberOfDeviations={alarmSystem?.deviations.fire_alarms.length}
            loading={!!!alarmSystem}
            deviationType={DeviationType.Error}
            title={localize("status.fire-alarm")}
        >
            {alarmSystem && (
                <FireAlarmStatus deviations={alarmSystem.deviations.fire_alarms} />
            )}
        </StatusAccordion>
    );
};

export default FireAlarmStatusAccordion;