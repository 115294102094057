import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { NoAuthWrapper, NoAuthContainer } from "./StyledComponents/NoAuthCompontents";
import { PanasonicLogoStyled } from "./svg/PanasonicLogo";
import { EBLCloudLogoWhiteStyled } from "./svg/EBLCloudLogoWhite";

const NoAuthLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <NoAuthWrapper >
            <NoAuthContainer maxWidth="sm">
                <div style={{height: '15%'}}>
                    
                    <EBLCloudLogoWhiteStyled
                        size={"large"}
                    />
                </div>
                <Outlet />
                <div>
                    <PanasonicLogoStyled
                        size={matches ? "small" : "small"}
                        sx={{
                            color: (theme) => theme.palette.background.default,
                        }}
                    />
                </div>
            </NoAuthContainer>
        </NoAuthWrapper>
    )
};

export default NoAuthLayout;