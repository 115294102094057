import { Button, CircularProgress, FormControl, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "adapters/auth";
import { NoAuthCard } from "components/StyledComponents/NoAuthCompontents";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import useFormValidation from "hooks/useFormValidation";
import React from "react";
import { useNavigate } from "react-router-dom";


const ResetPasswordView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const navigate = useNavigate();
    const { validateEmail } = useFormValidation();

    const [email, setEmail] = React.useState("");

    const resetPasswordMutation = useMutation({
        mutationFn: resetPassword,
        onSuccess: () => {
            openSnack(localize("reset-password.reset-success"), "success");
            navigate("/");
        },
        onError: () => {
            openSnack(localize("reset-password.reset-error"), "error");
        }
    });


    const onSubmit = async (event: React.SyntheticEvent): Promise<void> => {
        event.preventDefault();
        resetPasswordMutation.mutate(email);
    };

    const emailIsValid = validateEmail(email);

    return (
        <NoAuthCard style={{ width: '100%' }}>
            <Typography variant="h1" color="primary" gutterBottom>{localize("reset-password.title")}</Typography>
            <form noValidate onSubmit={onSubmit}>
                <FormControl fullWidth>
                    <EBLTextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        error={!!email.length && !emailIsValid}
                        label={localize("generics.email")}
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Stack direction="row" justifyContent="space-between" mt="28px">
                        <Button variant="outlined" onClick={() => navigate("/")}>
                            {localize("generics.cancel")}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={resetPasswordMutation.isPending || !emailIsValid}
                            endIcon={resetPasswordMutation.isPending && <CircularProgress size={"1rem"} />}
                        >
                            {localize("reset-password.send-verification-link")}
                        </Button>
                    </Stack>
                </FormControl>
            </form>
        </NoAuthCard>
    );
};

export default ResetPasswordView;