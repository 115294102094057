import { createSvgIcon } from "@mui/material";
import theme from "styling/theme";

export const CommunicationCheckCircleIcon = createSvgIcon(
    <g clipPath="url(#a)" style={{ mixBlendMode: "normal" }}>
        <path d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" fill={theme.palette.success.main} />
        <g style={{ mixBlendMode: "lighten" }}>
            <path d="m6.02 12.46 4.15 4.27 8.56-8.29" fill={theme.palette.success.main} />
            <path d="m6.02 12.46 4.15 4.27 8.56-8.29" stroke={theme.palette.secondary.light} strokeWidth="2.07" strokeLinecap="round" strokeLinejoin="round" />
        </g>
    </g>,
    "CommunicationCheckCircleIcon"
);