import { Box, Button, CircularProgress } from "@mui/material";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";

interface VerifyTOTPFormProps {
    onCancel: () => void;
    onVerify: (token: string) => void;
    loading: boolean;
}

const VerifyTOTPForm: React.FC<VerifyTOTPFormProps> = ({ onCancel, onVerify, loading}) => {   
    const { localize } = React.useContext(LocaleContext);
    const [token, setToken] = React.useState("");

    const onSubmit = async (event: React.SyntheticEvent): Promise<void> => {
        event.preventDefault();
        onVerify(token);
    };

    return (
        <form noValidate onSubmit={onSubmit}>
            <EBLTextField
                variant="outlined"
                required
                fullWidth
                id="token"
                label={localize("login.code")}
                autoFocus
                value={token}
                autoComplete='off'
                onChange={e => setToken(e.target.value)}
            />
            <Box 
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={"1em"}
            >
                <Button variant="outlined" onClick={onCancel}>
                    {localize("generics.cancel")}
                </Button>
                <Button
                    disabled={token.length < 6}
                    type="submit"
                    variant="contained"
                    endIcon={loading && <CircularProgress size={"1rem"} color="secondary"/>}
                >
                    {localize("totp.verify")}
                </Button>
            </Box>
        </form>
    );
};

export default VerifyTOTPForm;