import { useQuery } from "@tanstack/react-query";
import { fetchFireAlarmSystem } from "adapters/fireAlarmSystem";
import { IFireAlarmSystem } from "interfaces/fireAlarmSystem";
import { useParams } from "react-router-dom";

interface UseGetAlarmSystemMethods {
    isLoading: boolean;
    alarmSystem?: IFireAlarmSystem;
    alarmSystemId?: string;
}

const useGetAlarmSystem = (): UseGetAlarmSystemMethods =>  {

    const params = useParams();
    const alarmSystemId = params.id || undefined;
    
    const {
        data,
        isLoading,
    } = useQuery({
        queryKey: ["fire-alarm-system", alarmSystemId],
        queryFn: async () => await fetchFireAlarmSystem(alarmSystemId ?? ""),
        enabled: !!alarmSystemId,
    });


    return {
        alarmSystem: data?.data,
        isLoading,
        alarmSystemId
    }
};

export default useGetAlarmSystem;