import { useQuery } from "@tanstack/react-query";
import { fetchProfile } from "adapters/auth";
import { format } from "date-fns";


interface UseDateFormatMethods {
    formatDate: (unformated_date: string) => string;
}


const useDateFormat = (): UseDateFormatMethods => {
    const profileConfig = useQuery({
        queryKey: ["profile"],
        queryFn: fetchProfile,
    });

    const formatDate = (unformated_date: string): string => {
        if (profileConfig.data?.data.date_format) {
            return format(new Date(unformated_date), profileConfig.data.data.date_format);
        }
        return unformated_date;
    };

    return {formatDate};
};

export default useDateFormat;