import { fetchLocalOffice } from "adapters/userManagement";
import { Delete, People } from "@mui/icons-material";
import { Box, Button, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, Skeleton } from "@mui/material";
import { keepPreviousData, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { ILocalOffice } from "interfaces/userManagement";
import React from "react";
import { useHasPermission } from "hooks/useHasPermission";
import { useInView } from "react-intersection-observer";
import { isLastInArray } from "utils/arrays";
import { fetchProfile } from "adapters/auth";
import { LocaleContext } from "contexts/LocaleContext";
import { BaseDialog, BaseDialogActions } from "components/StyledComponents/Dialog";
import ConfirmDeleteLocalOffice from "./ConfirmDeleteLocalOffice";
import useUserManagement from "hooks/useUserManagement";
import ListUsersByRoleAssignment from "./ListUsersByRoleAssignment";
import useFormValidation from "hooks/useFormValidation";
import theme from "styling/theme";
import InfiniteScrollList from "components/InfiniteScrollList";


interface IListLocalOfficesProps {
    contextObjectId: string | undefined | null;
}

const ListLocalOffices: React.FC<IListLocalOfficesProps> = ({ contextObjectId }) => {
    const { hasPermission } = useHasPermission();
    const { ref } = useInView()
    const [selectedLocalOfficeForDeletion, setLocalOfficeForDeletion] = React.useState<ILocalOffice | null>(null);
    const [selectedLocalOfficeForViewUsers, setLocalOfficeForViewUsers] = React.useState<ILocalOffice | null>(null);
    const profileQuery = useQuery({ queryKey: ["profile"], queryFn: fetchProfile });
    const { localize } = React.useContext(LocaleContext);
    const { truncateString } = useFormValidation()

    const localOfficeQuery = useInfiniteQuery({
        queryKey: ["local-offices"],
        queryFn: async ({ pageParam }) => {
            const params = {
                page: pageParam,
                context_object_id: contextObjectId,
                org_id: contextObjectId
            };
            const res = await fetchLocalOffice({ params: params })
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    const handleClose = (): void => {
        setLocalOfficeForDeletion(null);
        setLocalOfficeForViewUsers(null);
    };

    const { roleAssignments } = useUserManagement(selectedLocalOfficeForViewUsers?.id);

    const renderLocalOfficeList = (): React.ReactNode => {
        return (
            <Box height={"30vh"}>
                <InfiniteScrollList
                    fetchNextPage={localOfficeQuery.fetchNextPage}
                    isFetchingNextPage={localOfficeQuery.isFetchingNextPage}
                    hasNextPage={!!localOfficeQuery.hasNextPage}
                    isLoading={localOfficeQuery.isLoading}
                >
                    {localOfficeQuery.data?.pages.every(datablock => datablock.data.length === 0) ? (
                        <ListItem>
                            {localize("generics.no-local-offices-added")}
                        </ListItem>
                    ) : localOfficeQuery.data?.pages.map((group, i) => group.data.map((office, j) => (
                        <ListItem
                            key={office.id}
                            dense
                            disablePadding
                            ref={isLastInArray(localOfficeQuery.data?.pages, i) && isLastInArray(group.data, j) ? ref : null}
                            style={{
                                backgroundColor: selectedLocalOfficeForViewUsers?.id === office.id || selectedLocalOfficeForDeletion?.id === office.id ?
                                    theme.palette.background.paper : theme.palette.background.default,
                                width: "100%",
                                borderRadius: "6px"
                            }}
                        >
                            <ListItemText style={{ wordBreak: "break-word" }} primaryTypographyProps={{ sx: { paddingRight: '80px' }, variant: "body1" }}>
                                {office.name}
                            </ListItemText>
                            <IconButton onClick={() => setLocalOfficeForViewUsers(office)}>
                                <People />
                            </IconButton>
                            {hasPermission("localoffice", "delete_localoffice") && (
                                <IconButton onClick={() => setLocalOfficeForDeletion(office)}>
                                    <Delete />
                                </IconButton>
                            )}
                        </ListItem>
                    )))}
                    {localOfficeQuery.isFetchingNextPage && ref != null && (
                        <>
                            <ListItem dense disablePadding key={1}>
                                <Skeleton width={"100%"} />
                            </ListItem>
                        </>
                    )}
                </InfiniteScrollList>
            </Box>
        )
    };


    const renderDialogContent = (): React.ReactNode => {
        return (
            <DialogContent>
                {roleAssignments.map(roleAssignment => (
                    <ListUsersByRoleAssignment key={roleAssignment.id} roleAssignment={roleAssignment}
                        profileUserId={profileQuery.data?.data.id} displayRole={true} />
                ))}
            </DialogContent>
        );
    }

    return (
        <>
            <List disablePadding sx={{ maxWidth: "500px" }}>
                {renderLocalOfficeList()}
            </List>
            <BaseDialog open={!!selectedLocalOfficeForDeletion} onClose={handleClose}>
                {selectedLocalOfficeForDeletion && <ConfirmDeleteLocalOffice local_office={selectedLocalOfficeForDeletion} onClose={handleClose} />}
            </BaseDialog>

            <BaseDialog
                open={!!selectedLocalOfficeForViewUsers}
                maxWidth={"xs"}
                fullWidth
            >
                <DialogTitle color="primary" gutterBottom>
                    {`${localize("user-management.active-users")} / ${truncateString(selectedLocalOfficeForViewUsers?.name) ?? ""} `}
                </DialogTitle>
                {renderDialogContent()}
                <BaseDialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        {localize("generics.close")}
                    </Button>
                </BaseDialogActions>
            </BaseDialog>
        </>
    )

};

export default ListLocalOffices;