import { LocaleContext } from "contexts/LocaleContext";
import React, { useEffect } from "react";

import { Box, Grid, Skeleton, Stack, Tab, Tabs, Typography, styled } from "@mui/material";
import ZoneDisablementForm from "../components/operations/ZoneDisablementForm";
import { useHasPermission } from "hooks/useHasPermission";
import ListActiveDisablements from "components/operations/ListActiveDisablements";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import { useQueryClient } from "@tanstack/react-query";

const TabsWithBorder = styled(Tabs)
    (({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.secondary.main}`
    })
    );

const LoadingOperations: React.FC = () => {

    return (
        <>
            <Grid item lg={6} xs={12}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Typography variant="h4" color="text">
                                <Skeleton variant="text" width={"100px"} height={"100%"} />
                            </Typography>
                            <Skeleton variant="text" width={"100%"} height={"65px"} />
                            <Skeleton variant="text" width={"100%"} height={"65px"} />
                            <Skeleton variant="text" width={"100%"} height={"100px"} />
                            <Skeleton variant="text" width={"100%"} height={"65px"} />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <Typography variant="h4" color="text">
                                <Skeleton variant="text" width={"100px"} height={"100%"} />
                            </Typography>
                            <Skeleton variant="text" width={"100%"} height={"65px"} />
                            <Skeleton variant="text" width={"100%"} height={"65px"} />
                            <Skeleton variant="text" width={"100%"} height={"100px"} />
                            <Skeleton variant="text" width={"100%"} height={"65px"} />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={6} xs={12}>
                <Stack>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Stack>
            </Grid>
        </>
    )
};


const Operations: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const [selectedOpTab] = React.useState("current")

    const { alarmSystemId, alarmSystem, isLoading } = useGetAlarmSystem();
    const { hasPermission } = useHasPermission("localoffice");
    const queryClient = useQueryClient();

    const refreshInterval = 10000;

    useEffect(() => {
        const intervalId = setInterval(() => {
            queryClient.invalidateQueries({ queryKey: ["fire-alarm-system", alarmSystemId] })
        }, refreshInterval);

        return () => clearInterval(intervalId);
    }, [alarmSystemId, queryClient]);

    const activeGateway = alarmSystem?.gateways.find(gw => gw.disconnected === null);
    const gatewayNotConnected = activeGateway?.connected === false

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" sx={{
                    marginBottom: "1.375em",
                    color: (theme) => theme.palette.primary.main,
                    display: { xs: "none", sm: "inherit" }
                }}>
                    {isLoading
                        ? <Skeleton width="10rem" />
                        : `${alarmSystem?.name} / ${localize("navigation.operations")} / ${localize("navigation.disablement")}`}
                </Typography>
            </Box>
            <Grid container>
                {isLoading && (
                    <LoadingOperations />
                )}

                {(hasPermission("command", "add_command") && alarmSystem) && (
                    <Grid item lg={6} xs={12}>
                        <Typography variant="h4" color="text">
                            {localize("operations.zone")}
                        </Typography>
                        <ZoneDisablementForm alarmSystem={alarmSystem} gatewayNotConnected={gatewayNotConnected} />
                        <Typography variant="h4" color="text">
                            {localize("operations.zone-address")}
                        </Typography>
                        <ZoneDisablementForm alarmSystem={alarmSystem} gatewayNotConnected={gatewayNotConnected} useAddress />
                    </Grid>
                )}
                {hasPermission("command", "view_command") && (
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={(theme) => hasPermission("command", "add_command") ? ({
                            paddingLeft: { lg: "32px" },
                            borderLeft: { lg: `1px solid ${theme.palette.grey[600]}` }
                        }) : ({})}
                    >
                        <TabsWithBorder value={selectedOpTab}>
                            <Tab value="current" label={localize("operations.current")} />
                        </TabsWithBorder>
                        {alarmSystem && (
                            <>
                                {<ListActiveDisablements alarmSystem={alarmSystem} gatewayNotConnected={gatewayNotConnected} />}
                            </>
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default Operations;