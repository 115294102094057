import React from "react";
import EventsTable from "../components/events/EventsTable";
import { useParams } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/system";
import { Skeleton, Typography } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import { fetchFireAlarmSystem } from "adapters/fireAlarmSystem";

const Events: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const params = useParams();
    const alarmSystemId = params.id || "alarm";

    const {
        data,
        isLoading
    } = useQuery({
        queryKey: [alarmSystemId],
        queryFn: async () => {
            const response = await fetchFireAlarmSystem(alarmSystemId);
            return {
                data: response.data,
            };
        },
    });

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" sx={{
                    marginBottom: "1.375em",
                    color: (theme) => theme.palette.primary.main,
                    display: { xs: "none", sm: "inherit" }
                }}>
                    {isLoading ? <Skeleton
                        width="10rem" /> : `${data?.data.name} / ${localize("navigation.status")} / ${localize("navigation.events")}`}
                </Typography>
            </Box>
            <EventsTable alarmSystemId={alarmSystemId} />
        </>
    );
};

export default Events;