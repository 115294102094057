import { createSvgIcon } from "@mui/material";
import React from "react";

const ServiceSignalIcon = createSvgIcon(
    <g clipPath="url(#clip0_168_82)">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.3522 22.3654V19.3567C15.2066 19.014 15.9861 18.5185 16.6586 17.9031L19.2114 19.3768L22.0168 14.5178L19.171 12.8749C19.1977 12.6087 19.2114 12.3386 19.2114 12.0651C19.2114 11.6045 19.1725 11.1531 19.098 10.7143L19.1267 10.7641L22.0167 9.09567L19.2113 4.23668L16.3213 5.90505L16.3548 5.96319C15.7594 5.47271 15.0907 5.07167 14.3682 4.77997V2.00003H8.7574V4.78014C8.10686 5.04283 7.49994 5.39418 6.95088 5.81964L4.20872 4.23662L1.40331 9.09561L4.04462 10.6204C3.95927 11.0887 3.91464 11.5716 3.91464 12.0651C3.91464 12.48 3.94618 12.8873 4.00693 13.2847L3.89002 13.0822L1 14.7506L3.80541 19.6096L6.58261 18.0064C7.21973 18.5664 7.94795 19.021 8.74138 19.3436V22.3654H14.3522ZM11.5628 18.4744C15.1027 18.4744 17.9722 15.6049 17.9722 12.065C17.9722 8.52507 15.1027 5.65557 11.5628 5.65557C8.02348 5.65557 5.15397 8.52507 5.15397 12.065C5.15397 15.6049 8.02348 18.4744 11.5628 18.4744Z" />
        <path d="M15.3617 18.3969L14.167 17.0983L12.5518 15.4537C7.5031 16.1747 8.33953 11.2709 8.33953 11.2709L10.3015 13.2321L12.0903 12.6553L12.5518 10.7511L10.7341 8.73208C15.7251 8.32764 14.7733 13.0591 14.7733 13.0591L15.7828 14.1557L18.1485 16.7234L15.3617 18.3969Z" />
    </g>,
    "ServiceSignal"
);

export default ServiceSignalIcon;