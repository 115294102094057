import { Edit } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Popover, Skeleton, Typography } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";

interface ChangableFieldProps {
    isLoading?: boolean;
    label: React.ReactNode;
    data: React.ReactNode;
    popoverFormData?: React.ReactNode;
    popoverAction?: React.ReactNode;
    onSubmit?: () => void;
    hasPermission?: boolean;
}

const ChangableField: React.FC<ChangableFieldProps> = ({ isLoading, label, data, popoverAction, popoverFormData, onSubmit, hasPermission }) => {
    const { localize } = React.useContext(LocaleContext);
    const ref = React.useRef(null);
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    
    const onOpenPopover = (): void => {
        setPopoverOpen(true);
    };

    const onClosePopover = (): void => {
        setPopoverOpen(false);
    };

    const onPopoverFormSubmit = async (event: React.SyntheticEvent): Promise<void> => {
        event.preventDefault();
        if (onSubmit) {
            onSubmit();
            setPopoverOpen(false);
        }
    };

    return (
        <Box ref={ref}>
            <Typography variant="overline" color="primary">
                {isLoading ? <Skeleton width={"150px"} /> : label}
            </Typography>
            <Box display="flex" alignItems="center">
                <Typography
                    component="div"
                    variant="body1"
                    color="text"
                    sx={{
                        whiteSpace: "pre-line",
                        wordBreak: "break-word",
                        maxWidth: {
                            xs: "80%",
                            md: "100%"
                        }
                    }}
                >
                    {isLoading ? <Skeleton width={"200px"} /> : data || "-"}
                </Typography>
                {!!popoverFormData && hasPermission && (
                    <IconButton size="small" color="primary" onClick={onOpenPopover} >
                        {(isLoading) ?
                            <Skeleton variant="circular" width={"18px"} height={"18px"} />
                            : <Edit fontSize="inherit" />
                        }
                    </IconButton>
                )}
            </Box>
            {!!popoverFormData && (
                <Popover
                    open={popoverOpen}
                    anchorEl={ref.current}
                    onClose={onClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <form onSubmit={onPopoverFormSubmit}>
                        <Box padding={"1em"} bgcolor={(theme) => theme.palette.background.default}>
                            <Typography variant="h6" color="primary">
                                {label}
                            </Typography>
                            {popoverFormData}
                        </Box>
                        <Divider />
                        <Box p={"16px 24px"} gap={"8px"} display="flex" bgcolor={(theme) => theme.palette.secondary.main}>
                            <Button variant="outlined" onClick={onClosePopover}>{localize("generics.cancel")}</Button>
                            {popoverAction}
                        </Box>
                    </form>
                </Popover>
            )}
        </Box>
    );
};

export default ChangableField;