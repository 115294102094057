import {
    Button,
    Typography,
    Box,
    Stack,
    LinearProgress,
    CircularProgress,
    Grid
} from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import FormGroup from '@mui/material/FormGroup';
import { ICreateCommand, IFireAlarmSystem, INewCommandFields } from "../../interfaces/fireAlarmSystem";
import { EBLTextField } from "components/StyledComponents/TextField";
import { format, isValid } from "date-fns";
import { TimePicker } from "@mui/x-date-pickers";
import useOperations from "hooks/useOperations";
import { SnackBarContext } from "contexts/SnackBarContext";
import { getMacAddress } from "utils/getMacAdress";
import { useSearchParams } from "react-router-dom";

const zoneRegex = /^(0*[1-9][0-9]{0,2}|[1-9][0-9]{0,2})$/;
const addressRegex = /^(0[1-9]|[1-9]|[1-9][0-9])$/;

const ZoneDisablementForm: React.FC<{ alarmSystem: IFireAlarmSystem, useAddress?: boolean, gatewayNotConnected: boolean }> = ({ alarmSystem, useAddress = false, gatewayNotConnected }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [commandFields, setCommandFields] = React.useState<INewCommandFields>({
        zone: "",
        reason: "",
        time: null,
        address: "",
        smokeOnly: false
    });

    const onSuccess = () => {
        setCommandFields({
            zone: "",
            reason: "",
            address: "",
            time: null,
            smokeOnly: false
        })
        openSnack(localize(`operations.command-executed`), "success");
    };

    const onError = () => {
        openSnack(localize(`operations.general-warning`), "warning");
        setSearchParams({ ...searchParams, modal: 'operations-error' })
    };


    const { createOperation, isExecuting, progress } = useOperations(onSuccess, onError);

    const communicationError = alarmSystem?.deviations.communication_error
    const communicationErrorExists = (communicationError ?? []).length >= 1 ? true : false

    const getArgs = (disable: boolean): ICreateCommand => {
        const asDate = commandFields.time ? new Date(commandFields.time) : null;
        return {
            fire_alarm_system: alarmSystem.id,
            version: 0,
            type: "cmd",
            source: getMacAddress(alarmSystem),
            user: "",
            seq_ack: 0,
            subject: "cmd/disablement/alarmpoint",
            reason: commandFields.reason ? commandFields.reason : "-",
            payload: {
                cmdtype: "execute",
                jobid: "",
                data: {
                    disable: disable,
                    parameters: {
                        zone: Number(commandFields.zone),
                        address: useAddress ? Number(commandFields.address) : 0,
                        autore: !!asDate,
                        autohour: asDate ? format(asDate, "kk") : undefined,
                        autominute: asDate ? format(asDate, "mm") : undefined,
                        smokeonly: commandFields.smokeOnly
                    }
                }
            }
        }
    };

    const disable = (event: React.SyntheticEvent): void => {
        event.preventDefault();
        createOperation(getArgs(true));
    };

    const zoneIsValid = React.useMemo(() => {
        return !!commandFields.zone && zoneRegex.test(commandFields.zone);
    }, [commandFields.zone]);

    const addressIsValid = React.useMemo(() => {
        if (!useAddress) {
            return true;
        }
        return !!commandFields.address && addressRegex.test(commandFields.address);
    }, [commandFields.address, useAddress]);


    const fieldsValid = zoneIsValid && addressIsValid && (commandFields.time === null || isValid(commandFields.time)) && !!alarmSystem;
    const showZoneError = !!commandFields.zone.length && !zoneIsValid;
    const showAddresError = !!commandFields.address?.length && !addressIsValid;

    return (
        <FormGroup>
            <Grid container>
                <Grid item md={6} xs={12}>
                    <EBLTextField
                        variant="outlined"
                        label={localize("operations.zone")}
                        id="zone-input"
                        fullWidth
                        value={commandFields.zone}
                        disabled={isExecuting}
                        error={showZoneError}
                        placeholder={localize("operations.zone-example")}
                        onChange={(e) => setCommandFields({
                            ...commandFields,
                            zone: e.target.value
                        })}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{ ml: { md: 2 } }}
                >
                    {useAddress && (
                        <EBLTextField
                            variant="outlined"
                            label={localize("operations.address")}
                            id="address-input"
                            fullWidth
                            disabled={isExecuting}
                            value={commandFields.address}
                            placeholder={localize("operations.address-example")}
                            onChange={(e) => setCommandFields({
                                ...commandFields,
                                address: e.target.value
                            })}
                            error={showAddresError}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack>
                        <TimePicker
                            label={localize("operations.auto-reenable-time")}
                            value={commandFields.time}
                            onChange={(newTime) => setCommandFields({
                                ...commandFields,
                                time: newTime
                            })}
                            disabled={isExecuting}
                            minTime={new Date(0, 0, 0, 0)}
                            maxTime={new Date(0, 0, 0, 23, 59)}
                            slots={{
                                textField: EBLTextField,
                            }}
                            slotProps={{
                                textField: (params) => ({
                                    ...params,
                                    inputProps: {
                                        ...params.inputProps,
                                        placeholder: localize("operations.time-example")
                                    },
                                    sx: {
                                        ".MuiOutlinedInput-notchedOutline": {
                                            top: 0,
                                            "legend": {
                                                display: "none"
                                            }
                                        }
                                    },
                                    variant: "outlined",
                                    fullWidth: true,
                                    required: false,
                                    id: "auto-reenable-time-input"

                                })
                            }}
                        />
                        <EBLTextField
                            variant="outlined"
                            label={localize("operations.reason")}
                            id="reason-input"
                            value={commandFields.reason}
                            fullWidth
                            disabled={isExecuting}
                            placeholder={localize("operations.reason-example")}
                            onChange={(e) => setCommandFields({
                                ...commandFields,
                                reason: e.target.value
                            })}
                            multiline
                            minRows={2}
                            helperText={`${commandFields.reason.length} / 100`}
                            inputProps={{ maxLength: 100 }}
                        />
                        <Button
                            variant="contained"
                            onClick={disable}
                            disabled={communicationErrorExists || !fieldsValid || isExecuting || gatewayNotConnected}
                            endIcon={isExecuting && <CircularProgress size={"1rem"} />}
                            sx={{
                                marginBottom: "2.750em"
                            }}>
                            <Typography variant="button">
                                {isExecuting ? "Executing" : (
                                    localize("operations.disable")
                                )}
                            </Typography>
                            {isExecuting && (
                                <Box width='100%' position="absolute" top="90%">
                                    <LinearProgress
                                        variant="determinate"
                                        value={progress}
                                    />
                                </Box>
                            )}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </FormGroup>
    )
};

export default ZoneDisablementForm;