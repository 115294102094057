import React from "react";
import { fetchEvents } from "adapters/fireAlarmSystem";
import { Skeleton, TableSortLabel } from "@mui/material";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isLastInArray } from "utils/arrays";
import { useInView } from "react-intersection-observer";
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "components/StyledComponents/Table";
import { LocaleContext } from "contexts/LocaleContext";
import EventTableFotterRow from "./EventTableFotterRow";
import useDateFormat from "hooks/useDateFormat";

type TSortHeader = "payload__timestamp" | "payload__event_text" | "payload__user" | "payload__origin";

const LoadingEventSkelton: React.FC = () => {
    return (
        <StyledTableRow>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
        </StyledTableRow >
    );
};


const EventsTable: React.FC<{ alarmSystemId: string }> = ({ alarmSystemId }) => {
    const { localize } = React.useContext(LocaleContext);
    const [order, setOrder] = React.useState<"asc" | "desc">('desc');
    const [orderBy, setOrderBy] = React.useState<TSortHeader>('payload__timestamp');
    const [totalItems, setTotalItems] = React.useState(0);
    const { ref, inView } = useInView()
    const { formatDate } = useDateFormat();

    const eventsQuery = useInfiniteQuery({
        queryKey: [`events-${alarmSystemId}`, order, orderBy],
        queryFn: async ({ pageParam }) => {
            const params = {
                fire_alarm_system: alarmSystemId,
                page: pageParam,
                ordering: order === "asc" ? orderBy : `-${orderBy}`,
            };
            const res = await fetchEvents({ params: params })
            setTotalItems(res.data.count);
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    const handleRequestSort = (property: TSortHeader) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    React.useEffect(() => {
        if (inView) {
            eventsQuery.fetchNextPage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    interface Columns { text: string, sort_header: TSortHeader }

    const columns: Columns[] = [
        {
            text: "event-time",
            sort_header: "payload__timestamp"
        },
        {
            text: "event-text",
            sort_header: "payload__event_text"
        },
        {
            text: "user",
            sort_header: "payload__user"
        },
        {
            text: "origin",
            sort_header: "payload__origin"
        }
    ]

    const handleUpdateQuery: () => void = () => {
        eventsQuery.refetch();
    };

    return (
        <>
            <StyledTableContainer>
                <Table sx={{ width: '100%' }} size="small" stickyHeader >
                    <TableHead>
                        <TableRow>
                            {columns.map((entry) => (
                                <StyledTableCell
                                    style={{ wordBreak: "normal" }}
                                    key={entry.text}
                                >
                                    <TableSortLabel
                                        active={orderBy === entry.sort_header}
                                        direction={orderBy === entry.sort_header ? order : 'asc'}
                                        onClick={() => handleRequestSort(entry.sort_header)}
                                    >
                                        {localize(`events.${entry.text}`)}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventsQuery.data?.pages.every(datablock => datablock.data.length === 0) ? (
                            <StyledTableRow key={"no-data"} >
                                <StyledTableCell colSpan={columns.length}>{localize("events.no-data-available")}</StyledTableCell>
                            </StyledTableRow>
                        ) : eventsQuery.data?.pages.map((datablock, i) => datablock.data.map((event, j) => (
                            <StyledTableRow
                                key={event.id}
                                ref={isLastInArray(eventsQuery.data?.pages, i) && isLastInArray(datablock.data, j) ? ref : null}
                            >
                                <StyledTableCell >{formatDate(event.payload.timestamp)}</StyledTableCell>
                                <StyledTableCell >{event.payload.event_text}</StyledTableCell>
                                <StyledTableCell >{event.payload.user}</StyledTableCell>
                                <StyledTableCell >{event.payload.origin}</StyledTableCell>

                            </StyledTableRow>
                        )))}
                        {eventsQuery.isFetchingNextPage && ref != null && (
                            <LoadingEventSkelton />
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <EventTableFotterRow alarmSystemId={alarmSystemId} totalCount={totalItems} handleUpdateQuery={handleUpdateQuery} order={order} orderBy={orderBy} />
        </>
    )
}

export default EventsTable;