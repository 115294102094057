import { Box, Button, Typography } from "@mui/material";
import { BaseStepper, BaseStep, BaseStepLabel } from "components/StyledComponents/Stepper";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import InstallStep from "./setupSteps/InstallStep";
import RegisterAppStep from "./setupSteps/RegisterAppStep";
import VerifyTOTPRegistrationStep from "./setupSteps/VerifyRegistrationStep";


enum StepKey {
    INSTALL = 0,
    REGISTER = 1,
    VERIFY = 2
}

type TStep = {
    key: StepKey,
    label: string;
};

const steps: TStep[] = [
    {
        key: StepKey.INSTALL,
        label: "totp.install"
    },
    {
        key: StepKey.REGISTER,
        label: "totp.register"
    },
    {
        key: StepKey.VERIFY,
        label: "totp.verify"
    }
];

export interface ICancelButton {
    cancelButton: React.ReactNode;
}

interface SetupTOTPProps {
    configUrl: string;
    verifyForm: React.ReactNode;
    onCancel: () => void;
}
  

const SetupTOTP: React.FC<SetupTOTPProps> = ({ configUrl, verifyForm, onCancel }) => {   
    const { localize } = React.useContext(LocaleContext);
    const [activeStep, setActiveStep] = React.useState<StepKey>(StepKey.INSTALL);

    const onNextStep = () => {
        if (activeStep === 2) {
            return
        }
        setActiveStep(activeStep + 1)
    };

    const renderStep = (): React.ReactNode => {
        switch (activeStep) {
            case StepKey.INSTALL:
                return <InstallStep />
            case StepKey.REGISTER:
                return <RegisterAppStep configUrl={configUrl}/>
            case StepKey.VERIFY:
                return (
                    <>
                        <VerifyTOTPRegistrationStep />
                        {verifyForm}
                    </>
                )
            default:
                return null;
        }
    };


    return (
        <>
            <Typography gutterBottom color="primary" variant="h1">
                {localize("totp.setup-title")}
            </Typography>
            <BaseStepper activeStep={activeStep}>
                {steps.map((step) => (
                    <BaseStep key={step.key}>
                        <BaseStepLabel>{localize(step.label)}</BaseStepLabel>
                    </BaseStep>
                ))}
            </BaseStepper>
            <Box margin="2em 0">
                {renderStep()}
            </Box>
            {activeStep !== StepKey.VERIFY && (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Button variant="outlined" onClick={onCancel}>
                        {localize("generics.cancel")}
                    </Button>
                    <Button variant="contained" onClick={onNextStep}>
                        {localize("generics.continue")}
                    </Button>
                </Box>
            )}
        </>
    );
};

export default SetupTOTP;