import { Box, Typography } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";


const NotFoundView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <Box
            bgcolor={(theme) => theme.palette.background.default}
            display="flex" 
            height="100px"
            width="200px"
            borderRadius={"5px"}
            justifyContent="center"
            alignItems="center">
            <Typography variant="h4">{localize("error.notfound")}</Typography>
        </Box>
    );
};

export default NotFoundView;