import { Delete } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemButton, ListItemText, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { fetchOrganizations } from "adapters/organizations";
import { BaseDialog } from "components/StyledComponents/Dialog";
import ListInvitations from "components/userManagement/ListInvitations";
import { LocaleContext } from "contexts/LocaleContext";
import useUserManagement from "hooks/useUserManagement";
import React from "react";
import { useInView } from "react-intersection-observer";
import { isLastInArray } from "utils/arrays";
import ConfirmRemoveOrganization from "./ConfirmRemoveOrganization";
import ExportOrganizationButton from "./ExportOrganizationButton";
import OrganizationDialogContent from "./OrganizationDialogContent";
import ListUsersByRoleAssignment from "components/userManagement/ListUsersByRoleAssignment";
import { useHasPermission } from "hooks/useHasPermission";
import theme from "styling/theme";
import useFormValidation from "hooks/useFormValidation";

const LoadingOrganizationSkeleton: React.FC = () => {
    return (
        <ListItem
            dense
            sx={{ padding: 0 }}
            secondaryAction={
                <Stack direction="row" gap="1em">
                    <Skeleton variant="circular" width="18px" />
                    <Skeleton variant="circular" width="18px" />
                </Stack>
            }
        >
            <ListItemText
                sx={{ margin: 0 }}
                primaryTypographyProps={{ variant: "subtitle2" }}
                primary={<Skeleton width="50%" />}
                secondary={<Skeleton width="30%" />}
            />
        </ListItem>
    );
};

type TStatusType = "pending" | "active";

interface IListOrganizationDialogState {
    organizationName: string;
    organizationId: string;
    type: TStatusType | "delete" | "";
}

interface IListOrganizationProps {
    status: TStatusType;
    ordering?: string;
}

const ListOrganizations: React.FC<IListOrganizationProps> = ({ status, ordering }) => {
    const { localize } = React.useContext(LocaleContext);
    const { ref, inView } = useInView();
    const [dialogState, setDialogState] = React.useState<IListOrganizationDialogState>({
        organizationName: "",
        organizationId: "",
        type: ""
    });
    const { roleAssignments } = useUserManagement(dialogState.organizationId);
    const closeDialog = () => setDialogState({ organizationName: "", organizationId: "", type: "" });
    const { hasPermission } = useHasPermission();
    const { truncateString } = useFormValidation();

    const organizationsQuery = useInfiniteQuery({
        queryKey: [`organizations-${status}`],
        queryFn: async ({ pageParam }) => {
            const params = {
                page: pageParam,
                ordering: ordering,
                page_size: 10,
                ...status === "pending" ? {
                    "number_of_users": 0
                } : {
                    "number_of_users__gte": 1
                }
            };
            const res = await fetchOrganizations({ params: params })
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    React.useEffect(() => {
        if (inView) {
            organizationsQuery.fetchNextPage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    const renderDialogContent = () => {
        switch (dialogState.type) {
            case "active":
                return (
                    <OrganizationDialogContent
                        onClose={closeDialog}
                        title={`${localize("organization-management.active-users")} / ${truncateString(dialogState.organizationName)} `}
                    >
                        {roleAssignments.map(roleAssignment => (
                            <ListUsersByRoleAssignment key={roleAssignment.id} roleAssignment={roleAssignment} displayRole={true} />
                        ))}
                    </OrganizationDialogContent>
                );
            case "pending":
                return (
                    <OrganizationDialogContent
                        onClose={closeDialog}
                        title={`${localize("organization-management.pending-invitations")} / ${truncateString(dialogState.organizationName)} `}
                    >
                        <ListInvitations contextObjectId={dialogState.organizationId} />
                    </OrganizationDialogContent>
                );
            case "delete":
                return (
                    <ConfirmRemoveOrganization
                        organizationId={dialogState.organizationId}
                        onClose={closeDialog}
                    />
                );
            default:
                return null;
        }
    }

    if (organizationsQuery.isFetching && !organizationsQuery.isFetchingNextPage) {
        return (
            <List dense>
                <LoadingOrganizationSkeleton key={1} />
                <LoadingOrganizationSkeleton key={2} />
                <LoadingOrganizationSkeleton key={3} />
                <LoadingOrganizationSkeleton key={4} />
            </List>
        );
    }
    return (
        <>
            <List dense>
                {organizationsQuery.data?.pages.map((group, i) => group.data.map((organization, j) => (
                    <ListItem
                        dense
                        key={organization.id}
                        sx={{ padding: 0 }}
                        ref={isLastInArray(organizationsQuery.data?.pages, i) && isLastInArray(group.data, j) ? ref : null}
                        secondaryAction={
                            <Stack direction="row" gap="1em">
                                {hasPermission("organization", "delete_organization") && (
                                    <>
                                        <ExportOrganizationButton organizationId={organization.id} />
                                        <IconButton
                                            edge="end"
                                            onClick={() => setDialogState({
                                                organizationName: organization.name,
                                                organizationId: organization.id,
                                                type: "delete"
                                            })}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </>
                                )}
                            </Stack>
                        }
                    >
                        <ListItemButton
                            style={{
                                backgroundColor: dialogState.organizationId === organization.id ?
                                    theme.palette.background.paper : undefined,
                                borderRadius: "6px"
                            }}
                            dense
                            disableGutters
                            onClick={() => setDialogState({
                                organizationName: organization.name,
                                organizationId: organization.id,
                                type: status
                            })}
                        >
                            <ListItemText
                                sx={{ margin: 0 }}
                                primaryTypographyProps={{ variant: "subtitle2" }}
                                primary={organization.name}
                                secondary={organization.org_nr}
                            />
                        </ListItemButton>
                    </ListItem>
                )))}
                {organizationsQuery.isFetchingNextPage && (
                    <LoadingOrganizationSkeleton key={"loading"} />
                )}
            </List>
            <BaseDialog
                fullWidth
                maxWidth="xs"
                open={!!dialogState.organizationId}
                onClose={closeDialog}
            >
                {renderDialogContent()}
            </BaseDialog>
        </>
    );
};

export default ListOrganizations;