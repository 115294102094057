import { createSvgIcon } from "@mui/material";

export const JournalIcon = createSvgIcon(
    <g width="24" fill="currentColor" height="24" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M22 2H3.412v20H22V2ZM3.412 0a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2H22a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H3.412Z" />
        <path d="M9.912 4.638h9.353v3.966H9.912z" />
        <rect y="4.138" width="3.765" height="1.241" rx=".621" />
        <rect y="9.931" width="3.765" height="1.241" rx=".621" />
        <path d="M9.412 12.414h10.353v1.241H9.412zM9.412 17.379h10.353v1.241H9.412zM9.412 14.896h10.353v1.241H9.412z" />
        <rect y="7.034" width="3.765" height="1.241" rx=".621" />
    </g>,
    "JournalIcon"
);
