import { Delete, Settings } from "@mui/icons-material";
import { Button, IconButton, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchTOTPDevices, ITOTPDevice, patchTOTPDevice } from "adapters/auth";
import ChangableField from "components/ChangableField";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import { useHasPermission } from "hooks/useHasPermission";
import React from "react";

interface IListDevicesProps {
    onSelectForDeletion: (device: ITOTPDevice) => void;
    onSelectForConfiguration: (config_url: string) => void;
}

const ListDevices: React.FC<IListDevicesProps> = ({ onSelectForDeletion, onSelectForConfiguration }) => {
    const { localize } = React.useContext(LocaleContext);
    const queryClient = useQueryClient();
    const [mutableDevices, setMutableDevices] = React.useState<ITOTPDevice[]>([]);
    const { hasPermission } = useHasPermission();

    const devicesQuery = useQuery({
        queryKey: ["devices"],
        queryFn: async () => {
            const queryResult = await fetchTOTPDevices()
            if (queryResult.data) {
                setMutableDevices([...queryResult.data.results]);
            }
            return queryResult
        }
    });

    const patchDeviceMutation = useMutation({
        mutationFn: async (deviceId: string) => {
            const device = mutableDevices.find(d => d.id === deviceId);
            if (device) {
                await patchTOTPDevice(deviceId, { name: device?.name });
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["devices"] });
        }
    });

    const onChangeDeviceName = (name: string, deviceId: string): void => {
        setMutableDevices(mutableDevices.map(dev =>
            dev.id === deviceId ? { ...dev, name: name } : dev
        ));
    };

    const canDeleteDevice = devicesQuery.data
        ? devicesQuery.data?.data.results.length > 1
        : false;

    return <>
        {devicesQuery.data?.data.results.map((device, index) => (
            <Box key={device.id} display="flex" alignItems="flex-end">
                <ChangableField
                    isLoading={devicesQuery.isFetching}
                    label={`${localize("account.device")} ${index + 1}`}
                    data={device.name}
                    onSubmit={() => patchDeviceMutation.mutate(device.id)}
                    popoverFormData={
                        <EBLTextField
                            variant="outlined"
                            label={localize("generics.name")}
                            id="name"
                            value={mutableDevices.find(d => d.id === device.id)?.name}
                            onChange={(e) => onChangeDeviceName(e.target.value, device.id)}
                            inputProps={{ maxLength: 64 }}
                        />
                    }
                    popoverAction={
                        <Button variant="contained" type="submit">
                            {localize("account.update-device-name")}
                        </Button>
                    }
                    hasPermission={hasPermission("totpdevice", "change_totpdevice")}
                />
                {!device.confirmed && (
                    <IconButton size="small" onClick={() => onSelectForConfiguration(device.config_url)}>
                        {devicesQuery.isFetching ? (
                            <Skeleton variant="circular" width={"18px"} height={"18px"} />
                        ) : (
                            <Settings fontSize="inherit" color="primary" />
                        )}
                    </IconButton>
                )}
                {canDeleteDevice && !device.is_last_confirmed_device && (
                    <IconButton size="small" onClick={() => onSelectForDeletion(device)}>
                        {devicesQuery.isFetching ? (
                            <Skeleton variant="circular" width={"18px"} height={"18px"} />
                        ) : (
                            <Delete fontSize="inherit" color="primary" />
                        )}
                    </IconButton>
                )}
            </Box>
        ))}
    </>;
};

export default ListDevices;