import React from "react";
import OrganizationManagementView from "./OrganizationManagement";
import OverviewView from "./Overview";
import useClientConfig from "hooks/useClientConfig";

const DashboardView: React.FC = () => {
    const clientConfig = useClientConfig();


    if (!clientConfig.data?.data.is_authenticated) {
        return null;
    }

    if (clientConfig.data?.data.dashboard === true) {
        return (
            <OrganizationManagementView />
        )
    }
    return (
        <OverviewView />
    )

};

export default DashboardView;