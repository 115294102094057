import { Dialog, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';


export const BaseDialog = styled(Dialog)
    (({theme}) => ({
        ".MuiPaper-root": {
            backgroundColor: theme.palette.background.default,
            border: `0.5px solid ${theme.palette.grey[400]}`
        },
    })
);

export const BaseDialogActions = styled(DialogActions)
    (({theme}) => ({
        backgroundColor: theme.palette.grey[100],
        borderTop: `1px solid ${theme.palette.grey[400]}`
    })
);