import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useSearchParams } from "react-router-dom";
import CreateAlarmSystemDialogContent from "./fireAlarmSystem/CreateAlarmSystemDialogContent";
import { BaseDialog } from "./StyledComponents/Dialog";
import OperationFailedDialogContent from "./operations/OperationFailedModalContent";

interface DialogConfig {
    key: string;
    component: React.ReactNode;
}
const GlobalDialogs: React.FC = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const openModals = searchParams.getAll("modal");

    const onClose = (): void => {
        searchParams.delete("modal");
        setSearchParams(searchParams);
        queryClient.invalidateQueries({
            queryKey: ['fireAlarmSystems'],
            refetchType: 'all',
        });

    };

    const dialogConfigs: DialogConfig[] = [
        {
            key: "create-alarm",
            component: <CreateAlarmSystemDialogContent onClose={onClose} />
        },
        {
            key: "operations-error",
            component: <OperationFailedDialogContent onClose={onClose} />
        }
    ];

    return (
        <>
            {dialogConfigs.map((conf, index) => (
                <BaseDialog 
                    fullWidth
                    maxWidth="xs"
                    key={index}
                    onClose={onClose} 
                    open={openModals.includes(conf.key)}>
                    <>
                        {conf.component}
                    </>
                </BaseDialog>
            ))}
        </>
    )
};

export default GlobalDialogs;