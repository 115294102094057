import { Box, CircularProgress } from "@mui/material";
import { ModelType, PermissionType } from "adapters/auth";
import useClientConfig from "hooks/useClientConfig";
import { useHasPermission } from "hooks/useHasPermission";
import React from "react";
import NotFoundView from "views/404";

interface IAuthRequired {
    main: React.ReactNode;
    fallback?: React.ReactNode;
    permissionParams?: [ModelType, PermissionType<ModelType>]
}

const AuthRequired: React.FC<IAuthRequired> = ({ main, fallback, permissionParams }) => {
    const clientConfig = useClientConfig();
    const { hasPermission, clientPermission } = useHasPermission();


    if (clientConfig.data?.data.is_authenticated) {
        if (clientConfig.isLoading || !clientPermission.isFetched || clientPermission.isLoading) {
            return (
                <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>
            )
        }

        if (!!permissionParams && !hasPermission(permissionParams[0], permissionParams[1])) {
            return <NotFoundView />
        }
        return <>{main}</>;
    }
    if (!fallback) {
        throw new Response("Not Found", { status: 404 });
    }

    return <>{fallback}</>;
};

export default AuthRequired;