import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "router";
import { useQuery } from "@tanstack/react-query";
import { refetchToken } from "adapters/auth";
import { Box, CircularProgress } from "@mui/material";
import OrganizationProvider from "contexts/OrganizationContext";
import useClientConfig from "hooks/useClientConfig";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";


const RouteWrapper: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const logoutTimerIdRef = React.useRef(-1);
    const clientConfig = useClientConfig();

    useQuery({
        queryKey: ["refreshToken"],
        queryFn: async () => {
            const token = sessionStorage.getItem("accessToken");
            const clientId = sessionStorage.getItem("client_id");

            const data = {
                client_id: clientId ? JSON.parse(clientId) : "",
                grant_type: "refresh_token",
                refresh_token: token ? JSON.parse(token)["refresh_token"] : ""
            };
            const queryResult = await refetchToken(data);
            if (queryResult.data) {
                sessionStorage.setItem("accessToken", JSON.stringify(queryResult.data));
            }
            else {
                sessionStorage.removeItem("accessToken");
            }
        },
        refetchInterval: 25 * 60000,
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        enabled: !!clientConfig.data?.data.is_authenticated
    });

    const onLogout = React.useCallback(() => {
        if (clientConfig.data?.data.is_authenticated) {
            openSnack(localize("generics.inactivity-logout"), "warning", undefined, undefined, false);
            sessionStorage.removeItem("accessToken");
            clientConfig.refetch();
        }
    }, [clientConfig, localize, openSnack]);


    React.useEffect(() => {
        const autoLogout = () => {
            if (document.visibilityState === 'hidden') {
                const timeOutId = window.setTimeout(() => onLogout(), 15 * 60000);
                logoutTimerIdRef.current = timeOutId;
            } else {
                window.clearTimeout(logoutTimerIdRef.current);
            }
        };

        document.addEventListener('visibilitychange', autoLogout);

        return () => {
            document.removeEventListener('visibilitychange', autoLogout);
        };
    }, [onLogout]);

    return (
        <OrganizationProvider is_authenticated={clientConfig.data?.data.is_authenticated}>
            {clientConfig.isLoading ? (
                <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>
            ) : (
                <RouterProvider router={router} />
            )}
        </OrganizationProvider>
    )

};

export default RouteWrapper;