import { Download } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { exportOrganization } from "adapters/organizations";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import React from "react";


interface IExportOrganizationButton {
    organizationId: string;
}

const ExportOrganizationButton: React.FC<IExportOrganizationButton> = ({ organizationId }) => {
    const { openSnack } = React.useContext(SnackBarContext);
    const { localize } = React.useContext(LocaleContext);
    const exportOrganizationQuery = useQuery({
        queryKey: ["organizations-export", organizationId],
        queryFn: async () => {
            const queryResult = await exportOrganization(organizationId)
            if (queryResult.data) {
                let a = document.createElement("a");
                let file = new Blob([JSON.stringify(queryResult.data.data, null, 2)], { type: 'text/plain' });
                a.href = URL.createObjectURL(file);
                a.download = `${organizationId}.txt`;
                a.click();
                openSnack(localize("organization-management.download-complete"), "success")
            }
        },
        refetchOnWindowFocus: false,
        enabled: false
    });


    return (
        <IconButton
            edge="end"
            onClick={() => exportOrganizationQuery.refetch()}
        >
            {exportOrganizationQuery.isFetching ? <CircularProgress size={"24px"} /> : <Download />}
        </IconButton>
    )
};

export default ExportOrganizationButton;