import { Box, Button, CircularProgress, DialogTitle } from "@mui/material";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { BaseStep, BaseStepLabel, BaseStepper } from "components/StyledComponents/Stepper";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import React from "react";
import { useNavigate } from "react-router-dom";
import CreateContactPersonForm from "./forms/CreateContactPersonForm";
import CreateAlarmSystemForm from "./forms/CreateFireAlarmSystemForm";
import CreateGatewayForm from "./forms/CreateGatewayForm";
import { getFieldErrorMessage } from "utils/getFieldErrorMessage";
import { AxiosError } from "axios";

const stepLabels = [
    "configuration.create-system-step-1",
    "configuration.create-system-step-2",
    "configuration.create-system-step-3"
];


interface CreateAlarmSystemDialogContentProps {
    onClose: () => void;
}

const CreateAlarmSystemDialogContent: React.FC<CreateAlarmSystemDialogContentProps> = ({ onClose }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const isCreatingAlarm = useIsMutating({ mutationKey: ["create-fire-alarm-system"] });
    const isCreatingContact = useIsMutating({ mutationKey: ["create-fire-alarm-contact"] });
    const isCreatingGateway = useIsMutating({ mutationKey: ["create-gateway"] });
    const navigate = useNavigate();
    const [newFireAlarmId, setFireAlarmId] = React.useState("");
    const [selectedLocalOfficeId, setLocalOfficeId] = React.useState("");
    const [activeStep, setActiveStep] = React.useState(0);
    const queryClient = useQueryClient();

    const isLoading = !!isCreatingAlarm || !!isCreatingContact || !!isCreatingGateway;

    const buttons = (
        <>
            <Button variant="outlined" onClick={onClose}>
                {localize("generics.cancel")}
            </Button>
            <Button
                variant="contained"
                type="submit"
                disabled={!!isLoading}
                endIcon={!!isLoading && <CircularProgress size={"1rem"} />}
            >
                {localize("configuration.create-system-next-step")}
            </Button>
        </>
    );

    const renderStep = (): React.ReactNode => {
        switch (activeStep) {
            case 0:
                return (
                    <CreateAlarmSystemForm
                        onSuccess={onSuccessCreateAlarm}
                        onError={onFailureCreateAlarm}
                        buttons={buttons}
                    />
                );
            case 1:
                return (
                    <CreateContactPersonForm
                        onSuccess={onSuccessCreateContact}
                        alarmSystemId={newFireAlarmId}
                        buttons={buttons}
                    />
                )
            case 2:
                return (
                    <CreateGatewayForm
                        onSuccess={onSuccessCreateGateway}
                        onError={onFailureCreateGateway}
                        alarmSystemId={newFireAlarmId}
                        buttons={[
                            <Button variant="outlined" onClick={onClose}>
                                {localize("generics.skip")}
                            </Button>,
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={!!isCreatingGateway}
                                endIcon={!!isCreatingGateway && <CircularProgress size={"1rem"} />}
                            >
                                {localize("generics.confirm")}
                            </Button>
                        ]}
                    />
                )
            default:
                return null;
        }
    };

    const onSuccessCreateAlarm = (fireAlarmSystemId: string, localOfficeId: string): void => {
        setFireAlarmId(fireAlarmSystemId);
        setLocalOfficeId(localOfficeId);
        queryClient.invalidateQueries({ queryKey: ["fireAlarmSystems"] })
        setActiveStep(1);
    };

    const onFailureCreateAlarm = (e: AxiosError): void => {
        const fieldError = getFieldErrorMessage(e)
        if (fieldError) {
            openSnack(`${fieldError.message}`, "error");
        }
        else {
            openSnack(localize("configuration.system-already-exists"), "error")
        }
    };

    const onSuccessCreateContact = (): void => {
        setActiveStep(2);
    };

    const onSuccessCreateGateway = (): void => {
        navigate(`/${selectedLocalOfficeId}/configuration/${newFireAlarmId}`);
    };

    const onFailureCreateGateway = (): void => {
        openSnack(localize("configuration.configure-system-error"), "error")
        navigate(`/${selectedLocalOfficeId}/configuration/${newFireAlarmId}`);
    };

    return (
        <Box>
            <DialogTitle color="primary">
                {localize("configuration.create-system-title")}
            </DialogTitle>
            <BaseStepper activeStep={activeStep} sx={{ padding: "0 2em" }}>
                {stepLabels.map(label => (
                    <BaseStep key={label}>
                        <BaseStepLabel>{localize(label)}</BaseStepLabel>
                    </BaseStep>
                ))}
            </BaseStepper>
            {renderStep()}
        </Box>
    );
};

export default CreateAlarmSystemDialogContent;