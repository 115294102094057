import { Button, Divider, Popover, Skeleton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { fetchFireAlarmSystemContactPersons } from "adapters/fireAlarmSystem";
import { LocaleContext } from "contexts/LocaleContext";
import DisplayContactPerson from "./DisplayContactPerson";
import CreateContactPersonForm from "./forms/CreateContactPersonForm";



const ListContactPersons: React.FC<{ alarmSystemId?: string, hasPermission?: boolean }> = ({ alarmSystemId, hasPermission }) => {
    const { localize } = React.useContext(LocaleContext);
    const [createContactPopoverOpen, setPopoverOpen] = React.useState(false);
    const ref = React.useRef(null);
    const alarmSystemContactPersonQuery = useQuery({
        queryKey: [`fire-alarm-contact-persons-${alarmSystemId}`],
        queryFn: async () => await fetchFireAlarmSystemContactPersons(alarmSystemId ?? ""),
        enabled: !!alarmSystemId
    });

    const onClosePopover = () => setPopoverOpen(false);

    return (
        <>
            <Stack gap="1em" overflow="auto"
                sx={{
                    maxHeight: {
                        xs: "inherit",
                        sm: "50vh"
                    }
                }}>
                {alarmSystemContactPersonQuery.isLoading ? (
                    <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </>
                ) : alarmSystemContactPersonQuery.data?.data.results.map((contact, i) => (
                    <Box key={i}>
                        <DisplayContactPerson contact={contact} />
                        {i !== alarmSystemContactPersonQuery.data?.data.results.length - 1 && (
                            <Divider sx={{ mt: "1em" }} flexItem />
                        )}
                    </Box>
                ))}
            </Stack>
            {hasPermission &&
                <Button ref={ref} variant="outlined" sx={{ m: "1em 0" }} onClick={() => setPopoverOpen(true)}>
                    {localize("configuration.add-contact")}
                </Button>
            }
            <Popover
                open={createContactPopoverOpen}
                anchorEl={ref.current}
                onClose={onClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box bgcolor={(theme) => theme.palette.background.default}>
                    <Typography variant="h6" color="primary" padding="1em 1em 0 1em">
                        {localize("configuration.add-contact")}
                    </Typography>
                    {alarmSystemId && (
                        <CreateContactPersonForm
                            onSuccess={onClosePopover}
                            alarmSystemId={alarmSystemId}
                            buttons={
                                <>
                                    <Button variant="outlined" onClick={onClosePopover}>
                                        {localize("generics.cancel")}
                                    </Button>
                                    <Button variant="contained" type="submit">
                                        {localize("configuration.add-contact")}
                                    </Button>
                                </>
                            }
                        />
                    )}
                </Box>
            </Popover>
        </>
    )

};

export default ListContactPersons;