import { Card, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import background from "assets/images/no-auth-background.jpeg"

export const NoAuthCard = styled(Card)
    (({theme}) => ({
        padding: "40px 60px",
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down("sm")]: {
            padding: "32px 20px 24px",
            marginBottom: 0,
        },
        overflow: "visible"

    })
);


export const NoAuthWrapper = styled("div")
    (() => ({
        backgroundImage: `url(${background})`,
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: "100vw",
        height: "100vh",
        overflow: "auto"
    })
);

export const NoAuthContainer = styled(Container)
    (() => ({
        display: "flex",
        flexDirection: 'column',
        justifyContent: "space-between",
        alignItems: "center",
        height: "90%",
    })
);