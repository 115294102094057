
import { Badge, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

type CardProps = {
    warning?: boolean;
    error?: boolean;
    disablement?: boolean;
};

export const AlarmSystemCard = styled(Card, { shouldForwardProp: (prop) => !['warning', 'error', 'disablement'].includes(prop.toString()) })<CardProps>
    (({warning, error, disablement, theme}) => ({
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.primary.light,
        boxShadow: "none",
        borderWidth: "8px",
        ...(disablement && {
            backgroundColor: theme.palette.primary.light,
        }),
        ...(warning && {
            backgroundColor: theme.palette.warning.main,
        }),
        ...(error && {
            ".MuiSvgIcon-root": {
                color: theme.palette.secondary.light,
            },
            ".MuiTableCell-root": {
                color: theme.palette.secondary.light,
            },
            ".MuiTypography-root": {
                color: theme.palette.secondary.light,
            },
            borderWidth: 0,
            backgroundColor: theme.palette.error.main,
        }),
    })
);


export const AlarmSystemBadge = styled(Badge)
    (({theme}) => ({
        ".MuiBadge-badge": {
            fontSize: "8px",
            fontWeight: 700,
            height: "12px",
            minWidth: "12px",
            padding: 0,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        ".MuiBadge-anchorOriginTopLeftRectangular": {
            left: "4px",
            top: "2px"
        }
    })
);