import React from "react";
import { fetchJournal } from "adapters/fireAlarmSystem";
import { Skeleton, TableSortLabel } from "@mui/material";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isLastInArray } from "utils/arrays";
import { useInView } from "react-intersection-observer";
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "components/StyledComponents/Table";
import { LocaleContext } from "contexts/LocaleContext";
import useDateFormat from "hooks/useDateFormat";
import JournalTableFotterRow from "./JournalTableFotterRow";
import { JournalIcon } from "components/svg/JournalIcon";

type TSortHeader = "created" | "icon" | "entry_text" | "written_by_email";

const LoadingJournalSkelton: React.FC = () => {
    return (
        <StyledTableRow>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
        </StyledTableRow >
    );
};


const JournalTable: React.FC<{ alarmSystemId: string | undefined }> = ({ alarmSystemId }) => {
    const { localize } = React.useContext(LocaleContext);
    const [order, setOrder] = React.useState<"asc" | "desc">('desc');
    const [orderBy] = React.useState<TSortHeader>('created');
    const [totalItems, setTotalItems] = React.useState(0);
    const { ref, inView } = useInView()
    const { formatDate } = useDateFormat();

    const journalQuery = useInfiniteQuery({
        queryKey: [`journal-${alarmSystemId}`, order, orderBy],
        queryFn: async ({ pageParam }) => {
            const params = {
                fire_alarm_system: alarmSystemId,
                page: pageParam,
                ordering: order === "asc" ? orderBy : `-${orderBy}`,
            };
            const res = await fetchJournal({ params: params })
            setTotalItems(res.data.count);
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    const handleRequestSort = () => {
        const isAsc = orderBy === "created" && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
    };

    React.useEffect(() => {
        if (inView) {
            journalQuery.fetchNextPage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    interface Columns { text: string, sort_header: TSortHeader, enable_sorting: Boolean }

    const columns: Columns[] = [
        {
            text: "journal-time",
            sort_header: "created",
            enable_sorting: true
        },

        {
            text: "",
            sort_header: "icon",
            enable_sorting: false
        },
        {
            text: "journal-text",
            sort_header: "entry_text",
            enable_sorting: false
        },
        {
            text: "journal-user",
            sort_header: "written_by_email",
            enable_sorting: false
        }
    ]

    const handleUpdateQuery: () => void = () => {
        journalQuery.refetch();
    };

    return (
        <>
            <StyledTableContainer>
                <Table sx={{ width: '100%' }} size="small" stickyHeader >
                    <TableHead>
                        <TableRow>
                            {columns.map((entry) => (
                                <StyledTableCell
                                    key={entry.text}
                                    style={{ wordBreak: "normal" }}
                                >
                                    {entry.enable_sorting === true ?
                                        <TableSortLabel
                                            key={entry.text}
                                            active={orderBy === "created"}
                                            direction={orderBy === "created" ? order : 'asc'}
                                            onClick={() => handleRequestSort()}
                                        >
                                            {localize(`journal.${entry.text}`)}
                                        </TableSortLabel>
                                        :
                                        localize(`journal.${entry.text}`)
                                    }
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {journalQuery.data?.pages.every(datablock => datablock.data.length === 0) ? (
                            <StyledTableRow key={"no-data"} >
                                <StyledTableCell colSpan={columns.length}>{localize("journal.no-data-available")}</StyledTableCell>
                            </StyledTableRow>
                        ) : journalQuery.data?.pages.map((datablock, i) => datablock.data.map((entity, j) => (
                            <StyledTableRow
                                key={entity.id}
                                ref={isLastInArray(journalQuery.data?.pages, i) && isLastInArray(datablock.data, j) ? ref : null}
                            >
                                <StyledTableCell >{formatDate(entity.created)}</StyledTableCell>
                                {entity.type === "journal" ? (
                                    <StyledTableCell style={{ textAlign: "right" }}>
                                        <JournalIcon color="primary" />
                                    </StyledTableCell>
                                ) :
                                    <StyledTableCell >
                                        {'\u00A0'}
                                    </StyledTableCell>}
                                {entity.type === "command" ? (
                                    <StyledTableCell>
                                        {entity.entry_text} {'\n'}
                                        {entity.command_status} {'\n'}
                                        {entity.command_reason}
                                    </StyledTableCell>
                                ) :
                                    <StyledTableCell>
                                        {entity.entry_text}
                                    </StyledTableCell>
                                }
                                <StyledTableCell>{entity.written_by_email}</StyledTableCell>

                            </StyledTableRow>
                        )))}
                        {journalQuery.isFetchingNextPage && ref != null && (
                            <LoadingJournalSkelton />
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <JournalTableFotterRow alarmSystemId={alarmSystemId} totalCount={totalItems} handleUpdateQuery={handleUpdateQuery} order={order} orderBy={orderBy} />

        </>
    )
}

export default JournalTable;