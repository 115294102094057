import { Button, DialogContent, DialogTitle, Divider, Grid, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BaseDialog, BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import { ILocalOffice, IRole, IUser } from "interfaces/userManagement";
import React from "react";
import webAPIRequest from "api";
import { IOrganization } from "interfaces/organization";
import { fetchRoleAssignments, removeUserFromGroup } from "adapters/userManagement";
import ConfirmRemoveUser from "./ConfirmRemoveUser";
import { Delete } from "@mui/icons-material";
import { useHasPermission } from "hooks/useHasPermission";
import { IErrorWithDetail } from "api";
import { SnackBarContext } from "contexts/SnackBarContext";
import { fetchProfile } from "adapters/auth";
import useFormValidation from "hooks/useFormValidation";

interface IGetRoleAssignmentDetailsProps {
    id?: string;
    role?: IRole;
    context_object_id: string;
    group: string;
    model: string;
    user: IUser;
}

interface IGetUserDetailsProps {
    onClose: () => void;
    user: IUser;
    org_id?: string | null;
}

const RenderUserRoleAssignmentDetails: React.FC<IGetRoleAssignmentDetailsProps> = ({ context_object_id, model, role, user, group }) => {
    const { localize } = React.useContext(LocaleContext);
    const { hasPermission } = useHasPermission();
    const [selectedUserForDeletion, setUserForDeletion] = React.useState<IUser | null>(null);
    const { openSnack } = React.useContext(SnackBarContext);
    const queryClient = useQueryClient();

    const getOrganizationDetailQuery = useQuery({
        queryKey: ["organizations", context_object_id],
        queryFn: async () => {
            return await webAPIRequest<IOrganization>("GET", `/organizations/${context_object_id}/`);
        },
        enabled: model === "organization"
    });

    const getLocalOfficeDetailQuery = useQuery({
        queryKey: ["local-offices", context_object_id],
        queryFn: async () => {
            return await webAPIRequest<ILocalOffice>("GET", `/organizations/local-offices/${context_object_id}/`);
        },
        enabled: model === "localoffice"
    });

    const getNameOfDepartment = () => {
        if (model === "organization") {
            return getOrganizationDetailQuery.data?.data.name;
        }
        return getLocalOfficeDetailQuery.data?.data.name;
    };

    const profileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: fetchProfile
    });

    const handleClose = (): void => {
        setUserForDeletion(null);
        queryClient.invalidateQueries({ queryKey: ["role-assignments"] });
    };

    const userRemoveMutation = useMutation({
        mutationFn: async () => {
            if (selectedUserForDeletion) {
                await removeUserFromGroup(group, selectedUserForDeletion.id)
            }
        },
        onSuccess: handleClose,
        onError: (error: IErrorWithDetail) => {
            openSnack(error.response.data.detail, "error");
        }
    });


    const totalOrgAdminCount = getOrganizationDetailQuery.data ? getOrganizationDetailQuery.data?.data.admin_count : 0;

    const canNotDeleteAccount = (totalOrgAdminCount <= 1 && profileQuery.data?.data.is_admin && user.id === profileQuery.data.data.id) ||
        (hasPermission("group", "change_group") && !hasPermission("role", `can_invite_${role?.name}`));


    return (
        <>
            <Grid container alignItems={"center"} justifyItems={"center"} justifyContent={"space-between"}>
                <Typography variant="subtitle1" fontWeight={800} >
                    {localize("user-management.role")}:
                </Typography>

                {profileQuery.isLoading && hasPermission("user", "delete_user") ? (
                    <Skeleton width={"100%"} />

                ) : (<Tooltip title={localize("user-management.user-remove")} placement="right">
                    <span>
                        <IconButton onClick={() => setUserForDeletion(user)}
                            disabled={canNotDeleteAccount || user.id === profileQuery.data?.data.id} >
                            <Delete />
                        </IconButton>
                    </span>
                </Tooltip>)
                }
            </Grid>
            <Typography gutterBottom>
                {role?.display_name}
            </Typography>
            <Typography variant="subtitle1" fontWeight={800}>
                {localize("user-management.department-name")}:
            </Typography>
            <Typography gutterBottom>
                {!getNameOfDepartment() ? <Skeleton width={"100%"} /> :
                    getNameOfDepartment()}
            </Typography>
            <Typography variant="subtitle1" fontWeight={800}>
                {localize("user-management.department-type")}:
            </Typography>
            <Typography gutterBottom>
                {model === "localoffice" ? "Local office" : "Organization"}
            </Typography>
            <BaseDialog open={!!selectedUserForDeletion} onClose={handleClose}>
                {selectedUserForDeletion && (
                    <ConfirmRemoveUser
                        loading={userRemoveMutation.isPending}
                        onClose={handleClose}
                        onConfirm={() => userRemoveMutation.mutate()}
                    />
                )}
            </BaseDialog>
            <Divider />
        </>
    )
}

const GetUserDetails: React.FC<IGetUserDetailsProps> = ({ user, onClose, org_id }) => {
    const { localize } = React.useContext(LocaleContext);
    const { truncateString } = useFormValidation();

    const getUserRoleAssignments = useQuery(
        {
            queryKey: ["role-assignments", user.id],
            queryFn: () => fetchRoleAssignments({ params: { "user_id": user.id, "org_id": org_id } }),
            enabled: !!user.id
        }
    );


    return (
        <>
            <DialogTitle color="primary" >
                {`${localize("user-management.user-details-title")} / ${truncateString(user.email)} `}
            </DialogTitle>
            <DialogContent>

                {getUserRoleAssignments.data?.data.map(roleAssignment => {
                    return <RenderUserRoleAssignmentDetails user={user} key={roleAssignment.id} {...roleAssignment} />
                })}

            </DialogContent>
            <BaseDialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {localize("generics.close")}
                </Button>
            </BaseDialogActions>
        </>
    )

};

export default GetUserDetails;