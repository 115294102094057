import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { fetchClientPermissions, IClientPermissions, ModelType } from "adapters/auth";
import { OrganizationContext } from "contexts/OrganizationContext";
import React from "react";
import { useParams } from "react-router-dom";
import useClientConfig from "./useClientConfig";
import { AxiosResponse } from "axios";


type Domain = "organization" | "localoffice";

interface UseHasPermissionMethods {
    hasPermission: (model: ModelType, permName: string) => boolean
    clientPermission: UseQueryResult<AxiosResponse<IClientPermissions, any>, unknown>;
}

export const useHasPermission = (domain: Domain = "organization"): UseHasPermissionMethods => {
    const { currentOrganizationId } = React.useContext(OrganizationContext);

    const browserParams = useParams();
    const localofficeId = browserParams.local_office_id || undefined;
    const clientConfig = useClientConfig();

    const params = React.useMemo(() => {
        
        if (currentOrganizationId && !clientConfig.data?.data.dashboard) {
            if (domain === "localoffice") {
                return {
                    "local_office_id": localofficeId 
                };
            }
            return {
                "org_id": currentOrganizationId
            };
        }
        return {};
    }, [clientConfig.data?.data.dashboard, currentOrganizationId, domain, localofficeId]);

    const isEnabled = React.useMemo(() => {
        if (!clientConfig.data?.data.is_authenticated) {
            return false;
        }
        if (clientConfig.data?.data.dashboard) {
            return true;
        }
        if (domain === "localoffice" && !localofficeId) {
            return false
        }
        return !!currentOrganizationId;
    }, [clientConfig.data, localofficeId, domain, currentOrganizationId]);


    const clientPermission = useQuery({
        queryKey: ["clientPermissions", currentOrganizationId, domain],
        queryFn: () => fetchClientPermissions({ params: params }),
        enabled: isEnabled
    });

    const hasPermission = React.useCallback((model: ModelType, permName: string) => {
        if (clientPermission.data && Object.keys(clientPermission.data?.data.permissions).includes(model)) {
            if (clientPermission.data.data.permissions[model].some(p => p === permName)) {
                return true;
            }
        }
        return false
    }, [clientPermission.data]);

    return {
                hasPermission,
                clientPermission
            };
};

export default useHasPermission;