import { Button, CircularProgress, DialogContent, DialogTitle, Typography } from "@mui/material";
import { BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";


interface IConfirmRemoveUserProps {
    onClose: () => void;
    onConfirm: () => void;
    loading: boolean;
}

const ConfirmRemoveUser: React.FC<IConfirmRemoveUserProps> = ({onClose, onConfirm, loading}) => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <>
            <DialogTitle color="primary">
                {localize("user-management.confirm-delete-account-title")}
            </DialogTitle>
            <DialogContent 
                sx={{
                    xs: {
                        width: "250px"
                    },
                    sm: {
                        width: "370px"
                    }
                }}>
                <Typography gutterBottom>
                    {localize("user-management.confirm-delete-account-content")}
                </Typography>
                <Typography gutterBottom>
                    {localize("user-management.confirm-delete-account-content2")}
                </Typography>
            </DialogContent>
            <BaseDialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {localize("user-management.cancel")}
                </Button>
                <Button 
                    disabled={loading}
                    variant="contained"
                    onClick={onConfirm}
                    endIcon={loading && <CircularProgress size={"1rem"}/>}
                >
                    {localize("user-management.confirm")}
                </Button>
            </BaseDialogActions>
        </>
    )
};

export default ConfirmRemoveUser;