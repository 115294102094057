import webAPIRequest, { IPaginatedData } from "api";
import { AxiosRequestConfig } from "axios";
import { IInvoiceInput } from "interfaces/invoicing";


export const fetchInvoiceInputs = async (opts: AxiosRequestConfig) => 
    webAPIRequest<IPaginatedData<IInvoiceInput>>("GET", "/invoicing/invoice-inputs/", opts);


export const createInvoiceInput = async () => 
    webAPIRequest<IInvoiceInput>("POST", "/invoicing/invoice-inputs/");
