import { Box, IconButton, Menu, MenuItem, Stack, Toolbar, Typography } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import useAlarmSystemPath from "hooks/useAlarmSystemPath";
import React from "react";
import { Menu as MenuIcon} from '@mui/icons-material';
import { INavigationItem } from "./BaseNavigationItems";
import { EBLCloudLogoBlackStyled } from "./svg/EBLCloudLogoBlack";

const SmallScreenToolbar: React.FC<{onNavigate: (item: INavigationItem) => void}> = ({onNavigate}) => {
    const { localize } = React.useContext(LocaleContext);
    const menuRef = React.useRef(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const { navigationItems } = useAlarmSystemPath();

    const onClickMenuItem = (item: INavigationItem): void => {
        onNavigate(item);
        setMenuOpen(false);
    };

    return (
        <Toolbar>
            <Box sx={{
                        background: (theme) => theme.palette.background.default
                    }}>
                        <EBLCloudLogoBlackStyled
                            sx={{
                                marginBottom: "0.5em !important",
                                marginLeft: -2
                            }}
                            size={"small"}
                        />
                    </Box>
            <Box sx={{ flexGrow: 1, width: "100% !important" }} />
            <IconButton
                size="large"
                edge="end"
                color="inherit"
                ref={menuRef}
                sx={{ ml: 2 }}
                onClick={() => setMenuOpen(true)}
            >
                <MenuIcon color="primary" />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={menuRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}>
                {navigationItems.filter(item => !item.bottom).map((item) => (
                    <MenuItem key={item.id} onClick={() => onClickMenuItem(item)}>
                        <Stack direction="row" gap="12px">
                            {item.icon}
                            <Typography color="primary" textAlign="center">
                                {localize(item.text)}
                            </Typography>
                        </Stack>
                    </MenuItem>
                ))}
                </Menu>
        </Toolbar>
    )
};

export default SmallScreenToolbar;