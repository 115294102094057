import { Button, CircularProgress, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { deleteFireAlarmSystemContactPersons, patchFireAlarmSystemContactPersons } from "adapters/fireAlarmSystem";
import { LocaleContext } from "contexts/LocaleContext";
import ChangableField from "components/ChangableField";
import { EBLTextField } from "components/StyledComponents/TextField";
import { IFireAlarmSystemContactPerson } from "interfaces/fireAlarmSystem";
import { AxiosResponse } from "axios";
import { IPaginatedData } from "api";
import { Delete } from "@mui/icons-material";
import { SnackBarContext } from "contexts/SnackBarContext";
import { useHasPermission } from "hooks/useHasPermission";
import useFormValidation from "hooks/useFormValidation";

interface IDisplayContactPersonProps {
    contact: IFireAlarmSystemContactPerson;
}
type QueryData = AxiosResponse<IPaginatedData<IFireAlarmSystemContactPerson>> | undefined;

const DisplayContactPerson: React.FC<IDisplayContactPersonProps> = ({ contact }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const { validateEmail } = useFormValidation();
    const queryClient = useQueryClient();
    const [mutableContact, setMutableContact] = React.useState(contact);
    const { hasPermission } = useHasPermission("localoffice");

    const patchContactMutation = useMutation({
        mutationKey: ["patch-fire-alarm-contact"],
        mutationFn: async (data: Partial<IFireAlarmSystemContactPerson>) => {
            return await patchFireAlarmSystemContactPersons(contact.id, data);
        },
        onSuccess: (newContactRes) => {
            const updateContacts = (oldData: QueryData): QueryData => {
                if (oldData) {
                    const res = oldData.data.results.map(c => c.id === newContactRes.data.id
                        ? { ...c, ...newContactRes.data }
                        : c
                    )
                    return { ...oldData, data: { ...oldData.data, results: res } }
                }
                return oldData;
            };
            queryClient.setQueryData<AxiosResponse<IPaginatedData<IFireAlarmSystemContactPerson>>>(
                [`fire-alarm-contact-persons-${contact.fire_alarm_system}`],
                updateContacts
            );
        },
        onError: () => {
            openSnack(localize("error.generic"), "error");
        }
    });

    const deleteContactMutation = useMutation({
        mutationKey: ["delete-fire-alarm-contact"],
        mutationFn: deleteFireAlarmSystemContactPersons,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [`fire-alarm-contact-persons-${contact.fire_alarm_system}`] })
        },
        onError: () => {
            openSnack(localize("error.generic"), "error");
        }
    });

    const emailIsInvalid = !!mutableContact.email.length && !validateEmail(mutableContact.email);
    const roleIsValid = mutableContact.role !== undefined && mutableContact.role.length < 50;
    const nameIsValid = mutableContact.name !== undefined && mutableContact.name.length < 100;
    const emailLengthIsValid = mutableContact.email !== undefined && mutableContact.email.length < 100;
    const phoneIsValid = mutableContact.phone !== undefined && mutableContact.phone.length < 15;


    const isLoading = patchContactMutation.isPending;
    return (
        <Box position="relative">
            {hasPermission("firealarmcontactperson", "delete_firealarmcontactperson") &&
                <IconButton
                    sx={{ position: "absolute", right: 0 }}
                    onClick={() => deleteContactMutation.mutate(contact.id)}>
                    {deleteContactMutation.isPending ? <CircularProgress color="error" size="1rem" /> : <Delete />}
                </IconButton>
            }
            <ChangableField
                label={localize("generics.role")}
                data={contact.role}
                isLoading={isLoading}
                onSubmit={() => patchContactMutation.mutate({ role: mutableContact.role })}
                popoverFormData={
                    <EBLTextField
                        variant="outlined"
                        label={localize("generics.role")}
                        id="name"
                        value={mutableContact.role}
                        onChange={e => setMutableContact({ ...mutableContact, role: e.target.value })}
                        inputProps={{ maxLength: 50 }}
                        helperText={!roleIsValid && localize("text-validation.50")}
                    />
                }
                popoverAction={
                    <Button
                        variant="contained"
                        type="submit"
                    >
                        {localize("configuration.update-role")}
                    </Button>
                }
                hasPermission={hasPermission("firealarmcontactperson", "change_firealarmcontactperson")}
            />
            <ChangableField
                label={localize("configuration.name-label")}
                data={contact.name}
                isLoading={isLoading}
                onSubmit={() => patchContactMutation.mutate({ name: mutableContact.name })}
                popoverFormData={
                    <EBLTextField
                        variant="outlined"
                        label={localize("configuration.name-label")}
                        id="name"
                        value={mutableContact.name}
                        onChange={e => setMutableContact({ ...mutableContact, name: e.target.value })}
                        inputProps={{ maxLength: 100 }}
                        helperText={!nameIsValid && localize("text-validation.100")}
                    />
                }
                popoverAction={
                    <Button
                        variant="contained"
                        type="submit"
                    >
                        {localize("account.update-name")}
                    </Button>
                }
                hasPermission={hasPermission("firealarmcontactperson", "change_firealarmcontactperson")}
            />
            <ChangableField
                label={localize("generics.email")}
                data={contact.email}
                isLoading={isLoading}
                onSubmit={() => patchContactMutation.mutate({ email: mutableContact.email })}
                popoverFormData={
                    <EBLTextField
                        variant="outlined"
                        label={localize("generics.email")}
                        id="name"
                        value={mutableContact.email}
                        onChange={e => setMutableContact({ ...mutableContact, email: e.target.value })}
                        error={emailIsInvalid}
                        inputProps={{ maxLength: 100 }}
                        helperText={!emailLengthIsValid && localize("text-validation.100")}
                    />
                }
                popoverAction={
                    <Button
                        variant="contained"
                        type="submit"
                    >
                        {localize("account.update-email")}
                    </Button>
                }
                hasPermission={hasPermission("firealarmcontactperson", "change_firealarmcontactperson")}
            />
            <ChangableField
                label={localize("generics.phone")}
                data={contact.phone}
                isLoading={isLoading}
                onSubmit={() => patchContactMutation.mutate({ phone: mutableContact.phone })}
                popoverFormData={
                    <EBLTextField
                        variant="outlined"
                        label={localize("generics.phone")}
                        id="name"
                        value={mutableContact.phone}
                        onChange={e => setMutableContact({ ...mutableContact, phone: e.target.value })}
                        inputProps={{ maxLength: 15 }}
                        helperText={!phoneIsValid && localize("text-validation.15")}
                    />
                }
                popoverAction={
                    <Button
                        variant="contained"
                        type="submit"
                    >
                        {localize("account.update-phone")}
                    </Button>
                }
                hasPermission={hasPermission("firealarmcontactperson", "change_firealarmcontactperson")}
            />
        </Box>
    )
};

export default DisplayContactPerson;