import { AppBar, BottomNavigation, Box, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import useAlarmSystemPath from "hooks/useAlarmSystemPath";
import React, { Key } from "react";
import {
    createSearchParams,
    Outlet,
    useLocation,
    useNavigate
} from "react-router-dom";
import {
    NavigateBefore,
    NavigateNext
} from '@mui/icons-material';
import {
    BottomNavigationWrapper,
    MobileNavigationAction,
    NavigationContentContainer,
    NavigationDrawer,
    NavigationIconButton,
} from "./StyledComponents/Navigation";
import DrawerItems from "./DrawerItems";
import { INavigationItem } from "./BaseNavigationItems";
import GlobalDialogs from "./GlobalDialogs";
import SmallScreenToolbar from "./SmallScreenToolbar";
import useNotificationsServiceWorker from "hooks/useNotificationSW";
import { useQuery } from "@tanstack/react-query";
import { StyledEBLCloudLogoBlack, StyledOrganizationGardient } from "./StyledComponents/BaseLayoutLogs";
import { IOrganization } from "interfaces/organization";
import webAPIRequest from "api";
import { OrganizationContext } from "contexts/OrganizationContext";
import useClientConfig from "hooks/useClientConfig";

const BaseLayout: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { currentOrganizationId } = React.useContext(OrganizationContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [drawerOpen, setDrawerState] = React.useState(matches);
    const { pathname } = useLocation();
    const { getRoute, missingAlarmSystem, nullAlarmSystem, navigationItems } = useAlarmSystemPath();
    const { registerWorker } = useNotificationsServiceWorker();
    const EBLCloudLogoBlack = require("./img/EBLCloudblack.png") as string;


    const onNavigate = (item: INavigationItem): void => {
        if (item.externalLink) {
            window.open(item.externalLink, '_blank');
            return
        }

        nullAlarmSystem(item);
        navigate({ pathname: getRoute(item), search: createSearchParams(item.query).toString() });
    };

    React.useEffect(() => {
        registerWorker();
    }, [registerWorker]);

    const getOrganizationDetailQuery = useQuery({
        queryKey: ["organization", currentOrganizationId],
        queryFn: async () => {
            return await webAPIRequest<IOrganization>("GET", `/organizations/${currentOrganizationId}/`);
        },
        enabled: !!currentOrganizationId
    });

    const clientConfig = useClientConfig();

    const RenderMobileNavigationItems = (item: INavigationItem, index: Key): React.ReactNode => {
        return (
            <MobileNavigationAction
                disabled={missingAlarmSystem(item)}
                key={index}
                label={localize(item.text)}
                icon={item.icon}
                value={getRoute(item)}
                onClick={() => onNavigate(item)} />
        )
    }

    return (
        <>
            <AppBar
                elevation={0}
                position="sticky"
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    backgroundColor: (theme) => theme.palette.background.default,
                    display: { xs: "inherit", sm: "none" }
                }}>
                <SmallScreenToolbar onNavigate={onNavigate} />
            </AppBar>
            <Box>
                <NavigationDrawer
                    variant={matches ? "permanent" : "temporary"}
                    anchor={matches ? "left" : "right"}
                    open={drawerOpen}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <StyledEBLCloudLogoBlack sx={{
                        padding: drawerOpen ? "2rem" : "32px 4px",
                        minHeight: drawerOpen ? "120px" : "80px"
                    }}>
                        <img src={EBLCloudLogoBlack} alt="" width={'90%'} />
                    </StyledEBLCloudLogoBlack>
                    {getOrganizationDetailQuery.isFetching ? (
                        <StyledOrganizationGardient>
                            <Skeleton variant="text" />
                        </StyledOrganizationGardient>
                    ) : (
                        <StyledOrganizationGardient>
                            {drawerOpen && (clientConfig.data?.data.dashboard === true ? "Panasonic Management" : getOrganizationDetailQuery.data?.data.name)}
                        </StyledOrganizationGardient>
                    )}
                    <DrawerItems
                        isLoading={currentOrganizationId ? getOrganizationDetailQuery.isLoading : clientConfig.isLoading}
                        expanded={drawerOpen}
                        navigationItems={navigationItems}
                        onNavigate={onNavigate}
                    />
                </NavigationDrawer>
                <NavigationIconButton
                    open={drawerOpen}
                    size="small"
                    edge="end"
                    color="inherit"
                    sx={{ display: matches ? "flex" : "none" }}
                    onClick={() => setDrawerState(!drawerOpen)}
                >
                    {drawerOpen ? (<NavigateBefore />) : (<NavigateNext />)}
                </NavigationIconButton>
            </Box>
            <NavigationContentContainer open={matches && drawerOpen}>
                <Outlet />
                <GlobalDialogs />
            </NavigationContentContainer>
            <BottomNavigationWrapper
                sx={{ display: { xs: "inherit", sm: "none" } }}
                square
                elevation={3}>
                <BottomNavigation
                    showLabels
                    value={pathname}
                >
                    {navigationItems.filter(i => i.bottom).map((item, index) => (
                        item.subMenus ?
                            item.subMenus.map((subitem, index) => (
                                RenderMobileNavigationItems(subitem, index)
                            ))
                            :
                            RenderMobileNavigationItems(item, index)
                    ))}
                </BottomNavigation>
            </BottomNavigationWrapper>
        </>
    );
};

export default BaseLayout;