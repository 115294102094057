import { useQueryClient } from "@tanstack/react-query";
import { SnackBarContext } from "contexts/SnackBarContext";
import { IServiceWorkerMessage } from "interfaces/notifications";
import React from "react";
import { useNavigate } from "react-router-dom";

const urlB64ToUint8Array = (base64String: string): Uint8Array => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

interface IUseNotificationServiceWorker {
    navigatorPermissionState: PermissionState | null;
    registerWorker: () => Promise<ServiceWorkerRegistration | undefined>;
    subscribeServiceWorker: (serviceWorkerReg: ServiceWorkerRegistration) => Promise<PushSubscription>;
}

const useNotificationsServiceWorker = (): IUseNotificationServiceWorker =>  {
    const { openSnack } = React.useContext(SnackBarContext);
    const [navigatorPermissionState, setNavigatorPermissionState] = React.useState<PermissionState | null>(null);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    React.useEffect(() => {
        const setNotficationState = async () => {      
            const perms = await navigator.permissions.query({
                name: "notifications"
            });
            perms.onchange = () => setNavigatorPermissionState(perms.state) 
            setNavigatorPermissionState(perms.state);
        };
        setNotficationState();
    }, []);

    const handleNewMessage = (ev: MessageEvent<IServiceWorkerMessage>): void => {
        openSnack(ev.data.body, ev.data.type === "activate" ? "warning" : "success", ev.data.title, () => navigate(`/${ev.data.local_office}/status/${ev.data.fire_alarm_system}`));
        queryClient.invalidateQueries(
            {queryKey: ["fireAlarmSystems"], refetchType: 'all'},
            {cancelRefetch: false}
        );
        queryClient.invalidateQueries(
            { queryKey: ["fire-alarm-system"], refetchType: 'all'},
            { cancelRefetch: false }
        );
        queryClient.invalidateQueries(
            {queryKey: ["activeDeviations"], refetchType: 'all'},
            {cancelRefetch: false}
        );
    };

    const registerWorker = async (): Promise<ServiceWorkerRegistration | undefined> => {
        if ('serviceWorker' in navigator) {
            let url = '/service-worker.js';
            const worker =  navigator.serviceWorker.register(url);
            navigator.serviceWorker.addEventListener("message", handleNewMessage);
            return worker;
        }
        return undefined
    };

    const subscribeServiceWorker = async (serviceWorkerReg: ServiceWorkerRegistration) => {
        const vapidPublicKey = process.env.REACT_APP_VAPID_PUB_KEY;
        if (!vapidPublicKey) {
            throw new Error("Invalid configuration");
        }
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(
            vapidPublicKey,
          ),
        };
        return await serviceWorkerReg.pushManager.subscribe(subscribeOptions)
    };

    return {
        navigatorPermissionState,
        registerWorker,
        subscribeServiceWorker
    }
};

export default useNotificationsServiceWorker;