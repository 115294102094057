import { Box, Button, CircularProgress, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";
import { ScrollableStack } from "./StyledComponents/ScrollableStack";
import { LocaleContext } from "contexts/LocaleContext";


interface InfiniteScrollListProps {
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;
    hasNextPage: boolean;
    isLoading: boolean;
    children: React.ReactNode; 
}


const InfiniteScrollList: React.FC<InfiniteScrollListProps> = 
({ fetchNextPage, isFetchingNextPage, hasNextPage, isLoading, children }) => {
    
    const { localize } = React.useContext(LocaleContext);
    const { ref, inView } = useInView();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        if (inView) {
            fetchNextPage()
        }
    }, [fetchNextPage, inView]);
    

    return (
        <ScrollableStack gap="1em" pb="2em">
            {isLoading ? (
                <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </>
            ) : (
                <>
                    {children}
                    <Box mt="1em" display="flex" ref={isLargeScreen ? ref : null} height={"100px"} width={"100%"} justifyContent="center" alignItems="center">
                        {isFetchingNextPage ? (
                            <CircularProgress disableShrink/>
                        ) : (
                            <Box>
                                <Button
                                    sx={{display: {
                                        xs: 'inherit',
                                        md: 'none',
                                    }}}
                                    variant="outlined"
                                    disabled={!hasNextPage}
                                    onClick={() => fetchNextPage()}
                                >
                                    {localize("generics.load-more")}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </>
                )}
        </ScrollableStack>
    )
};

export default InfiniteScrollList;