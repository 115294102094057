import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText, Paper, Skeleton, styled, Typography } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import useAlarmSystemPath from "hooks/useAlarmSystemPath";
import React, { Fragment, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { NavigationListButton } from "./StyledComponents/Navigation";
import { INavigationItem } from "./BaseNavigationItems";
import { alpha } from "@mui/system";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import { PanasonicLogoStyled } from "./svg/PanasonicLogo";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface DrawerItemsProps {
    navigationItems: INavigationItem[];
    expanded: boolean;
    onNavigate: (item: INavigationItem) => void;
    isLoading: boolean;
}

const DrawerList = styled(List)
    (() => ({
        display: "flex",
        flexDirection: "column",
        gap: "16px"
    })
    );

const DrawerItems: React.FC<DrawerItemsProps> = ({ navigationItems, expanded, onNavigate, isLoading }) => {
    const { localize } = React.useContext(LocaleContext);
    const { pathname } = useLocation();
    const { getRoute, missingAlarmSystem } = useAlarmSystemPath();


    const { alarmSystemId, alarmSystem } = useGetAlarmSystem();
    const [openItems, setOpenItems] = React.useState<{ [key: string]: boolean }>({});

    const handleClick = (menu_id: string) => {
        setOpenItems((prevState) => ({
            ...prevState,
            [menu_id]: !prevState[menu_id],
        }));
    };

    useMemo(() => {
        if (pathname) {
            if (pathname.split("/")[1] !== sessionStorage.getItem("local_office_id")) {
                setOpenItems({});
            }
        }
    }, [pathname]);

    const renderItem = (item: INavigationItem, onClick: () => void): React.ReactNode => {
        return (
            <ListItem
                key={item.id}
                disablePadding
                disableGutters
                sx={{ marginTop: item.pushEnd ? "auto" : 0 }}
            >
                <NavigationListButton
                    disabled={missingAlarmSystem(item)}
                    selected={!!item.route && pathname === getRoute(item)}
                    onClick={onClick}
                >
                    <ListItemIcon
                        sx={{
                            justifyContent: "center",
                            mr: expanded ? "12px" : "auto",
                            ml: "auto",
                            minWidth: 0
                        }}
                        color="primary"
                    >
                        {item.icon}
                    </ListItemIcon>
                    {expanded && (
                        <Fragment key={`item_${item.id}`}>
                            <ListItemText
                                primaryTypographyProps={{
                                    color: 'primary',
                                    fontWeight: 'bold',
                                    variant: 'body1',
                                }}
                                primary={localize(item.text)}
                            />

                            {item.subMenus ? (openItems[item.id] ? <ExpandLess /> : <ExpandMore />) : ""}
                        </Fragment>
                    )}
                </NavigationListButton>
            </ListItem>
        )
    };

    const renderLoadingItem = (id: string, pushEnd: boolean = false): React.ReactNode => {
        return (
            <ListItem
                key={id}
                disablePadding
                disableGutters
                sx={{ mt: pushEnd ? "auto" : 0 }}
            >
                <Box ml="8px" mr="8px" width="100%" display="flex" alignItems="baseline">
                    {expanded && (
                        <ListItemText
                            primaryTypographyProps={{
                                color: 'primary',
                                fontWeight: 'bold',
                                variant: 'body1',
                            }}
                            primary={<Skeleton variant="rectangular" height="40px" />}
                        />
                    )}
                </Box>
            </ListItem>
        )
    };

    const renderSubMenuItems = (item: INavigationItem): React.ReactNode => {
        return (
            <Collapse key={`collapse_${item.id}`} in={item.mainMenu ? openItems[item.mainMenu] ?? false : false} timeout="auto" unmountOnExit >
                <Box
                    sx={{ pl: 3 }}
                >
                    {renderItem(item, () => onNavigate(item))}
                </Box>
            </Collapse>
        )
    }

    return (
        <>
            <DrawerList sx={{ height: "100%" }}>
                {isLoading ? [false, false, false, false, false, true, false].map((item, id) => (
                    renderLoadingItem(id.toString(), item)
                ))
                    :

                    navigationItems.map((item) => (
                        item.subMenus ?
                            (
                                <Paper
                                    key={item.id}
                                    elevation={0}
                                    sx={{
                                        background: (theme) => alpha(theme.palette.primary.main, 0.2),
                                        m: "0 8px",
                                        p: expanded ? "0 1em" : "0 8px"
                                    }}>
                                    <Box height="1.5em" overflow="hidden" textOverflow="ellipsis">
                                        <Typography color="primary" fontWeight="bold" variant="overline" noWrap>
                                            {alarmSystem?.name}
                                        </Typography>
                                    </Box>
                                    <DrawerList
                                        sx={{
                                            borderLeft: (theme) => `1px solid ${alpha(theme.palette.primary.main, alarmSystemId ? 1 : 0.2)}`,
                                            margin: "1em 0",
                                            padding: 0,
                                            ".MuiButtonBase-root": {
                                                padding: expanded ? "auto" : "8px 0",
                                            }
                                        }}>
                                        {item.subMenus.map((subitem) => (
                                            <Fragment key={`sub_${subitem.id}`}>
                                                {renderItem(subitem, () => handleClick(subitem.id))}

                                                {subitem.subMenus?.map(renderSubMenuItems)}
                                            </Fragment>
                                        ))}
                                    </DrawerList>
                                </Paper >
                            )
                            : renderItem(item, () => onNavigate(item))
                    ))}
                <ListItem
                    key={"last"}
                    disablePadding
                    disableGutters
                    sx={{
                        padding: expanded ? "0" : "32px 4px",
                        paddingLeft: expanded ? "70px" : 0,
                    }}
                >
                    <a href="https://www.panasonic-fire-security.com/" target="_blank"
                        rel="noopener noreferrer">
                        <PanasonicLogoStyled
                            sx={{
                                marginBottom: "1em !important",
                                alignItems: "center",

                            }}
                            size={expanded ? "small" : "inherit"}
                        />
                    </a>
                </ListItem>
            </DrawerList >
        </>
    );
};

export default DrawerItems;