import { Button, DialogContent, DialogTitle, Typography } from "@mui/material";
import { BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import useNotificationsServiceWorker from "hooks/useNotificationSW";
import { createWebPushNotificationPreference } from "adapters/notifications";


interface IAcceptNotificationsDialogProps {
    onClose: () => void;
}

const AcceptNotificationsDialog: React.FC<IAcceptNotificationsDialogProps> = ({ onClose }) => {
    const { localize } = React.useContext(LocaleContext);
    const {
        registerWorker,
        subscribeServiceWorker
    } = useNotificationsServiceWorker();

    const askPermission = async (): Promise<void> => {
        const swRegistration = await registerWorker();
        if (swRegistration) {
            const pushSubscription = await subscribeServiceWorker(swRegistration);
            createWebPushNotificationPreference({ config: pushSubscription.toJSON() });
        }
        onClose();
    };

    return (
        <>
            <DialogTitle color="primary">
                {localize("notifications.accept-notifications-title")}
            </DialogTitle>
            <DialogContent
                sx={{
                    xs: {
                        width: "250px"
                    },
                    sm: {
                        width: "370px"
                    }
                }}>
                <Typography gutterBottom>
                    {localize("notifications.accept-notifications-description")}
                </Typography>
            </DialogContent>
            <BaseDialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {localize("generics.cancel")}
                </Button>
                <Button variant="contained" onClick={askPermission}>
                    {localize("generics.confirm")}
                </Button>
            </BaseDialogActions>
        </>
    )

};

export default AcceptNotificationsDialog;