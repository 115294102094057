import local from "json/config-local.json";
import production from "json/config-production.json";

export interface IConfig {
    apiURL: string;
}

let config: IConfig = local;
switch (process.env.REACT_APP_CONFIG) {
    case "production":
        config = production;
        break;
    case "local":
    default:
        config = local;
        break;
}
export default config;
