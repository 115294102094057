import webAPIRequest, { IPaginatedData } from "api";
import { AxiosRequestConfig } from "axios";
import { ICreateInvite, IInvite, ILocalOffice, IRole, IRoleAssignment, IUser } from "interfaces/userManagement";


export const fetchUsers = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<IUser>>("GET", "/users/", opts);

export const removeUserFromGroup = async (groupId: string, userId: string) =>
    webAPIRequest("PATCH", `/drm/groups/${groupId}/remove-user/${userId}/`, { data: {} });

export const fetchInvites = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<IInvite>>("GET", "/drm/invitations/", opts);

export const createInvite = async (data: ICreateInvite) =>
    webAPIRequest<IPaginatedData<IInvite>>("POST", "/drm/invitations/", { data });

export const deleteInvite = async (id: string) =>
    webAPIRequest("DELETE", `/drm/invitations/${id}/`);

export const fetchRoles = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<IRole>>("GET", "/drm/roles/", opts);

export const fetchLocalOffice = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<ILocalOffice>>("GET", "/organizations/local-offices/", opts);

export const createLocalOffice = async (data: Omit<ILocalOffice, 'id'>) =>
    webAPIRequest<ILocalOffice>("POST", "/organizations/local-offices/", { data: data });

export const deleteLocalOffice = async (id: string) =>
    webAPIRequest("DELETE", `/organizations/local-offices/${id}/`);

export const fetchRoleAssignments = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IRoleAssignment[]>("GET", "/drm/role-assignments/", opts);
