import { Badge } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchActiveDeviationCount } from "adapters/fireAlarmSystem";
import { BaseNavigationItems, INavigationItem } from "components/BaseNavigationItems";
import React from "react";
import { useLocation } from "react-router-dom";
import { useHasPermission } from "./useHasPermission";
import { OrganizationContext } from "contexts/OrganizationContext";
import useGetAlarmSystem from "./useGetAlarmSystem";

interface UseAlarmSystemPathMethods {
    getRoute: (item: INavigationItem) => string;
    missingAlarmSystem: (item: INavigationItem) => boolean;
    nullAlarmSystem: (item: INavigationItem) => void;
    navigationItems: INavigationItem[];
}

const useAlarmSystemPath = (): UseAlarmSystemPathMethods => {
    const { pathname } = useLocation();
    const { currentOrganizationId } = React.useContext(OrganizationContext);

    const { alarmSystem } = useGetAlarmSystem();

    if (alarmSystem) {
        sessionStorage.setItem("alarm_system_id", alarmSystem.id);
        sessionStorage.setItem("local_office_id", alarmSystem.local_office);
    }
    else {
        sessionStorage.removeItem("alarm_system_id");
        sessionStorage.removeItem("local_office_id");
    }

    const { hasPermission } = useHasPermission();
    const canViewActiveDeviations = hasPermission("activedeviation", "view_activedeviation");

    const activeDeviationsCountQuery = useQuery({
        queryKey: ["activeDeviations", currentOrganizationId],
        queryFn: () => fetchActiveDeviationCount({ params: { "org_id": currentOrganizationId } }),
        enabled: canViewActiveDeviations && !!currentOrganizationId,
        staleTime: 0
    });

    const navigationItems = React.useMemo(() => {
        return BaseNavigationItems
            .filter(navItem => hasPermission(...navItem.permissionParams))
            .map((item) => {
                if (item.id === "overview") {
                    const totalDeviations = activeDeviationsCountQuery.data
                        ? Object.values(activeDeviationsCountQuery.data.data).reduce((acc, current) => {
                            if (typeof current === "boolean") {
                                return acc
                            }
                            return acc + current
                        })
                        : undefined;
                    return {
                        ...item,
                        icon: (
                            <Badge color={activeDeviationsCountQuery.data?.data.has_active_fire ? "error" : "warning"} badgeContent={totalDeviations ? totalDeviations : 0}>
                                {item.icon}
                            </Badge>
                        )
                    };

                }
                return item;
            });
    }, [activeDeviationsCountQuery.data, hasPermission])

    const nullAlarmSystem = (item: INavigationItem): void => {
        if (item.nullsAlarmSystem) {
            sessionStorage.removeItem("alarm_system_id");
            sessionStorage.removeItem("local_office_id");

        }
    };

    const getRoute = (item: INavigationItem): string => {
        let route = item.route || pathname;

        if (!item.useAlarmSystem) {
            return route
        }
        const alarmSystemId = sessionStorage.getItem("alarm_system_id");
        const localOfficeId = sessionStorage.getItem("local_office_id");
        return localOfficeId + route + alarmSystemId
    };

    const missingAlarmSystem = (item: INavigationItem): boolean => {
        const alarmSystemId = sessionStorage.getItem("alarm_system_id");
        return !!(item.useAlarmSystem && alarmSystemId === null);
    };

    return {
        getRoute,
        missingAlarmSystem,
        nullAlarmSystem,
        navigationItems
    }
};

export default useAlarmSystemPath;