import { Button, DialogContent, DialogTitle, FormControl, Radio, RadioGroup, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { BaseDialog } from 'components/StyledComponents/Dialog';
import { FormButtonWrapper } from 'components/StyledComponents/Form';
import { StyledFormControlLabel } from 'components/StyledComponents/FormControlLabel';
import { EBLTextField } from 'components/StyledComponents/TextField';
import { LocaleContext } from 'contexts/LocaleContext';
import React from 'react';
import { IDownloadJournal, IJournal } from "interfaces/fireAlarmSystem";
import { useQuery } from '@tanstack/react-query';
import { downloadJournal } from 'adapters/fireAlarmSystem';
import { SnackBarContext } from "contexts/SnackBarContext";


interface PopupProps {
    onClose: () => void;
    open: boolean;
    alarmSystemId: string | undefined;
    totalCount: number;
    order: string,
    orderBy: string
}


const DownloadJournalLog: React.FC<PopupProps> = ({ open, onClose, alarmSystemId, totalCount, order, orderBy }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const [value, setValue] = React.useState('All');

    const [downloadFields, setDownloadFields] = React.useState<IDownloadJournal>({
        timestamp_from: null,
        timestamp_to: null
    });

    const transformData = (data: IJournal[]) => {
        return data.map((data) => ({
            created: data.created,
            entry_text: `${data.entry_text} ${data.command_status} ${data.command_reason}`,
            written_by_email: data.written_by_email
        }));
    };

    const downloadAsCsv = (data: IJournal[], filename: string) => {
        const headerRow = "Created;Text;User\n";

        const csvContent = "data:text/csv;charset=utf-8," + headerRow +
            data.map((data) =>
                `${data.created};${data.entry_text};${data.written_by_email}`
            ).join("\n");

        const encodedUri = encodeURI(csvContent);
        let a = document.createElement("a");
        a.setAttribute('href', encodedUri);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const filterQuery = useQuery({
        queryKey: [`journal-${alarmSystemId}-download`, downloadFields, order, orderBy],
        queryFn: async () => {
            const params = {
                timestamp_from: downloadFields.timestamp_from,
                timestamp_to: downloadFields.timestamp_to,
                alarm_system_id: alarmSystemId,
                ordering: order === "asc" ? orderBy : `-${orderBy}`,
            };
            const queryResult = await downloadJournal({ params: params })
            if (queryResult.data) {
                const transformedData = transformData(queryResult.data)
                downloadAsCsv(transformedData, `Journal-log-${alarmSystemId}.csv`)
                openSnack(localize("journal.download-complete"), "success")
                onClose()
            }
            else {
                openSnack(localize("error.generic"), "error");
            }
        },
        enabled: false
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "All") {
            setDownloadFields({
                ...downloadFields,
                timestamp_from: null,
                timestamp_to: null
            })
        }
        if (event.target.value === "Date-range") {
            setDownloadFields({
                ...downloadFields
            })
        }
        setValue((event.target.value));

    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        filterQuery.refetch();
    }


    return (
        <BaseDialog open={open} onClose={onClose}>
            <DialogTitle color="primary">
                {localize("journal.download")}
            </DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-labelledby="Download All journal entries"
                        name="Download All journal entries"
                        value={value}
                        onChange={handleChange}
                    >
                        <StyledFormControlLabel
                            value="All" control={<Radio />} label={localize("journal.all-label")} labelPlacement='start' />

                        <StyledFormControlLabel value="Date-range" control={<Radio />} label={localize("journal.date-range-label")} labelPlacement='start' />

                        <Stack direction="row" gap={2} justifyContent="space-between">
                            <DatePicker
                                disabled={value !== "Date-range"}
                                label={localize("journal.from")}
                                value={downloadFields.timestamp_from}
                                onChange={(newDate) => setDownloadFields({
                                    ...downloadFields,
                                    timestamp_from: newDate
                                })}
                                slots={{
                                    textField: EBLTextField,
                                }}
                                slotProps={{
                                    textField: (params) => ({
                                        ...params,
                                        inputProps: {
                                            ...params.inputProps,
                                            placeholder: "yyyy-mm-dd"
                                        },
                                        sx: {
                                            ".MuiOutlinedInput-notchedOutline": {
                                                top: 0,
                                                "legend": {
                                                    display: "none"
                                                }
                                            }
                                        },
                                        fullWidth: true,
                                        required: false,
                                        id: "from-date"
                                    })
                                }}
                            />
                            <DatePicker
                                label={localize("journal.to")}
                                disabled={value !== "Date-range"}
                                value={downloadFields.timestamp_to}
                                onChange={(newDate) => setDownloadFields({
                                    ...downloadFields,
                                    timestamp_to: newDate
                                })}
                                slots={{
                                    textField: EBLTextField,
                                }}
                                slotProps={{
                                    textField: (params) => ({
                                        ...params,
                                        inputProps: {
                                            ...params.inputProps,
                                            placeholder: "yyyy-mm-dd"
                                        },
                                        sx: {
                                            ".MuiOutlinedInput-notchedOutline": {
                                                top: 0,
                                                "legend": {
                                                    display: "none"
                                                }
                                            }
                                        },
                                        fullWidth: true,
                                        required: false,
                                        id: "to-date"
                                    })
                                }}
                            />
                        </Stack>
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <FormButtonWrapper sx={{ justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    onClick={handleSubmit}>
                    {localize("journal.download")}
                </Button>
            </FormButtonWrapper>
        </BaseDialog>
    );
}

export default DownloadJournalLog;
