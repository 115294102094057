import {
    useMediaQuery,
    useTheme
} from "@mui/material";
import { StatusAccordion } from "components/StatusAccordion";
import { InterlockingIcon } from "components/svg/DeviationIcons";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import { IDeviationStatusHeader, DeviationStatusTable, DeviationStatusStack } from "components/status/DeviationInfo";
import { IStatusAccordionProps } from "interfaces/statusAccordionProps";

const interlockingHeaders: IDeviationStatusHeader[] = [
    {
        locale: "status.time-title"
    },
    {
        locale: "status.area-point-title",
        alignment: "right"
    },
    {
        locale: "status.input-title",
        alignment: "right"
    },
    {
        locale: "status.output-title",
    },
    {
        locale: "status.text-title",
    },
];


const InterlockingStatusAccordion: React.FC<IStatusAccordionProps> = ({alarmSystem, formatDate}) => {
    const { localize } = React.useContext(LocaleContext);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const rows = alarmSystem?.deviations.interlocking.map(dev => [
        formatDate(dev.payload.timestamp),
        dev.payload.data.parameters.areapoint_desc || "-",
        dev.payload.data.parameters.inputactive ? "ON" : "OFF",
        dev.payload.data.parameters.outputactive ? "ON" : "OFF",
        dev.payload.text || "-",
    ]);

    return (
        <StatusAccordion
            icon={<InterlockingIcon />}
            numberOfDeviations={alarmSystem?.deviations.interlocking.length}
            loading={!!!alarmSystem}
            title={localize("status.interlocking")}
        >
            {rows && (isLargeScreen ? (
                <DeviationStatusTable
                    headers={interlockingHeaders}
                    rows={rows}
                />
            ) : rows.map((row, rowIndex) => (
                <DeviationStatusStack headers={interlockingHeaders} key={rowIndex} data={row} />
            )))}
        </StatusAccordion>
    );
};

export default InterlockingStatusAccordion;