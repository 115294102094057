import { Delete, ManageAccounts } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemText, Skeleton, Typography } from "@mui/material";
import { keepPreviousData, useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchUsers, removeUserFromGroup } from "adapters/userManagement";
import { IErrorWithDetail } from "api";
import { BaseDialog } from "components/StyledComponents/Dialog";
import { SnackBarContext } from "contexts/SnackBarContext";
import { useHasPermission } from "hooks/useHasPermission";
import { IRoleAssignment, IUser } from "interfaces/userManagement";
import React from "react";
import { useInView } from "react-intersection-observer";
import { isLastInArray } from "utils/arrays";
import ChangeRole from "./ChangeRole";
import ConfirmRemoveUser from "./ConfirmRemoveUser";

interface IListUsersByRoleAssignmentProps {
    roleAssignment: IRoleAssignment;
    profileUserId?: string;
    displayRole?: boolean;
}

const ListUsersByRoleAssignment: React.FC<IListUsersByRoleAssignmentProps> = ({ roleAssignment, profileUserId, displayRole }) => {
    const { openSnack } = React.useContext(SnackBarContext);
    const { hasPermission } = useHasPermission();
    const { ref, inView } = useInView()
    const [selectedUserForDeletion, setUserForDeletion] = React.useState<IUser | null>(null);
    const [selectedUserForChange, setUserForChange] = React.useState<IUser | null>(null);
    const queryClient = useQueryClient();

    const userQuery = useInfiniteQuery({
        queryKey: ["users", roleAssignment.group],
        queryFn: async ({ pageParam }) => {
            const params = {
                page: pageParam,
                domain_groups: roleAssignment.group
            };
            const res = await fetchUsers({ params: params })
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    React.useEffect(() => {
        if (inView) {
            userQuery.fetchNextPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    const handleClose = (): void => {
        setUserForDeletion(null);
        setUserForChange(null);
        queryClient.invalidateQueries({ queryKey: ["users"] });
    };

    const userRemoveMutation = useMutation({
        mutationFn: async () => {
            if (selectedUserForDeletion) {
                await removeUserFromGroup(roleAssignment.group, selectedUserForDeletion.id)
            }
        },
        onSuccess: handleClose,
        onError: (error: IErrorWithDetail) => {
            openSnack(error.response.data.detail, "error");
        }
    });

    const canManageUser = (user: IUser): boolean => {
        return hasPermission("group", "change_group") && hasPermission("role", `can_invite_${roleAssignment.role.name}`) && user.id !== profileUserId;
    };

    const renderList = (): React.ReactNode => {
        if (userQuery.isFetching && !userQuery.isFetchingNextPage) {
            return (
                <>
                    <ListItem dense disablePadding key={1}>
                        <Skeleton width={"100%"} />
                    </ListItem>
                </>
            );
        }
        return userQuery.data?.pages.map((group, i) => group.data.map((user, j) => (
            <ListItem
                key={user.id}
                dense
                disablePadding
                ref={isLastInArray(userQuery.data?.pages, i) && isLastInArray(group.data, j) ? ref : null}
            >
                <ListItemText style={{ wordBreak: "break-all" }} primaryTypographyProps={{ sx: { paddingRight: '80px' }, variant: "body1" }}>
                    {user.email}
                </ListItemText>
                {canManageUser(user) && (

                    <IconButton onClick={() => setUserForChange(user)}>
                        <ManageAccounts />
                    </IconButton>
                )}
                {hasPermission("user", "delete_user") &&

                    <IconButton onClick={() => setUserForDeletion(user)} disabled={user.id === profileUserId}>
                        <Delete />
                    </IconButton>
                }
            </ListItem>
        )));
    };

    return (
        <>
            <Typography variant="h6" noWrap>{displayRole ? (roleAssignment.role.display_name) : ""}</Typography>
            <List disablePadding sx={{ maxWidth: "500px" }}>
                {renderList()}
            </List>
            {userQuery.isFetchingNextPage && (
                <Skeleton />
            )}
            <BaseDialog open={!!selectedUserForDeletion} onClose={handleClose}>
                {selectedUserForDeletion && (
                    <ConfirmRemoveUser
                        loading={userRemoveMutation.isPending}
                        onClose={handleClose}
                        onConfirm={() => userRemoveMutation.mutate()}
                    />
                )}
            </BaseDialog>
            <BaseDialog open={!!selectedUserForChange} onClose={handleClose}>
                {selectedUserForChange && (
                    <ChangeRole
                        user={selectedUserForChange}
                        onClose={handleClose}
                        currentRoleAssignment={roleAssignment}
                    />
                )}
            </BaseDialog>
        </>
    )
};

export default ListUsersByRoleAssignment;