import { Box, Divider, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, useTheme } from "@mui/material";
import { keepPreviousData, useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { createFireAlarmSystem } from "adapters/fireAlarmSystem";
import { fetchLocalOffice } from "adapters/userManagement";
import { AxiosError } from "axios";
import { FormButtonWrapper } from "components/StyledComponents/Form";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import { OrganizationContext } from "contexts/OrganizationContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";

interface CreateAlarmSystemFormProps {
    onSuccess: (fireAlarmSystemId: string, localOfficeId: string) => void;
    onError: (err: AxiosError) => void;
    buttons: React.ReactNode;
}

interface AlarmSystemParams {
    name: string,
    address: string,
    city: string,
    country: string,
}

const CreateAlarmSystemForm: React.FC<CreateAlarmSystemFormProps> = ({ onSuccess, onError, buttons }) => {
    const { localize } = React.useContext(LocaleContext);
    const { currentOrganizationId } = React.useContext(OrganizationContext);

    const { openSnack } = React.useContext(SnackBarContext);
    const theme = useTheme();

    const createAlarmSystemMutation = useMutation({
        mutationKey: ["create-fire-alarm-system"],
        mutationFn: createFireAlarmSystem,
        onSuccess: (data) => {
            openSnack(`${newAlarmSystemFields.name} ${localize("configuration.create-system-success")}`, "success");
            onSuccess(data.data.id, data.data.local_office);
        },
        onError: (err: AxiosError) => {
            onError(err);
        }
    });

    const [newAlarmSystemFields, setNewAlarmSystemFields] = React.useState({
        name: "",
        address: "",
        city: "",
        zip_code: "",
        country: "",
        description: "",
        local_office: ""
    });

    const onFormFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setNewAlarmSystemFields({ ...newAlarmSystemFields, [event.target.id]: event.target.value })
    };

    const onSubmitAlarm = (event: React.SyntheticEvent) => {
        event.preventDefault();
        createAlarmSystemMutation.mutate({
            ...newAlarmSystemFields,
        });
    };

    const getBackgroundColor = (fieldName: keyof AlarmSystemParams) => {
        const fieldValue = newAlarmSystemFields[fieldName];
        return fieldValue && fieldValue.length > 0 ? '#E8F0FE' : theme.palette.background.default;
    };


    const localOfficeQuery = useInfiniteQuery({
        queryKey: ["localOffice"],
        queryFn: async ({ pageParam }) => {
            const params = {
                'page': pageParam,
                "org_id": currentOrganizationId,
                "has_permission": "add_firealarmsystem"
            };
            const response = await fetchLocalOffice({ params });
            return {
                lenght: response.data.count,
                data: response.data.results,
                nextPage: response.data.next ? pageParam + 1 : undefined,
            };

        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    const lastMenuItemRef = useRef<HTMLLIElement | null>(null);

    const setLastMenuItemRef = (node: HTMLLIElement | null) => {
        if (node) {
            lastMenuItemRef.current = node;
            // Check if the last menu item is visible and trigger fetchNextPage
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        localOfficeQuery.fetchNextPage();
                    }
                },
                { threshold: 1 }
            );

            observer.observe(node);
        }
    };

    const { inView } = useInView();

    const nameIsValid = newAlarmSystemFields.name !== undefined && newAlarmSystemFields.name.length < 100;
    const addressIsValid = newAlarmSystemFields.address !== undefined && newAlarmSystemFields.address.length < 100;
    const cityIsValid = newAlarmSystemFields.city !== undefined && newAlarmSystemFields.city.length < 150;
    const countryIsValid = newAlarmSystemFields.country !== undefined && newAlarmSystemFields.country.length < 74;

    React.useEffect(() => {
        if (inView) {
            localOfficeQuery.fetchNextPage()
        }
    }, [inView, localOfficeQuery]);

    return (
        <form onSubmit={onSubmitAlarm}>
            <Box padding={"1em"} bgcolor={(theme) => theme.palette.background.default}>
                <Stack>
                    <FormControl required variant="outlined">
                        <InputLabel sx={{ left: "-14px", top: "-0.5rem" }} shrink htmlFor="email-input">
                            {localize("configuration.local-office-label")}
                        </InputLabel>
                        <Select
                            id="local-office"
                            variant="outlined"
                            displayEmpty
                            onChange={(e) => setNewAlarmSystemFields({
                                ...newAlarmSystemFields,
                                local_office: e.target.value
                            })}
                            value={newAlarmSystemFields.local_office}
                            input={<OutlinedInput size="small" />}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        paddingRight: "5px",
                                        maxHeight: '20vh'
                                    }
                                },
                            }}
                        >
                            <MenuItem key={1} value="" disabled>
                                <em>{localize("generics.local-office-placeholder")}</em>
                            </MenuItem>

                            {localOfficeQuery.data?.pages.map((group, i) => group.data.map((office, j) => (
                                <MenuItem
                                    ref={j === group.data.length - 1 ? setLastMenuItemRef : null}
                                    key={office.id}
                                    value={office.id}
                                >
                                    {office.name}
                                </MenuItem>
                            )))}
                        </Select>
                    </FormControl>
                    <EBLTextField
                        id="name"
                        variant="outlined"
                        required={true}
                        label={localize("configuration.name-label")}
                        value={newAlarmSystemFields.name}
                        onChange={onFormFieldChange}
                        inputProps={{ maxLength: 100 }}
                        helperText={!nameIsValid && localize("text-validation.100")}
                        sx={{ backgroundColor: getBackgroundColor('name') }}
                    />
                    <EBLTextField
                        id="address"
                        variant="outlined"
                        label={localize("address.address")}
                        value={newAlarmSystemFields.address}
                        onChange={onFormFieldChange}
                        inputProps={{ maxLength: 100 }}
                        helperText={!addressIsValid && localize("text-validation.100")}
                        sx={{ backgroundColor: getBackgroundColor('address') }}

                    />
                    <EBLTextField
                        id="city"
                        variant="outlined"
                        label={localize("address.city")}
                        value={newAlarmSystemFields.city}
                        onChange={onFormFieldChange}
                        inputProps={{ maxLength: 150 }}
                        helperText={!cityIsValid && localize("text-validation.150")}
                        sx={{ backgroundColor: getBackgroundColor('city') }}
                    />
                    <EBLTextField
                        id="zip_code"
                        variant="outlined"
                        label={localize("address.zip-code")}
                        value={newAlarmSystemFields.zip_code}
                        onChange={onFormFieldChange}
                        inputProps={{ maxLength: 16 }}
                        helperText={`${newAlarmSystemFields.zip_code?.length} / 16`}
                    />
                    <EBLTextField
                        variant="outlined"
                        id="country"
                        label={localize("address.country")}
                        value={newAlarmSystemFields.country}
                        onChange={onFormFieldChange}
                        inputProps={{ maxLength: 74 }}
                        helperText={!countryIsValid && localize("text-validation.74")}
                        sx={{ backgroundColor: getBackgroundColor('country') }}
                    />
                    <EBLTextField
                        multiline
                        rows={3}
                        id="description"
                        variant="outlined"
                        label={localize("configuration.description-label")}
                        value={newAlarmSystemFields.description}
                        onChange={onFormFieldChange}
                        inputProps={{ maxLength: 250 }}
                        helperText={`${newAlarmSystemFields.description?.length} / 250`}
                    />
                </Stack>
            </Box>
            <Divider />
            <FormButtonWrapper>
                {buttons}
            </FormButtonWrapper>
        </form>

    );
};

export default CreateAlarmSystemForm;