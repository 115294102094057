import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const EBLTextField = styled(TextField)
    (() => ({
        ".MuiInputLabel-root": {
            position: "absolute",
            left: "-14px",
            top: "-0.5rem" 
        }
    })
);

EBLTextField.defaultProps = {
    size: "small",
    margin: "normal",
    InputProps: {
        notched: false
    },
    InputLabelProps: {
        shrink: true
    }
}