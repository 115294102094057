import { Button, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import { IInvite, ILocalOffice } from "interfaces/userManagement";
import React from "react";
import webAPIRequest from "api";
import { IOrganization } from "interfaces/organization";
import useFormValidation from "hooks/useFormValidation";


interface IGetInvitationDetailsProps {
    onClose: () => void;
    invitation: IInvite;
}

const GetInvitationDetails: React.FC<IGetInvitationDetailsProps> = ({ invitation, onClose }) => {
    const { localize } = React.useContext(LocaleContext);
    const { truncateString } = useFormValidation();

    const getOrganizationDetailQuery = useQuery({
        queryKey: ["organizations", invitation.role_assignment.context_object_id],
        queryFn: async () => {
            return await webAPIRequest<IOrganization>("GET", `/organizations/${invitation.role_assignment.context_object_id}/`);
        },
        enabled: invitation.role_assignment.model === "organization"
    });

    const getLocalOfficeDetailQuery = useQuery({
        queryKey: ["local-offices", invitation.role_assignment.context_object_id],
        queryFn: async () => {
            return await webAPIRequest<ILocalOffice>("GET", `/organizations/local-offices/${invitation.role_assignment.context_object_id}/`);
        },
        enabled: invitation.role_assignment.model === "localoffice"
    });


    const getNameOfDepartment = () => {
        if (invitation.role_assignment.model === "organization") {
            return getOrganizationDetailQuery.data?.data.name;
        }
        return getLocalOfficeDetailQuery.data?.data.name;
    }

    return (
        <>
            <DialogTitle color="primary">
                {`${localize("user-management.invitation-details-title")} / ${truncateString(invitation.email)} `}
            </DialogTitle>
            <DialogContent>

                <Typography variant="subtitle1" fontWeight={800}>
                    {localize("user-management.role")}:
                </Typography>
                <Typography gutterBottom>
                    {invitation.role_assignment.role.display_name}
                </Typography>
                <Typography variant="subtitle1" fontWeight={800}>
                    {localize("user-management.department-name")}:
                </Typography>
                <Typography gutterBottom>
                    {getNameOfDepartment()}
                </Typography>
                <Typography variant="subtitle1" fontWeight={800}>
                    {localize("user-management.department-type")}:
                </Typography>
                <Typography gutterBottom>
                    {invitation.role_assignment.model === "localoffice" ? "Local office" : "Organization"}
                </Typography>

            </DialogContent>
            <BaseDialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {localize("generics.close")}
                </Button>
            </BaseDialogActions>
        </>
    )

};

export default GetInvitationDetails;