import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography} from "@mui/material";
import { Stack } from "@mui/system";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";

export interface IDeviationStatusHeader {
    locale: string;
    alignment?: "inherit" | "left" | "center" | "right" | "justify";
}

interface IDeviationStatusStackProps {
    headers: IDeviationStatusHeader[];
    data: React.ReactNode[];
}

export const DeviationStatusStack: React.FC<IDeviationStatusStackProps> = ({ headers, data }) => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <Grid
            container
            maxWidth={"100%"}
            borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
            >
            <Grid item xs={5} borderRight={(theme) => `1px solid ${theme.palette.divider}`}>
                <Stack spacing="12px">
                    {headers.map((entry, index) => (
                        <Typography variant="subtitle2" key={index} textOverflow="ellipsis" noWrap>
                            {localize(entry.locale)}
                        </Typography>
                    ))}
                </Stack>
            </Grid>
            <Grid item xs={7}>
                <Stack spacing="12px" pl={"16px"}>
                    {data.map((entry, entryIndex) => (
                        <Typography variant="body1" key={entryIndex} noWrap>
                            {entry}
                        </Typography>
                    ))}
                </Stack>
            </Grid>
        </Grid>
    );
};

interface IDeviationStatusTableProps {
    headers: IDeviationStatusHeader[];
    rows: React.ReactNode[][];
}

export const DeviationStatusTable: React.FC<IDeviationStatusTableProps> = ({ headers, rows }) => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <TableCell key={index} align={header.alignment}>
                                {localize(header.locale)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, rowIndex) => (
                        <TableRow key={rowIndex} sx={{'&:nth-last-of-type(1) .MuiTableCell-root': {borderBottom: 0}}}>
                            {row.map((entry, entryIndex) => (
                                <TableCell key={entryIndex} align={headers[entryIndex].alignment}>
                                    {entry}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>

    )
}
