import { Box, CircularProgress, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { useInfiniteQuery, keepPreviousData } from "@tanstack/react-query";
import { fetchFireAlarmSystems } from "adapters/fireAlarmSystem";
import AlarmSystemCardContent from "components/cards/AlarmSystemCardContent";
import DashboardFilters from "components/dashboard/DasboardFilters";
import RefreshButton from "components/dashboard/RefreshButton";
import { FireAlarmStatus } from "components/status/accordions";
import { AlarmSystemCard } from "components/StyledComponents/Card";
import { LocaleContext } from "contexts/LocaleContext";
import { OrganizationContext } from "contexts/OrganizationContext";
import { IFireAlarmSystem } from "interfaces/fireAlarmSystem";
import React from "react";
import { useInView } from 'react-intersection-observer'
import { useSearchParams } from "react-router-dom";
import { isLastInArray } from "utils/arrays";

const OverviewView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { currentOrganizationId } = React.useContext(OrganizationContext);

    const { ref, inView } = useInView();
    let [searchParams] = useSearchParams();
    const subjects = searchParams.get("subjects");
    sessionStorage.removeItem("alarm_system_id");
    sessionStorage.removeItem("local_office_id");

    const {
        isLoading,
        isRefetching,
        data,
        isFetchingNextPage,
        refetch,
        fetchNextPage
    } = useInfiniteQuery({
        queryKey: ["fireAlarmSystems", subjects],
        queryFn: async ({ pageParam }) => {
            const params = { "page": pageParam, fire_alarm_first: true, include_deviations: true, subjects: subjects, org_id: currentOrganizationId };
            const response = await fetchFireAlarmSystems({ params });
            return {
                data: response.data.results,
                nextPage: response.data.next ? pageParam + 1 : undefined,
            };
        },
        enabled: !!currentOrganizationId,
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    React.useEffect(() => {
        if (inView) {
            fetchNextPage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    const hasActiveDeviations = (alarm: IFireAlarmSystem): boolean => {
        return Object.values(alarm.deviations).some(value => value ? value.length > 0 : false);
    };

    return (
        <>
            <Grid pt={"12px"} container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} justifyContent="space-between">
                        <Typography variant="h2" color="primary" pr="2rem">
                            {localize("navigation.overview")}
                        </Typography>
                        <Stack direction="row">
                            <DashboardFilters />
                            <RefreshButton isRefetching={isRefetching} refetchAlarms={refetch} />
                        </Stack>
                    </Stack>
                </Grid>
                {isLoading ? Array.from({ length: 12 }, (_, index) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        xl={3}
                        key={index}
                    >
                        <Skeleton variant="rectangular" height="6rem" />
                    </Grid>
                )) : data?.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.data.map((alarm, j) => alarm.deviations.fire_alarms.length ? (
                            <Grid
                                key={j}
                                item
                                xs={12}
                            >
                                <AlarmSystemCard error={true} >
                                    <AlarmSystemCardContent fireAlarmSystem={alarm}>
                                        <FireAlarmStatus deviations={alarm.deviations.fire_alarms} />
                                    </AlarmSystemCardContent>
                                </AlarmSystemCard>
                            </Grid>
                        ) : (
                            <Grid
                                key={j}
                                item
                                xs={12}
                                md={6}
                                lg={4}
                                xl={3}
                            >
                                <AlarmSystemCard
                                    disablement={!!alarm.deviations.disablements.length}
                                    warning={hasActiveDeviations(alarm)}
                                    ref={isLastInArray(data.pages, i) && isLastInArray(group.data, j) ? ref : null}
                                >
                                    <AlarmSystemCardContent fireAlarmSystem={alarm} />
                                </AlarmSystemCard>
                            </Grid>
                        ))}
                    </React.Fragment>
                ))}
            </Grid>
            {isFetchingNextPage && (
                <Box mt="1em" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

export default OverviewView;