import { TableCell, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  }
}));

export const StyledTableCell = styled(TableCell)(() => ({
  borderWidth: 0,
  textAlign: 'left',
  maxWidth: '30vw',
  whiteSpace: 'pre-line',
  wordBreak: 'break-word'
}));

export const StyledTableContainer = styled(TableContainer)(({theme}) => ({
  height: '80vh',
  [theme.breakpoints.down("sm")]: {
    height: '60vh'
  }

}));