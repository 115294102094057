import { FilterAlt, SwapHoriz, WarningAmber } from "@mui/icons-material";
import { Badge, Box, Button, IconButton, Popover, Stack, styled, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchActiveDeviationCount } from "adapters/fireAlarmSystem";
import { DisablementIcon, TechWarnIcon } from "components/svg/DeviationIcons";
import ServiceSignalIcon from "components/svg/ServiceSignal";
import { LocaleContext } from "contexts/LocaleContext";
import { OrganizationContext } from "contexts/OrganizationContext";
import React from "react";
import { useSearchParams } from "react-router-dom";

type TFilterName =
    | "dev/disablement"
    | "dev/fault"
    | "dev/techwarn"
    | "dev/dirtysensor"
    | "dev/connectionerror"
    | "null";

type TActiveCountName =
    | "disablement_count"
    | "fault_count"
    | "techwarn_count"
    | "dirtysensor_count"
    | "connectionerror_count";


interface IButtonConfig {
    value: TFilterName;
    activeCountName?: TActiveCountName;
    color: 'standard' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    icon: React.ReactNode;
    text: string;
}

export const ToggleFilterButtonGroup = styled(ToggleButtonGroup)
    (({ theme }) => ({
        gap: "8px",
        ".MuiToggleButton-root": {
            border: "0",
            borderRadius: "4px !important",
            ":not(&.Mui-selected)": {
                backgroundColor: theme.palette.grey[100]
            }
        }
    })
    );


const DashboardFilters: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { currentOrganizationId } = React.useContext(OrganizationContext);

    const theme = useTheme();
    const ref = React.useRef(null);
    let [searchParams, setSearchParams] = useSearchParams();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
    const [popoverOpen, setPopoverOpen] = React.useState(false);

    const subjects = searchParams.get("subjects");
    const [filterState, setFilterState] = React.useState<TFilterName[]>(
        () => subjects ? subjects.split(",") as TFilterName[] : []
    );

    const activeDeviationsCountQuery = useQuery({
        queryKey: ["activeDeviations", currentOrganizationId],
        queryFn: () => fetchActiveDeviationCount({ params: { "org_id": currentOrganizationId } }),
        enabled: !!currentOrganizationId
    });

    React.useEffect(() => {
        if (subjects) {
            setFilterState(subjects.split(",") as TFilterName[]);
        }
    }, [subjects]);

    const onFilterChange = (event: React.MouseEvent<HTMLElement>, newFilters: TFilterName[]) => {
        event.preventDefault();
        if (isLargeScreen) {
            setSearchParams({ subjects: newFilters.join(",") })
        } else {
            setFilterState(newFilters);
        }
    };

    const activeFilters = (): TFilterName[] => {
        if (!isLargeScreen) {
            return filterState;
        }

        if (subjects) {
            return subjects.split(",") as TFilterName[];
        }
        return [];
    };

    const commitSmallScreen = (): void => {
        setSearchParams({ subjects: filterState.join(",") })
        setPopoverOpen(false);
        setFilterState([]);
    };

    const onClosePopover = (): void => {
        setPopoverOpen(false);
        setFilterState([]);
    };

    const buttons: IButtonConfig[] = [
        {
            color: "primary",
            value: "null",
            icon: "",
            text: localize("dashboard.filter-without-remarks")
        },
        {
            color: "secondary",
            value: "dev/disablement",
            activeCountName: "disablement_count",
            icon: <DisablementIcon fontSize="small" />,
            text: localize("status.disablement")
        },
        {
            color: "warning",
            value: "dev/fault",
            activeCountName: "fault_count",
            icon: <WarningAmber fontSize="small" />,
            text: localize("status.fault")
        },
        {
            color: "warning",
            value: "dev/techwarn",
            activeCountName: "techwarn_count",
            icon: <TechWarnIcon fontSize="small" />,
            text: localize("status.technical-warning")
        },
        {
            color: "warning",
            value: "dev/dirtysensor",
            activeCountName: "dirtysensor_count",
            icon: <ServiceSignalIcon fontSize="small" />,
            text: localize("status.service-signal")
        },
        {
            color: "warning",
            value: "dev/connectionerror",
            activeCountName: "connectionerror_count",
            icon: <SwapHoriz fontSize="small" />,
            text: localize("status.communication-error")
        }
    ];

    const renderToggleButtons = (): React.ReactNode => {
        return (
            <ToggleFilterButtonGroup
                orientation={isLargeScreen ? "horizontal" : "vertical"}
                value={activeFilters()}
                onChange={onFilterChange}>
                {buttons.map((button, index) => (
                    <ToggleButton
                        key={index}
                        color={button.color}
                        size="small"
                        value={button.value}
                        onChange={onFilterChange}
                        sx={{ ...(!isLargeScreen && { justifyContent: "flex-start" }) }}
                    >
                        <Badge
                            badgeContent={button.activeCountName ? activeDeviationsCountQuery.data?.data[button.activeCountName] : 0}
                            color={activeFilters().includes(button.value) ? "secondary" : "warning"}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
                            <Box display="flex" alignItems="center">
                                {button.icon}{button.text}
                            </Box>
                        </Badge>
                    </ToggleButton>
                ))}
            </ToggleFilterButtonGroup>
        );
    };

    if (isLargeScreen) {
        return <>{renderToggleButtons()}</>;
    }
    return (
        <>
            <IconButton
                ref={ref}
                color="primary"
                size="small"
                sx={{
                    border: (theme) => `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "4px",
                    width: "36px",
                    height: "36px"
                }}
                onClick={() => setPopoverOpen(true)}
            >
                <FilterAlt fontSize="small" />
            </IconButton>
            <Popover
                open={popoverOpen}
                anchorEl={ref.current}
                onClose={onClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Stack padding={"1rem 1.5rem"} bgcolor={(theme) => theme.palette.background.default}>
                    <Typography variant="h6" gutterBottom>
                        {localize("dashboard.filter-title")}
                    </Typography>
                    {renderToggleButtons()}
                </Stack>
                <Stack
                    direction="row"
                    gap="8px"
                    padding={"1rem 1.5rem"}
                    bgcolor={(theme) => theme.palette.secondary.main}>
                    <Button
                        variant="outlined"
                        onClick={onClosePopover}
                    >
                        {localize("generics.cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={commitSmallScreen}>
                        {localize("dashboard.update-filter")}
                    </Button>
                </Stack>
            </Popover>
        </>
    );
};

export default DashboardFilters;