import { useQuery } from "@tanstack/react-query";
import { IProfile } from "adapters/auth";
import { fetchRoleAssignments, fetchRoles } from "adapters/userManagement";
import { IRole, IRoleAssignment } from "interfaces/userManagement";

interface UseUserManagementMethods {
    roles: IRole[];
    roleAssignments: IRoleAssignment[];
    profile?: IProfile;
}

const useUserManagement = (organizationId?: string): UseUserManagementMethods =>  {
    const roleQuery = useQuery({
        queryKey: ["roles", organizationId], 
        queryFn: fetchRoles
    });
    const roleAssignmentsQuery = useQuery({
        enabled: !!organizationId,
        queryKey: ["role-assignments", organizationId],
        queryFn: async () => {
            const params = {
                context_object_id: organizationId
            } 
            return await fetchRoleAssignments({params: params})
        }
    });

    return {
        roles: roleQuery.data?.data.results || [],
        roleAssignments: roleAssignmentsQuery.data?.data || [],
    }
};

export default useUserManagement;