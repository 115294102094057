import {
    useMediaQuery,
    useTheme
} from "@mui/material";
import { StatusAccordion } from "components/StatusAccordion";
import ServiceSignalIcon from "components/svg/ServiceSignal";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import { IDeviationStatusHeader, DeviationStatusTable, DeviationStatusStack } from "components/status/DeviationInfo";
import { IStatusAccordionProps } from "interfaces/statusAccordionProps";

const serviceSignalHeaders: IDeviationStatusHeader[] = [
    {
        locale: "status.time-title",
    },
    {
        locale: "status.zone-address-title",
    },
    {
        locale: "status.tech-no-title",
    },
    {
        locale: "status.outdated-title",
    }
];


const ServiceSignalStatusAccordion: React.FC<IStatusAccordionProps> = ({alarmSystem, formatDate}) => {
    const { localize } = React.useContext(LocaleContext);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const rows = alarmSystem?.deviations.service_signals.map(dev => [
        formatDate(dev.payload.timestamp),
        dev.payload.data.parameters.zoneaddress_desc || "-",
        dev.payload.data.parameters.techno || "-",
        dev.payload.data.parameters.dirtybyage ? "Yes" : "No"
    ]);

    return (
        <StatusAccordion
            icon={<ServiceSignalIcon />}
            numberOfDeviations={alarmSystem?.deviations.service_signals.length}
            loading={!!!alarmSystem}
            title={localize("status.service-signal")}
        >
            {rows && (isLargeScreen ? (
                <DeviationStatusTable
                    headers={serviceSignalHeaders}
                    rows={rows}
                />
            ) : rows.map((row, rowIndex) => (
                <DeviationStatusStack headers={serviceSignalHeaders} key={rowIndex} data={row} />
            )))}
        </StatusAccordion>
    );
};

export default ServiceSignalStatusAccordion;