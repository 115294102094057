import { PrivacyTip } from "@mui/icons-material";
import {
    useMediaQuery,
    useTheme
} from "@mui/material";
import { StatusAccordion } from "components/StatusAccordion";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import { IDeviationStatusHeader, DeviationStatusTable, DeviationStatusStack } from "components/status/DeviationInfo";
import { IStatusAccordionProps } from "interfaces/statusAccordionProps";

const headers: IDeviationStatusHeader[] = [
    {
        locale: "status.time-title"
    },
    {
        locale: "status.zone-address-title",
        alignment: "right"
    },
    {
        locale: "status.type-title",
        alignment: "right"
    },
    {
        locale: "status.state-title",
        alignment: "right"
    },
    {
        locale: "status.text-title",
    }
];


const PreFireAlarmStatusAccordion: React.FC<IStatusAccordionProps> = ({alarmSystem, formatDate}) => {
    const { localize } = React.useContext(LocaleContext);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const rows = alarmSystem?.deviations.pre_warnings.map(dev => [
        formatDate(dev.payload.timestamp),
        dev.payload.data.parameters.zoneaddress_desc || "",
        dev.payload.data.parameters.alarmsource_desc || "-",
        dev.payload.data.parameters?.alarmstate_desc || "-",
        dev.payload.text || "-"
    ]);

    return (
        <StatusAccordion
            icon={<PrivacyTip />}
            numberOfDeviations={alarmSystem?.deviations.pre_warnings.length}
            loading={!!!alarmSystem}
            title={localize("status.pre-warning")}
        >
            {rows && (isLargeScreen ? (
                <DeviationStatusTable
                    headers={headers}
                    rows={rows}
                />
            ) : rows.map((row, rowIndex) => (
                <DeviationStatusStack headers={headers} key={rowIndex} data={row} />
            )))}
        </StatusAccordion>
    );
};

export default PreFireAlarmStatusAccordion;