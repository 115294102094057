import { MenuItem, Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { IProfile } from "adapters/auth";
import webAPIRequest from "api";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import { OrganizationContext } from "contexts/OrganizationContext";
import React from "react";

const UserSelectedOrg: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { currentOrganizationId, updateOrganization } = React.useContext(OrganizationContext);

    const [selectedOrg, setOrganization] = React.useState<string | null>(currentOrganizationId);
    const getProfileDetailQuery = useQuery({
        queryKey: ["profile"],
        queryFn: async () => {
            return await webAPIRequest<IProfile>("GET", `/users/profile/`);
        }
    });


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        sessionStorage.removeItem('selectedOrganization')
        setOrganization(event.target.value)
        updateOrganization(event.target.value)
    }
    
    const totalJoindOrganizations : boolean | undefined = !!getProfileDetailQuery.data?.data.organization.length &&  getProfileDetailQuery.data?.data.organization.length > 1;

    return (
        <Stack direction="row" gap={"4em"} alignItems={"center"}>
            {getProfileDetailQuery.isLoading ? (
                <Skeleton variant="rectangular" width={"300px"} />
            ) : (
                <EBLTextField
                    variant="outlined"
                    label={localize("account.select-organization")}
                    select
                    value={selectedOrg}
                    onChange={handleChange}
                    disabled={!totalJoindOrganizations}
                >
                    {getProfileDetailQuery.data?.data.organization.map((organization) => (

                        <MenuItem key={organization.id} value={organization.id}>
                            {organization.name}
                        </MenuItem>
                    ))}
                </EBLTextField>
            )}

        </Stack>

    );
};

export default UserSelectedOrg;