import { Box, Divider } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createGateway } from "adapters/fireAlarmSystem";
import { FormButtonWrapper } from "components/StyledComponents/Form";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import useFormValidation from "hooks/useFormValidation";
import React from "react";

interface CreateGatewayFormProps {
    alarmSystemId: string;
    onSuccess: () => void;
    onError: () => void;
    buttons: React.ReactNode;
}

const CreateGatewayForm: React.FC<CreateGatewayFormProps> = ({onSuccess, onError, buttons, alarmSystemId}) => {
    const { localize } = React.useContext(LocaleContext);

    const createGatewayMutation = useMutation({
        mutationKey: ["create-gateway"],
        mutationFn: createGateway,
        onSuccess: onSuccess,
        onError: onError
    });

    const [newGatewayFields, setNewGatewayFields] = React.useState({
        mac_address: "",
        fire_alarm_system: alarmSystemId
    });

    const onSubmitGateway = (event: React.SyntheticEvent) => {
        event.preventDefault();
        createGatewayMutation.mutate({...newGatewayFields});
    };
    const { 
        validateMacAddress
    } = useFormValidation();

    
    const mac_addressIsValid = validateMacAddress(newGatewayFields.mac_address)

    return (
        <form onSubmit={onSubmitGateway}>
            <Box padding={"1em"} bgcolor={(theme) => theme.palette.background.default}>
                <EBLTextField
                    id="mac_address"
                    variant="outlined"
                    label={localize("configuration.mac-address-label")}
                    value={newGatewayFields.mac_address}
                    error={!!newGatewayFields.mac_address && !mac_addressIsValid}
                    helperText={!mac_addressIsValid && localize("configuration.valid-mac-address-label")}
                    onChange={(e) => setNewGatewayFields({...newGatewayFields, mac_address: e.target.value})}
                    inputProps={{
                        maxLength: 13,
                        autoComplete: "off",
                    }}
                />
            </Box>
            <Divider />
            <FormButtonWrapper>
                {React.Children.map(Array.isArray(buttons) ? buttons : [buttons], (button) =>
                    React.cloneElement(button, { disabled: button.props.type === "submit" && !mac_addressIsValid })
                )}
            </FormButtonWrapper>
        </form>

    );
};

export default CreateGatewayForm;