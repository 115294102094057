import { Button, CircularProgress, Link, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { checkCredentials, ITOTPDevice } from "adapters/auth";
import SetupTOTP from "components/authenticate/SetupTOTP";
import VerifyTOTPForm from "components/authenticate/VerifyOtpForm";
import PasswordField from "components/inputFields/PasswordField";
import { NoAuthCard } from "components/StyledComponents/NoAuthCompontents";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import useAuthenticate from "hooks/useAuthenticate";
import useFormValidation from "hooks/useFormValidation";
import { Link as RouterLink } from "react-router-dom";
import React from "react";

interface ILoginState {
    email: string;
    password: string;
    configUrl: string;
    credentialsChecked: boolean;
}

const defaultLoginState: ILoginState = {
    email: "",
    password: "",
    configUrl: "",
    credentialsChecked: false,
}

const Login: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const [loginState, setLoginState] = React.useState<ILoginState>(defaultLoginState);
    const { validateEmail } = useFormValidation();
    const { authenticateOTPUser, isLoading } = useAuthenticate();

    const credentialsMutation = useMutation({
        mutationFn: checkCredentials,
        onSuccess: (data) => {
            let configUrl = "";
            if (data.data) {
                const device = data.data as ITOTPDevice;
                configUrl = device.config_url;
            }
            setLoginState({ ...loginState, configUrl, credentialsChecked: true });
        },
        onError: (e) => {
            openSnack(localize("login.invalid-credentials"), "error");
        }
    });

    const onSubmitCheckCredentials = (event: React.SyntheticEvent): void => {
        event.preventDefault();
        credentialsMutation.mutate({
            email: loginState.email,
            password: loginState.password,
        });
    };

    const onCancel = () => {
        setLoginState(defaultLoginState);
    };

    const verifyForm = (
        <VerifyTOTPForm
            onCancel={onCancel}
            onVerify={(token) => authenticateOTPUser(loginState.email, loginState.password, token)}
            loading={isLoading}
        />
    )

    const emailIsValid = validateEmail(loginState.email);

    const renderContent = (): React.ReactNode => {
        if (!loginState.credentialsChecked) {
            return (
                <>
                    <Typography gutterBottom color="primary" variant="h1">{localize("login.title")}</Typography>
                    <form noValidate onSubmit={onSubmitCheckCredentials}>
                        <EBLTextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            error={!emailIsValid}
                            label={localize("generics.email")}
                            autoComplete="email"
                            autoFocus
                            value={loginState.email}
                            onChange={e => setLoginState({ ...loginState, email: e.target.value })}
                        />
                        <PasswordField
                            variant="outlined"
                            required
                            fullWidth
                            label={localize("generics.password")}
                            id="password"
                            autoComplete="current-password"
                            value={loginState.password}
                            onChange={e => setLoginState({ ...loginState, password: e.target.value })}
                        />
                        <Typography variant="caption">
                            {localize("login.forgot-password") + " "}
                            <Link component={RouterLink} to="/reset-password/">
                                {localize("login.forgot-password-action")}
                            </Link>
                        </Typography>
                        <Button
                            type="submit"
                            sx={{ float: "right", marginTop: "28px" }}
                            variant="contained"
                            color="primary"
                            disabled={!emailIsValid || credentialsMutation.isPending}
                            endIcon={credentialsMutation.isPending && <CircularProgress size={"1rem"} />}
                        >
                            {localize("generics.continue")}
                        </Button>
                    </form>
                </>
            )
        }
        if (loginState.configUrl) {
            return <SetupTOTP
                onCancel={onCancel}
                verifyForm={verifyForm}
                configUrl={loginState.configUrl}
            />;
        }
        return (
            <>
                <Typography gutterBottom color="primary" variant="h1">
                    {localize("login.title")}
                </Typography>
                <Typography gutterBottom variant="body1">
                    {localize("login.input-code-description")}
                </Typography>
                {verifyForm}
            </>
        )
    }

    return (
        <NoAuthCard sx={{ width: "90% !important" }}>
            {renderContent()}
        </NoAuthCard>
    )
};

export default Login;