import webAPIRequest, { IPaginatedData } from "api";
import { AxiosRequestConfig } from "axios";
import {
    IBaseFireAlarmSystem,
    ICreateGateway,
    IFireAlarmSystem,
    IFireAlarmSystemContactPerson,
    IActiveDeviationCount,
    ICommand,
    IEvents,
    IDeviation,
    ICreateCommand,
    IJournal,
    ICreateJournalEntry
} from "interfaces/fireAlarmSystem";

export const fetchFireAlarmSystems = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<IFireAlarmSystem>>("GET", "/fire-alarm-systems/", opts);

export const fetchActiveDeviationCount = async (opts: AxiosRequestConfig) => webAPIRequest<IActiveDeviationCount>("GET", "/fire-alarm-messages/active-deviations-count/", opts);

export const createFireAlarmSystem = async (data: IBaseFireAlarmSystem) =>
    webAPIRequest<IFireAlarmSystem>("POST", "/fire-alarm-systems/", { data: data });

export const fetchFireAlarmSystem = async (id: string) =>
    webAPIRequest<IFireAlarmSystem>("GET", `/fire-alarm-systems/${id}/`, { params: { "include_deviations": true } });

export const patchFireAlarmSystem = async (id: string, data: Partial<IFireAlarmSystem>) =>
    webAPIRequest<IFireAlarmSystem>("PATCH", `/fire-alarm-systems/${id}/`, { data: data, params: { "include_deviations": true } });

export const deleteFireAlarmSystem = async (id: string) =>
    webAPIRequest("DELETE", `/fire-alarm-systems/${id}/`);

export const createGateway = async (data: ICreateGateway) =>
    webAPIRequest("POST", "/fire-alarm-systems/gateways/", { data: data });

export const createGatewayCredentials = async (id: string, password: string) =>
    webAPIRequest<{ id: string, password: string }>("POST", `/fire-alarm-systems/gateways/${id}/create-account/`, { data: { password: password } });

export const disconnectGateway = async (id: string) =>
    webAPIRequest("PATCH", `/fire-alarm-systems/gateways/${id}/disconnect/`,  { data: {} });

export const fetchFireAlarmSystemContactPersons = async (fireAlarmId: string) =>
    webAPIRequest<IPaginatedData<IFireAlarmSystemContactPerson>>("GET", "/fire-alarm-systems/contact-persons/", { params: { "fire_alarm_system": fireAlarmId } });

export const patchFireAlarmSystemContactPersons = async (id: string, data: Partial<IFireAlarmSystemContactPerson>) =>
    webAPIRequest<IFireAlarmSystemContactPerson>("PATCH", `/fire-alarm-systems/contact-persons/${id}/`, { data: data });

export const createFireAlarmSystemContactPersons = async (data: Omit<IFireAlarmSystemContactPerson, "id">) =>
    webAPIRequest<IFireAlarmSystemContactPerson>("POST", `/fire-alarm-systems/contact-persons/`, { data: data });

export const fetchCommands = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<ICommand>>("GET", "/fire-alarm-messages/commands/", opts);


export const fetchCommand = async (commandId: string) =>
    webAPIRequest<ICommand>("GET", `/fire-alarm-messages/commands/${commandId}`);


export const fetchDeviations = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<IDeviation>>("GET", "/fire-alarm-messages/deviations/", opts);

export const createCommand = async (data: ICreateCommand) =>
    webAPIRequest<ICommand>("POST", "/fire-alarm-messages/commands/", { data: data });

export const deleteFireAlarmSystemContactPersons = async (id: string) =>
    webAPIRequest("DELETE", `/fire-alarm-systems/contact-persons/${id}/`);

export const fetchEvents = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<IEvents>>("GET", "/fire-alarm-messages/events/", opts);

export const downloadEvents = async (opts?: AxiosRequestConfig) =>
    webAPIRequest<IEvents[]>("GET", "/fire-alarm-messages/events/download/", opts);

export const fetchJournal = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<IJournal>>("GET", "/fire-alarm-systems/journal/", opts);

export const createJournalEntry= async (data: ICreateJournalEntry) =>
    webAPIRequest<IJournal>("POST", "/fire-alarm-systems/journal/", { data: data });

export const downloadJournal = async (opts?: AxiosRequestConfig) =>
    webAPIRequest<IJournal[]>("GET", "/fire-alarm-systems/journal/download/", opts);