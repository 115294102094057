import { Button, DialogContent, DialogTitle, Typography } from "@mui/material";
import { BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";


interface OperationFailedDialogContentProps {
    onClose: () => void;
}

const OperationFailedDialogContent: React.FC<OperationFailedDialogContentProps> = ({onClose}) => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <>
            <DialogTitle color="primary">
                {localize("operations.general-warning")}
            </DialogTitle>
            <DialogContent
                sx={{
                    xs: {
                        width: "250px"
                    },
                    sm: {
                        width: "370px"
                    }
                }}>
                <Typography gutterBottom>
                    {localize("operations.error-description")}
                </Typography>
            </DialogContent>
            <BaseDialogActions>
                <Button variant="contained" onClick={onClose}>
                    {localize("generics.close")}
                </Button>
            </BaseDialogActions>
        </>
    )
};

export default OperationFailedDialogContent;