import { Box, Divider, Grid, Typography } from "@mui/material";
import CreateOrganizationForm from "components/organizationManagement/CreateOrganizationForm";
import ListOrganizations from "components/organizationManagement/ListOrganizations";
import { LocaleContext } from "contexts/LocaleContext";
import { useHasPermission } from "hooks/useHasPermission";
import React from "react";


const OrganizationManagementView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { hasPermission } = useHasPermission();

    return (
        <Grid>

            <Typography variant="h2" color="primary" gutterBottom>
                {localize("navigation.organization-management")}
            </Typography>
            {hasPermission("organization", "add_organization") &&
                <Grid container>
                    <Grid item xs={12} md={6} sx={{ pr: { md: "64px" } }}>
                        <Typography variant="h4" color="text">
                            {localize("organization-management.create-organization")}
                        </Typography>
                        <CreateOrganizationForm />
                        <Box mt="1em">
                            <Typography variant="h4" color="text" gutterBottom>
                                {localize("organization-management.pending")}
                            </Typography>
                            <ListOrganizations status="pending" ordering="-created" />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={(theme) => ({
                            paddingLeft: { md: "32px" },
                            borderLeft: { md: `1px solid ${theme.palette.grey[600]}` }
                        })}
                    >
                        <Divider sx={{ display: { md: "none" }, mt: "1em", mb: "1em" }}></Divider>
                        <Typography variant="h4" color="text" gutterBottom>
                            {localize("organization-management.current")}
                        </Typography>
                        <Box mt="1em">
                            <ListOrganizations status="active" ordering="name" />
                        </Box>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export default OrganizationManagementView;