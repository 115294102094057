import { Box, Button, Divider } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createLocalOffice } from "adapters/userManagement";
import { FormButtonWrapper } from "components/StyledComponents/Form";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import useFormValidation from "hooks/useFormValidation";
import { AxiosError } from "axios";

interface CreateLocalOfficeFormProps {
    onSuccess: () => void;
    onError: (err: AxiosError) => void;
    context_object_id: string | undefined | null;
    onClose: () => void;
}


const CreateLocalOfficeForm: React.FC<CreateLocalOfficeFormProps> = ({ onSuccess, onError, onClose, context_object_id }) => {
    const { localize } = React.useContext(LocaleContext);
    const { fieldIsNotEmpty } = useFormValidation();

    const createLocalOfficeMutation = useMutation({
        mutationKey: ["local-offices"],
        mutationFn: createLocalOffice,
        onSuccess: onSuccess,
        onError: (err: AxiosError) => {
            onError(err);
        }
    });

    const [newLocalOfficeFields, setnewLocalOfficeFields] = React.useState({
        local_office: "",
        context_object_id: ""
    });

    const onSubmitLocalOffice = (event: React.SyntheticEvent) => {
        event.preventDefault();
        createLocalOfficeMutation.mutate({
            name: newLocalOfficeFields.local_office,
            organization: context_object_id
        });
    };


    const localOfficeIsValid = fieldIsNotEmpty(newLocalOfficeFields.local_office)
    const localOfficeLengthIsValid = newLocalOfficeFields.local_office !== undefined && newLocalOfficeFields.local_office.length < 100;

    return (
        <form onSubmit={onSubmitLocalOffice}>
            <Box padding={"1em"} bgcolor={(theme) => theme.palette.background.default}>
                <EBLTextField
                    id="local_office"
                    variant="outlined"
                    label={localize("generics.local-office")}
                    value={newLocalOfficeFields.local_office}
                    onChange={(e) => setnewLocalOfficeFields({ ...newLocalOfficeFields, local_office: e.target.value })}
                    helperText={!localOfficeLengthIsValid && localize("text-validation.100")}
                    inputProps={{
                        maxLength: 100,
                        autoComplete: "off",
                    }}
                />
            </Box>
            <Divider />
            <FormButtonWrapper>
                <Button variant="outlined" onClick={onClose}>
                    {localize("generics.cancel")}
                </Button>
                <Button
                    disabled={!localOfficeIsValid}
                    variant="contained"
                    type="submit"
                >
                    {localize("generics.confirm")}
                </Button>
            </FormButtonWrapper>
        </form>

    );
};

export default CreateLocalOfficeForm;