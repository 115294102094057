import { Stack, alpha, styled } from "@mui/material";

export const ScrollableStack = styled(Stack)
    (({ theme }) => ({
        overflowX: "hidden",
        overflowY: "auto",
        height: "100%",
        paddingRight: "5px",
        scrollbarWidth: "auto",
        scrollbarColor: `${alpha(theme.palette.primary.main, 0.8)} none`,
        "::-webkit-scrollbar": {
            width: "6px"
        },
        "::-webkit-scrollbar-track": {
            background: "none",
        },
        "::-webkit-scrollbar-thumb": {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            borderRadius: "10px",
        }
    })
    );