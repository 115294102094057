import { deleteInvite } from "adapters/userManagement";
import { Button, CircularProgress, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import { IInvite } from "interfaces/userManagement";
import React from "react";
import { IErrorWithDetail } from "api";
import { SnackBarContext } from "contexts/SnackBarContext";


interface IConfirmDeleteInvitationProps {
    onClose: () => void;
    invitation: IInvite;
}

const ConfirmDeleteInvitation: React.FC<IConfirmDeleteInvitationProps> = ({ invitation, onClose }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const queryClient = useQueryClient();
    const inviteDeleteMutation = useMutation({
        mutationFn: deleteInvite,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["invites"] });
            onClose();
        },
        onError: (error: IErrorWithDetail) => {
            openSnack(error.response.data.detail, "error");
        }
    });

    const onConfirmDelete = () => inviteDeleteMutation.mutate(invitation.id);

    return (
        <>
            <DialogTitle color="primary">
                {localize("user-management.confirm-delete-invitation-title")}
            </DialogTitle>
            <DialogContent
                sx={{
                    xs: {
                        width: "250px"
                    },
                    sm: {
                        width: "370px"
                    }
                }}>
                <Typography gutterBottom>
                    {localize("user-management.confirm-delete-invitation-content")}
                </Typography>
                <Typography gutterBottom>
                    {localize("user-management.confirm-delete-invitation-content2")}
                </Typography>
            </DialogContent>
            <BaseDialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {localize("user-management.cancel")}
                </Button>
                <Button
                    variant="contained"
                    disabled={inviteDeleteMutation.isPending}
                    endIcon={inviteDeleteMutation.isPending && <CircularProgress size={"1rem"} />}
                    onClick={onConfirmDelete}
                >
                    {localize("user-management.confirm")}
                </Button>
            </BaseDialogActions>
        </>
    )

};

export default ConfirmDeleteInvitation;