

import { createSvgIcon } from "@mui/material";
import React from "react";

export const EventsIcon = createSvgIcon(
    <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.49988 2.68496C8.49988 3.40777 9.1754 3.99999 9.99988 3.99999C10.8244 3.99999 11.4999 3.40777 11.4999 2.68496V1.99999H17.2932C18.7824 1.99999 20 3.24875 20 4.77491V18.1661C20 19.6914 18.7824 20.9401 17.2932 20.9401H2.7067C1.2175 20.9401 -0.00012207 19.6914 -0.00012207 18.1661V4.77491C-0.00012207 3.24875 1.2175 1.99999 2.7067 1.99999H8.49988V2.68496ZM17.3195 9.45782H2.67957C2.30414 9.45782 1.99988 9.13171 1.99988 8.72846C1.99988 8.32609 2.30414 7.99998 2.67957 7.99998H17.3195C17.6949 7.99998 18 8.32609 18 8.72846C18 9.13171 17.6949 9.45782 17.3195 9.45782ZM2.53856 15.3824H11.332C11.6296 15.3824 11.8707 15.1414 11.8707 14.8438V14.5387C11.8707 14.2411 11.6296 14 11.332 14H2.53856C2.24095 14 1.99988 14.2411 1.99988 14.5387V14.8438C1.99988 15.1414 2.24095 15.3824 2.53856 15.3824ZM17.3195 12.4375H2.67968C2.30426 12.4375 2 12.1159 2 11.7183C2 11.3216 2.30426 11 2.67968 11H17.3195C17.6949 11 18 11.3216 18 11.7183C18 12.1159 17.6949 12.4375 17.3195 12.4375Z"/>
        <path d="M9.99994 3C9.72511 3 9.49994 2.74619 9.49994 2.43642V0.563584C9.49994 0.25381 9.72511 0 9.99994 0C10.2748 0 10.4999 0.25381 10.4999 0.563584V2.43642C10.4999 2.74619 10.2748 3 9.99994 3Z" />
    </g>,
    "EventsIcon"
);
