import { MenuItem, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { IProfile, patchProfile } from "adapters/auth";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import { format } from "date-fns";
import React from "react";


const UserPreferredDateFormat: React.FC<{ account: IProfile }> = ({ account }) => {
    const { localize } = React.useContext(LocaleContext);
    const [selectedDateFormat, setDateFormat] = React.useState(account.date_format);
    const validFormats = ["yyyy-MM-dd H:mm:ss O", "yyyy/MM/dd hh:mm:ss a O"]

    const updateProfileMutation = useMutation({
        mutationFn: async (selectedDateFormat: string) => {
            if (validFormats.includes(selectedDateFormat)) {
                const userId = account.id;
                const data = {
                    "date_format": selectedDateFormat
                }
                if (userId) {
                    return await patchProfile(userId, data);
                }
            }
            return null;
        },
        onSuccess: (data) => {
            if (data) {
                setDateFormat(data?.data.date_format)
            }

        }
    });

    return (
        <Stack>
            <EBLTextField
                variant="outlined"
                label={localize("account.preferred-date-format")}
                id="name"
                select
                value={selectedDateFormat}
                onChange={e => updateProfileMutation.mutate(e.target.value)}
                sx={{width: 'fit-content'}}
            >
                <MenuItem value={"yyyy-MM-dd H:mm:ss O"}>{format(new Date(), "yyyy-MM-dd H:mm:ss O")}</MenuItem>
                <MenuItem value={"yyyy/MM/dd hh:mm:ss a O"}>{format(new Date(), "yyyy/MM/dd hh:mm:ss a O")}</MenuItem>
            </EBLTextField>
        </Stack>
    );
};

export default UserPreferredDateFormat;