import { Step, StepLabel, Stepper, styled } from "@mui/material";


export const BaseStepper = styled(Stepper)
    (({theme}) => ({
        marginBottom: "2em",

        ".MuiStepConnector-line": {
            borderColor: theme.palette.primary.main
        }
    })
);


export const BaseStep = styled(Step)
    (({theme}) => ({
        position: "relative",
        padding: 0,
        ".MuiStepLabel-labelContainer": {
            position: "absolute",
            top: "24px",
            left: "50%",
            width: "50px",
            maxWidth: "100px",
            transform: "translateX(-50%)",
        },
        ".MuiStepLabel-iconContainer": {
            padding: 0,
            "&.Mui-active svg text": {
                fill: theme.palette.secondary.light
            },
            "&.Mui-disabled svg": {
                stroke: theme.palette.primary.main,
                // strokeWidth: "-1px",
                color: theme.palette.secondary.light,
                "& circle": {
                    r: 11,
                }
            }
        }
    })
);

export const BaseStepLabel = styled(StepLabel)
    (({theme}) => ({
        ".MuiStepLabel-label" : {
            fontSize: "10px",
            whiteSpace: "nowrap",
            textAlign: "center",
            color: theme.palette.grey[600],
        },
    })
);