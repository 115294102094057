import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, OutlinedTextFieldProps } from "@mui/material";
import { EBLTextField } from "components/StyledComponents/TextField";
import React from "react";

const PasswordField: React.FC<OutlinedTextFieldProps> = ({onChange, ...textFieldProps}) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        onChange && onChange(e);
    };

    return (
        <EBLTextField
            {...textFieldProps}
            type={showPassword ? "text" : "password"}
            onChange={onValueChange}
            InputProps={{
                ...textFieldProps.InputProps,
                notched: false,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
};

export default PasswordField;