
import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

export const EBLSwitch = styled(Switch)
    (({theme}) => ({
        alignItems: "center",
        ".MuiSwitch-track": {
            backgroundColor: `${theme.palette.grey[100]} !important`,
            border: `0.5px solid ${theme.palette.grey[800]}`,
            borderRadius: "4px",
            height: "20px"
        },
        ".MuiSwitch-colorPrimary": {
            color: theme.palette.grey[600],
        },
        ".Mui-checked": {
            color: theme.palette.primary.main
        },
        ".MuiSwitch-thumb": {
            borderRadius: "4px",
        },
    })
);
