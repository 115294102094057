import { Button, CircularProgress, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteOrganization } from "adapters/organizations";
import { IErrorWithDetail } from "api";
import { BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import React from "react";


interface IConfirmRemoveOrganizationProps {
    organizationId: string;
    onClose: () => void;
}

const ConfirmRemoveOrganization: React.FC<IConfirmRemoveOrganizationProps> = ({ organizationId, onClose }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const queryClient = useQueryClient();
    const userRemoveMutation = useMutation({
        mutationFn: async () => await deleteOrganization(organizationId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["organizations-active"] });
            queryClient.invalidateQueries({ queryKey: ["organizations-pending"] });
            onClose();
        },
        onError: (error: IErrorWithDetail) => {
            openSnack(error.response.data.detail, "error");
        }
    });

    return (
        <>
            <DialogTitle color="primary">
                {localize("organization-management.confirm-delete-organization-title")}
            </DialogTitle>
            <DialogContent
                sx={{
                    xs: {
                        width: "250px"
                    },
                    sm: {
                        width: "370px"
                    }
                }}>
                <Typography gutterBottom>
                    {localize("organization-management.confirm-delete-organization-content")}
                </Typography>
                <Typography gutterBottom>
                    {localize("organization-management.confirm-delete-organization-content2")}
                </Typography>
            </DialogContent>
            <BaseDialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {localize("generics.cancel")}
                </Button>
                <Button
                    disabled={userRemoveMutation.isPending}
                    variant="contained"
                    onClick={() => userRemoveMutation.mutate()}
                    endIcon={userRemoveMutation.isPending && <CircularProgress size={"1rem"} />}
                >
                    {localize("generics.confirm")}
                </Button>
            </BaseDialogActions>
        </>
    )
};

export default ConfirmRemoveOrganization;