
interface UseFormValidationMethods {
    validatePassword: (rawPassword: string) => boolean;
    validateEmail: (email: string) => boolean;
    validatePhoneNumber: (phoneNumber: string) => boolean;
    passwordIsNotValidTag: (password: string, passwordToMatch?: string) => string;
    validateMacAddress: (macAddress: string) => boolean;
    fieldIsNotEmpty: (field: string) => boolean;
    truncateString: (name: string | undefined) => string | null;
}

const useFormValidation = (): UseFormValidationMethods => {

    const validatePassword = (rawPassword: string): boolean => {
        return !!rawPassword && rawPassword.length > 7 && !/^[0-9]*$/.test(rawPassword);
    };

    const validateEmail = (email: string): boolean => {
        return !!email && (/(.+)@(.+){2,}\.(.+){2,}/).test(email);
    };

    const validatePhoneNumber = (phoneNumber: string): boolean => {
        return !!phoneNumber && /^\+\d{9,15}$/.test(phoneNumber);
    };

    const passwordIsNotValidTag = (password: string, passwordToMatch?: string): string => {
        if (password) {
            if (password.length < 8) {
                return "password.not-long-enough";
            } else if (/^[0-9]*$/.test(password)) {
                return "password.only-numbers";
            }
        }
        if (passwordToMatch && passwordToMatch !== password) {
            return "password.does-not-match"
        }
        return "";
    };

    const macAddressRegex = /^[0-9a-fA-F]{12}$/;
    const validateMacAddress = (macAddress: string): boolean => {

        return macAddressRegex.test(macAddress)
    }

    const EmptyAndWhiteSpaceRegex = /^(?:\s*)$/;
    const fieldIsNotEmpty = (field: string): boolean => {

        return !EmptyAndWhiteSpaceRegex.test(field)
    }

    const truncateString = (name: string | undefined): string | null => {
        if (name) {
            return name.length > 25 ? name.substring(0, 21) + "..." : name;
        }
        return null
    }

    return {
        validatePassword,
        validateEmail,
        validatePhoneNumber,
        passwordIsNotValidTag,
        validateMacAddress,
        fieldIsNotEmpty,
        truncateString
    }
};

export default useFormValidation;