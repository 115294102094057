import { Box, Button, Grid, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchFireAlarmSystem } from "adapters/fireAlarmSystem";
import ChangableField from "components/ChangableField";
import AlarmSystemActions from "components/fireAlarmSystem/AlarmSystemActions";
import ListContactPersons from "components/fireAlarmSystem/ListContactPersons";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import { IFireAlarmSystem } from "interfaces/fireAlarmSystem";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "contexts/SnackBarContext";
import { getMacAddress } from "utils/getMacAdress";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import { useHasPermission } from "hooks/useHasPermission";
import { CommunicationWarningIcon } from "components/svg/CommunicationWarningIcon";
import { UnconfiguredCommunicationIcon } from "components/svg/UnconfiguredCommunicationIcon";
import { CommunicationCheckCircleIcon } from "components/svg/CommunicationCheckCircleIcon";
import theme from "styling/theme";
import { formatDatetimeToHoursAndMinutes } from "utils/formatDatetimeToHoursAndMinutes";

const AlarmSystemConfigurationView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { alarmSystemId, alarmSystem, isLoading: isLoadingSystem } = useGetAlarmSystem();
    const { hasPermission } = useHasPermission("localoffice");

    const [mutableAlarm, setMutableAlarm] = React.useState<Partial<IFireAlarmSystem>>({
        name: "",
        address: "",
        city: "",
        country: "",
        zip_code: "",
        description: "",
        organization: ""
    });
    const [showTooltip, setShowTooltip] = React.useState<boolean>(false)

    const { openSnack } = React.useContext(SnackBarContext);
    const onFailureCreateAlarm = (): void => {
        openSnack(localize("configuration.system-already-exists"), "error")
    };

    React.useEffect(() => {
        if (alarmSystem) {
            setMutableAlarm(alarmSystem);
        }
    }, [alarmSystem])

    const patchAlarmSystemMutation = useMutation({
        mutationKey: ["patch-fire-alarm-system"],
        mutationFn: async (data: Partial<IFireAlarmSystem>) => {
            return await patchFireAlarmSystem(alarmSystemId ?? "", data);
        },
        onSuccess: (data) => {
            queryClient.setQueryData(["fire-alarm-system", alarmSystemId], data);
        },
        onError: onFailureCreateAlarm
    });

    const refreshInterval = 10000;

    useEffect(() => {
        const intervalId = setInterval(() => {
            queryClient.invalidateQueries({ queryKey: ["fire-alarm-system", alarmSystemId] })
        }, refreshInterval);

        return () => clearInterval(intervalId);
    }, [alarmSystemId, queryClient]);

    const renderAddress = () => {
        const system = alarmSystem;
        if (!system) {
            return ""
        }
        return `${system.address}\n${system.zip_code} ${system.city}\n${system.country}`
    };

    const isLoading = isLoadingSystem || patchAlarmSystemMutation.isPending;

    const activeGateway = alarmSystem?.gateways.find(gw => gw.disconnected === null);

    const nameIsValid = mutableAlarm.name !== undefined && mutableAlarm.name.length < 100;
    const addressIsValid = mutableAlarm.address !== undefined && mutableAlarm.address.length < 100;
    const cityIsValid = mutableAlarm.city !== undefined && mutableAlarm.city.length < 150;
    const countryIsValid = mutableAlarm.country !== undefined && mutableAlarm.country.length < 74;

    const renderStatus = (): React.ReactNode => {
        switch (activeGateway?.status) {
            case "connected":
                return (
                    <Tooltip title={localize("configuration.success-connection-gateway")}
                        open={showTooltip}
                        onOpen={() => (true)}
                        onClose={() => setShowTooltip(false)}
                        placement={"right"}
                        arrow>
                        <CommunicationCheckCircleIcon
                            onClick={() => setShowTooltip(!showTooltip)}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        />
                    </Tooltip>
                );
            case "disconnected":
                return (
                    <Tooltip title={localize("configuration.failed-connection-gateway")}
                        open={showTooltip}
                        onOpen={() => (true)}
                        onClose={() => setShowTooltip(false)}
                        placement={"right"}
                        arrow>
                        <CommunicationWarningIcon
                            onClick={() => setShowTooltip(!showTooltip)}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        />
                    </Tooltip>
                );
            case "waiting":
                return (
                    <Tooltip title={localize("configuration.gateway-setup-in-progress")}
                        open={showTooltip}
                        onOpen={() => (true)}
                        onClose={() => setShowTooltip(false)}
                        placement={"right"}
                        arrow>
                        <HourglassBottomIcon
                            sx={{ size: "small", color: theme.palette.warning.main }}
                            onClick={() => setShowTooltip(!showTooltip)}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        />
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip title={localize("configuration.unConfigured-gateway")}
                        open={showTooltip}
                        onOpen={() => (true)}
                        onClose={() => setShowTooltip(false)}
                        placement={"right"}
                        arrow>
                        <UnconfiguredCommunicationIcon
                            onClick={() => setShowTooltip(!showTooltip)}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        />
                    </Tooltip>
                );
        }
    }

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" sx={{
                    marginBottom: "1.375em",
                    color: (theme) => theme.palette.primary.main,
                    display: { xs: "none", sm: "inherit" }
                }}>
                    {isLoading ? <Skeleton width="10rem" /> : `${alarmSystem?.name} / ${localize("navigation.settings")} / ${localize("navigation.configuration")}`}
                </Typography>
                <Button
                    variant="contained"
                    disabled={isLoadingSystem}
                    onClick={() => navigate(`/${alarmSystem?.local_office}/status/${alarmSystem?.id}`)}
                    sx={{
                        marginBottom: "2.750em",
                        padding: "12px 24px",
                        display: { xs: "none", sm: "inherit" }
                    }}>
                    <Typography variant="button">
                        {localize("configuration.detail-button")}
                    </Typography>
                </Button>
            </Box>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    sx={{
                        borderRight: (theme) => ({
                            md: `1px solid ${theme.palette.divider}`
                        }),
                        padding: {
                            md: "0 1.5em 0 0",
                            xs: "1.5em 0",
                        }
                    }}>
                    <Typography variant="h4" gutterBottom>
                        {localize("configuration.alarm-system-information")}
                    </Typography>
                    <ChangableField
                        isLoading={isLoading}
                        label={localize("configuration.name-label")}
                        data={alarmSystem?.name}
                        onSubmit={() => patchAlarmSystemMutation.mutate({ name: mutableAlarm.name, organization: mutableAlarm.organization, local_office: mutableAlarm.local_office })}
                        popoverFormData={
                            <EBLTextField
                                variant="outlined"
                                label={localize("configuration.name-label")}
                                id="name"
                                value={mutableAlarm.name}
                                onChange={e => setMutableAlarm({ ...mutableAlarm, name: e.target.value })}
                                inputProps={{ maxLength: 100 }}
                                helperText={!nameIsValid && localize("text-validation.100")}
                            />
                        }
                        popoverAction={
                            <Button
                                variant="contained"
                                type="submit"
                                onError={onFailureCreateAlarm}
                            >
                                {localize("account.update-name")}
                            </Button>
                        }
                        hasPermission={hasPermission("firealarmsystem", "change_firealarmsystem")}
                    />
                    <Box>
                        <Typography variant="overline" color="primary">
                            {isLoading ? <Skeleton width={"150px"} /> : localize("configuration.mac-address-label")}
                        </Typography>
                        <Typography component="div" variant="body1" color="text" sx={{ whiteSpace: "pre-line" }}>
                            {isLoading ? <Skeleton width={"200px"} /> : getMacAddress(alarmSystem).toUpperCase()}
                        </Typography>
                    </Box>
                    <ChangableField
                        isLoading={isLoading}
                        label={localize("configuration.address-label")}
                        data={renderAddress()}
                        onSubmit={() => patchAlarmSystemMutation.mutate({
                            address: mutableAlarm.address,
                            zip_code: mutableAlarm.zip_code,
                            city: mutableAlarm.city,
                            country: mutableAlarm.country,
                            local_office: mutableAlarm.local_office
                        })}
                        popoverFormData={
                            <Stack>
                                <EBLTextField
                                    variant="outlined"
                                    label={localize("address.address")}
                                    id="address"
                                    value={mutableAlarm.address}
                                    onChange={e => setMutableAlarm({ ...mutableAlarm, address: e.target.value })}
                                    inputProps={{ maxLength: 100 }}
                                    helperText={!addressIsValid && localize("text-validation.100")}
                                />
                                <EBLTextField
                                    variant="outlined"
                                    label={localize("address.zip-code")}
                                    id="zip"
                                    value={mutableAlarm.zip_code}
                                    onChange={e => setMutableAlarm({ ...mutableAlarm, zip_code: e.target.value })}
                                    inputProps={{ maxLength: 16 }}
                                    helperText={`${mutableAlarm.zip_code?.length} / 16`}
                                />
                                <EBLTextField
                                    variant="outlined"
                                    label={localize("address.city")}
                                    id="city"
                                    value={mutableAlarm.city}
                                    onChange={e => setMutableAlarm({ ...mutableAlarm, city: e.target.value })}
                                    inputProps={{ maxLength: 150 }}
                                    helperText={!cityIsValid && localize("text-validation.150")}
                                />
                                <EBLTextField
                                    variant="outlined"
                                    label={localize("address.country")}
                                    id="country"
                                    value={mutableAlarm.country}
                                    onChange={e => setMutableAlarm({ ...mutableAlarm, country: e.target.value })}
                                    inputProps={{ maxLength: 74 }}
                                    helperText={!countryIsValid && localize("text-validation.74")}
                                />
                            </Stack>
                        }
                        popoverAction={
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                {localize("configuration.update-address")}
                            </Button>
                        }
                        hasPermission={hasPermission("firealarmsystem", "change_firealarmsystem")}
                    />
                    <Box>
                        <Typography variant="overline" color="primary" sx={{ whiteSpace: "pre-line" }}>
                            {isLoading ? <Skeleton width={"150px"} /> : localize("configuration.gateway-connection-status")}
                        </Typography>
                        <Typography component="div" variant="body1" color="text" sx={{ whiteSpace: "pre-line" }}>
                            {isLoading ? <Skeleton width={"200px"} /> : renderStatus()}
                        </Typography>
                    </Box>
                    {activeGateway?.gw_version ?
                        <Box>
                            <Typography variant="overline" color="primary" sx={{ whiteSpace: "pre-line" }}>
                                {isLoading ? <Skeleton width={"150px"} /> : localize("configuration.gateway-version")}
                            </Typography>
                            <Typography component="div" variant="body1" color="text" sx={{ whiteSpace: "pre-line" }}>
                                {isLoading ? <Skeleton width={"200px"} /> : activeGateway?.gw_version}
                            </Typography>
                        </Box>
                        : ""}
                    <Box>
                        <Typography variant="overline" color="primary" sx={{ whiteSpace: "pre-line" }}>
                            {isLoading ? <Skeleton width={"150px"} /> : localize("configuration.time_stamps")}
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Typography variant="body2" align="left">{localize("configuration.gateway")}</Typography>
                                <Typography sx={{ wordBreak: 'break-word' }} align="left">{formatDatetimeToHoursAndMinutes(activeGateway?.gateway_current_time)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" align="left">{localize("configuration.central_unit")}</Typography>
                                <Typography sx={{ wordBreak: 'break-word' }} align="left">{formatDatetimeToHoursAndMinutes(alarmSystem?.central_station_current_time)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" align="left">{localize("configuration.eblcloud")}</Typography>
                                <Typography sx={{ wordBreak: 'break-word' }} align="left">{formatDatetimeToHoursAndMinutes(alarmSystem?.server_current_time)}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Typography variant="overline" color="primary" sx={{ whiteSpace: "pre-line" }}>
                            {isLoading ? <Skeleton width={"150px"} /> : localize("configuration.gateway_timezone")}
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Typography sx={{ wordBreak: 'break-word' }} align="left">{activeGateway?.gateway_timezone ? activeGateway?.gateway_timezone : "-"}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    sx={{
                        borderRight: (theme) => ({
                            lg: `1px solid ${theme.palette.divider}`
                        }),
                        borderTop: (theme) => ({
                            xs: `1px solid ${theme.palette.divider}`,
                            sm: "none"
                        }),
                        padding: {
                            md: "0 1.5em",
                            xs: "1.5em 0",
                        }
                    }}>
                    <Typography variant="h4" gutterBottom>
                        {localize("configuration.alarm-system-description")}
                    </Typography>
                    <ChangableField
                        isLoading={isLoading}
                        label={localize("configuration.description-label")}
                        data={alarmSystem?.description}
                        onSubmit={() => patchAlarmSystemMutation.mutate({
                            description: mutableAlarm.description,
                            local_office: mutableAlarm.local_office
                        })}
                        popoverFormData={
                            <EBLTextField
                                multiline
                                fullWidth
                                maxRows={10}
                                variant="outlined"
                                id="description"
                                inputProps={{ maxLength: 250 }}
                                helperText={`${mutableAlarm.description?.length} / 250`}
                                value={mutableAlarm.description}
                                onChange={e => setMutableAlarm({ ...mutableAlarm, description: e.target.value })}
                            />
                        }
                        popoverAction={
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                {localize("configuration.update-description")}
                            </Button>
                        }
                        hasPermission={hasPermission("firealarmsystem", "change_firealarmsystem")}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    sx={{
                        borderTop: (theme) => ({
                            xs: `1px solid ${theme.palette.divider}`,
                            sm: "none"
                        }),
                        padding: {
                            md: "0 1.5em",
                            xs: "1.5em 0",
                        }
                    }}>
                    <Typography variant="h4" gutterBottom>
                        {localize("configuration.alarm-system-contacts")}
                    </Typography>
                    <ListContactPersons alarmSystemId={alarmSystemId} hasPermission={hasPermission("firealarmcontactperson", "add_firealarmcontactperson")} />
                </Grid>
                <Grid item xs={12} padding="1.5em 0">
                    {alarmSystem && (
                        <AlarmSystemActions fireAlarmSystem={alarmSystem} />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default AlarmSystemConfigurationView;