import { PrivacyTip, WarningAmber, Map, ForwardToInbox, SwapHoriz } from "@mui/icons-material";
import { CardActionArea, CardContent, Stack, Typography } from "@mui/material";
import { AlarmSystemBadge } from "components/StyledComponents/Card";
import { ActiveFireAlarmIcon, DisablementIcon, DoorOpenIcon, InterlockingIcon, TechWarnIcon, WebFunctionDisablementIcon } from "components/svg/DeviationIcons";
import ServiceSignalIcon from "components/svg/ServiceSignal";
import { IFireAlarmSystem, TDeviationType } from "interfaces/fireAlarmSystem";
import React from "react";
import { useNavigate } from "react-router-dom";

interface AlarmSystemCardProps {
    fireAlarmSystem: IFireAlarmSystem;
    children?: React.ReactNode;
}

const getSubjectIcon = (key: TDeviationType): React.ReactNode => {
    switch (key) {
        case "pre_warnings":
            return <PrivacyTip color="primary" />;
        case "faults":
            return <WarningAmber color="primary" />
        case "disablements":
            return <DisablementIcon color="primary" />
        case "tech_warnings":
            return <TechWarnIcon color="primary" />
        case "interlocking":
            return <InterlockingIcon color="primary" />
        case "door_open":
            return <DoorOpenIcon color="primary" />
        case "test_zones":
            return <Map color="primary" />
        case "service_signals":
            return <ServiceSignalIcon color="primary" />
        case "output_activations":
            return <ForwardToInbox color="primary" />
        case "web_disablement":
            return <WebFunctionDisablementIcon color="primary" />
        case "communication_error":
            return <SwapHoriz color="primary" />
        default:
            return null;
    }
};

const getBadgeIcon = (key: TDeviationType, badgeCount: number): React.ReactNode => {
    return (
        <AlarmSystemBadge 
            badgeContent={badgeCount}
            color="secondary"
            anchorOrigin={{ vertical: 'top', horizontal: 'left',}}
        >
            {getSubjectIcon(key)}
        </AlarmSystemBadge>
    );
};

const AlarmSystemCardContent: React.FC<AlarmSystemCardProps> = ({ fireAlarmSystem, children}) => {   
    const navigate = useNavigate();

    const onNavigate = (): void => {
        navigate(`/${fireAlarmSystem.local_office}/status/${fireAlarmSystem.id}`);
    };

    const getCardIcons = (): React.ReactNode[] => {
        if (fireAlarmSystem.deviations.fire_alarms.length){
            return [
                <ActiveFireAlarmIcon />
            ];
        }

        const icons = [];
        for (const key in fireAlarmSystem.deviations) {
            const subjectCount = fireAlarmSystem.deviations[key as TDeviationType].length;
            if (subjectCount) {
                icons.push(getBadgeIcon(key as TDeviationType, subjectCount))
            }
        }
        return icons.length ? icons : [];
    };

    return (
        <CardActionArea onClick={onNavigate}>
            <CardContent sx={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", textAlign: 'center'}}>
                <Stack direction="row">
                    {getCardIcons().map((icon, index) => 
                        <React.Fragment key={index}>
                            {icon}
                        </React.Fragment>
                    )}
                </Stack>
                <Typography style={{wordBreak: 'break-word'}} gutterBottom variant="h2" component="div" color={"primary"}>
                    {fireAlarmSystem.name}
                </Typography>
                <Typography style={{wordBreak: 'break-word'}} gutterBottom variant="subtitle2" fontWeight="normal" component="div" color={"primary"}>
                    {fireAlarmSystem.local_office_details?.name}
                </Typography>
                {children}
            </CardContent>
        </CardActionArea>
    );
};

export default AlarmSystemCardContent;