import { useMutation, useQueryClient } from "@tanstack/react-query";
import { authenticateOTP, fetchToken } from "adapters/auth";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useClientConfig from "./useClientConfig";

interface UseAuthenticateMethods {
    authenticateOTPUser: (email: string, password: string, token: string) => void;
    isLoading: boolean;
}

const useAuthenticate = (): UseAuthenticateMethods => {
    const queryClient = useQueryClient();
    const { openSnack } = React.useContext(SnackBarContext);
    const { localize } = React.useContext(LocaleContext);
    const navigate = useNavigate();
    const location = useLocation();


    const clientConfig = useClientConfig();


    const otpApp = clientConfig.data?.data.applications.find((app => app.name === "ebl_otp"));

    const createToken = useMutation({
        mutationFn: fetchToken,
        onSuccess: res => {
            sessionStorage.setItem("accessToken", JSON.stringify(res.data));
            sessionStorage.setItem("client_id", JSON.stringify(otpApp?.client_id));
            if (location.pathname.split("/")[2] !== "notifications") {
                navigate("/");
            }
            queryClient.invalidateQueries({ queryKey: ["clientConfiguration"] });
        },
        onError: () => {
            openSnack(localize("login.invalid-credentials"), "error");
        }
    });

    const authenticateOTPMutation = useMutation({
        mutationFn: authenticateOTP,
        onSuccess: otpRes => {
            createToken.mutate({
                "client_id": otpApp?.client_id || "",
                "grant_type": "authorization_code",
                "code": otpRes.data.code,
                "code_verifier": otpRes.data.code_verifier,
                "redirect_uri": otpApp?.redirect_uris.split(",")[0] || ""
            });
        },
        onError: () => {
            openSnack(localize("login.invalid-credentials"), "error");
        }
    });

    const authenticateOTPUser = (email: string, password: string, token: string): void => {
        authenticateOTPMutation.mutate({
            email: email,
            password: password,
            token: token,
            client_id: otpApp?.client_id || ""
        });
    };

    const isLoading = authenticateOTPMutation.isPending || createToken.isPending;

    return {
        authenticateOTPUser,
        isLoading
    }
};

export default useAuthenticate;