import { Add } from "@mui/icons-material";
import { Button, CircularProgress, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createTOTPDevice, ITOTPDevice, verifyTOTPDeviceToken } from "adapters/auth";
import SetupTOTP from "components/authenticate/SetupTOTP";
import VerifyTOTPForm from "components/authenticate/VerifyOtpForm";
import { BaseDialog } from "components/StyledComponents/Dialog";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import ConfirmDeleteTOTPDevice from "./ConfirmDeleteTOTPDevice";
import ListDevices from "./ListDevices";
import useFormValidation from "hooks/useFormValidation";
import { AxiosError } from "axios";
import { SnackBarContext } from "contexts/SnackBarContext";
import { getFieldErrorMessage } from "utils/getFieldErrorMessage";


const TwoFAInformation: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const queryClient = useQueryClient();
    const [deviceToDelete, setSelectedDeviceToDelete] = React.useState<ITOTPDevice | null>(null);
    const [configUrlToSetup, setConfigUrlToSetup] = React.useState("");
    const [newDeviceName, setNewDeviceName] = React.useState("");
    const [isSettingUpNewDevice, setIsSettingUpNewDevice] = React.useState(false);
    const { fieldIsNotEmpty } = useFormValidation();
    const { openSnack } = React.useContext(SnackBarContext);

    const onDeviceError = (e: AxiosError): void => {
        const fieldError = getFieldErrorMessage(e)
        if (fieldError) {
            openSnack(`${fieldError.message}`, "error");
        }
        else {
            openSnack(`${e.response?.data as Record<string, string[]>}`, "error");
        }
    }

    const createDeviceMutation = useMutation({
        mutationFn: createTOTPDevice,
        onSuccess: (data) => {
            setConfigUrlToSetup(data.data.config_url);
            queryClient.invalidateQueries({ queryKey: ["devices"] });
        },
        onError: (err: AxiosError) => {
            onDeviceError(err);
        }
    });

    const createDeviceTokenMutation = useMutation({
        mutationFn: verifyTOTPDeviceToken,
        onSuccess: (data) => {
            setIsSettingUpNewDevice(false);
            setConfigUrlToSetup("");
            queryClient.invalidateQueries({ queryKey: ["devices"] });
        }
    });


    const handleCloseDelete = () => setSelectedDeviceToDelete(null);

    const handleCloseDeviceCreation = (): void => {
        setIsSettingUpNewDevice(false);
        setConfigUrlToSetup("");
        setNewDeviceName("");
    };

    const onSelectForConfiguration = (config_url: string) => {
        setConfigUrlToSetup(config_url)
        setIsSettingUpNewDevice(true);
    };
    const nameIsValid = newDeviceName !== undefined && newDeviceName.length < 64;


    return (
        <>
            <Typography variant="h4" color="text" gutterBottom>
                {localize("account.2FA-information")}
            </Typography>
            <ListDevices
                onSelectForConfiguration={onSelectForConfiguration}
                onSelectForDeletion={(device) => setSelectedDeviceToDelete(device)}
            />
            <Button
                sx={{ mt: "2em" }}
                variant="contained"
                endIcon={<Add />}
                onClick={() => setIsSettingUpNewDevice(true)}>
                {localize("account.setup-new-device")}
            </Button>
            <BaseDialog open={!!deviceToDelete} onClose={handleCloseDelete}>
                {deviceToDelete && <ConfirmDeleteTOTPDevice device={deviceToDelete} onClose={handleCloseDelete} />}
            </BaseDialog>
            <BaseDialog open={isSettingUpNewDevice} onClose={handleCloseDeviceCreation}>
                <DialogTitle>
                    {localize("account.setup-device")}
                </DialogTitle>
                <DialogContent>
                    {!configUrlToSetup ? (
                        <Stack >
                            <EBLTextField
                                variant="outlined"
                                label={localize("account.new-device-name")}
                                id="name"
                                value={newDeviceName}
                                onChange={(e) => setNewDeviceName(e.target.value)}
                                inputProps={{ maxLength: 64 }}
                                helperText={!nameIsValid && localize("text-validation.64")} />
                            <Stack gap={1}>
                                <Button
                                    disabled={!fieldIsNotEmpty(newDeviceName)}
                                    variant="contained"
                                    endIcon={createDeviceMutation.isPending && <CircularProgress color="secondary" size={"1rem"} />}
                                    onClick={() => createDeviceMutation.mutate(newDeviceName)}
                                >
                                    {localize("generics.continue")}
                                </Button>
                                <Button variant="outlined" onClick={handleCloseDeviceCreation}>
                                    {localize("generics.cancel")}
                                </Button>
                            </Stack>
                        </Stack>
                    ) : (
                        <SetupTOTP
                            onCancel={handleCloseDeviceCreation}
                            configUrl={configUrlToSetup}
                            verifyForm={
                                <VerifyTOTPForm
                                    onCancel={handleCloseDeviceCreation}
                                    onVerify={(token) => createDeviceTokenMutation.mutate(token)}
                                    loading={createDeviceTokenMutation.isPending}
                                />
                            }
                        />
                    )}
                </DialogContent>
            </BaseDialog>
        </>
    )
};

export default TwoFAInformation;