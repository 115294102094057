import { Box, CircularProgress } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logout } from "adapters/auth";
import React from "react";
import { useNavigate } from "react-router-dom";


const LogoutView: React.FC = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const logoutMutation = useMutation({
        mutationKey: ["logout"],
        mutationFn: logout,
        onSuccess: async () => {
            sessionStorage.removeItem("accessToken");
            await queryClient.invalidateQueries({ queryKey: ["clientConfiguration"] });
            navigate("/");
            queryClient.resetQueries();
            sessionStorage.clear()
        }
    });

    React.useEffect(() => {
        logoutMutation.mutate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box height="50vh" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
        </Box>
    );
};

export default LogoutView;