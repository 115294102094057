import { Button, Grid, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LocaleContext } from "contexts/LocaleContext";
import React, { MouseEventHandler } from "react";
import DownloadEventLog from "./forms/DownloadEventLog";

interface IEventTableFotterRowProps {
    alarmSystemId: string, 
    totalCount: number, 
    handleUpdateQuery: MouseEventHandler<HTMLButtonElement>,
    order: string,
    orderBy: string
}


const EventTableFotterRow: React.FC<IEventTableFotterRowProps> = ({ alarmSystemId, totalCount, handleUpdateQuery, order, orderBy }) => {
    const { localize } = React.useContext(LocaleContext);
    const [showPopup, setShowPopup] = React.useState(false);

    const handleClick = () => {
        setShowPopup(!showPopup);
    };

    return (<>
        <Grid container>
            <Grid item xs={4}>
                <Button onClick={() => handleClick()}
                    variant="contained"
                    sx={{
                        marginTop: "1.0em",
                        marginRight: "8px"
                    }}>
                    <Typography variant="button">
                        {localize("events.download")}
                    </Typography>
                    <GetAppIcon fontSize="small" />
                </Button>
                <Button
                    variant="contained"
                    onClick={handleUpdateQuery}
                    sx={{
                        marginTop: "1.0em"
                    }}>
                    <Typography variant="button">
                        {localize("events.update")}
                    </Typography>
                    <RefreshIcon fontSize="small" />
                </Button>
            </Grid>
            <Grid item xs={7}>
                <Typography marginTop="1.0em" align="right">
                    {localize("events.total-items")} {totalCount}
                </Typography>
            </Grid>
        </Grid>
        {showPopup && <DownloadEventLog totalCount={totalCount} alarmSystemId={alarmSystemId} open={true} onClose={() => setShowPopup(false)} order={order} orderBy={orderBy} />}
    </>);
}

export default EventTableFotterRow;