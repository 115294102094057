import { deleteLocalOffice } from "adapters/userManagement";
import { Button, CircularProgress, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import { ILocalOffice } from "interfaces/userManagement";
import React from "react";
import { IErrorWithDetail } from "api";
import { SnackBarContext } from "contexts/SnackBarContext";


interface IConfirmDeleteLocalOfficeProps {
    onClose: () => void;
    local_office: ILocalOffice;
}

const ConfirmDeleteLocalOffice: React.FC<IConfirmDeleteLocalOfficeProps> = ({ local_office, onClose }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const queryClient = useQueryClient();
    const localOfficeDeleteMutation = useMutation({
        mutationFn: deleteLocalOffice,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["local-offices"] });
            onClose();
        },
        onError: (error: IErrorWithDetail) => {
            openSnack(error.response.data.detail, "error");
        }
    });

    const onConfirmDelete = () => localOfficeDeleteMutation.mutate(local_office.id);

    return (
        <>
            <DialogTitle color="primary">
                {localize("user-management.confirm-delete-local-office-title")}
            </DialogTitle>
            <DialogContent
                sx={{
                    xs: {
                        width: "250px"
                    },
                    sm: {
                        width: "370px"
                    }
                }}>
                <Typography gutterBottom>
                    {localize("user-management.confirm-delete-local-office-content")}
                </Typography>
                <Typography gutterBottom>
                    {localize("user-management.confirm-delete-local-office-content2")}
                </Typography>
            </DialogContent>
            <BaseDialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {localize("user-management.cancel")}
                </Button>
                <Button
                    variant="contained"
                    disabled={localOfficeDeleteMutation.isPending}
                    endIcon={localOfficeDeleteMutation.isPending && <CircularProgress size={"1rem"} />}
                    onClick={onConfirmDelete}
                >
                    {localize("user-management.confirm")}
                </Button>
            </BaseDialogActions>
        </>
    )

};

export default ConfirmDeleteLocalOffice;