import {
    useMediaQuery,
    useTheme
} from "@mui/material";
import { StatusAccordion } from "components/StatusAccordion";
import { DisablementIcon } from "components/svg/DeviationIcons";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import { IDeviationStatusHeader, DeviationStatusTable, DeviationStatusStack } from "components/status/DeviationInfo";
import { IStatusAccordionProps } from "interfaces/statusAccordionProps";

const disablementHeaders: IDeviationStatusHeader[] = [
    {
        locale: "status.time-title"
    },
    {
        locale: "status.text-title",
        
    }
];


const DisablementStatusAccordion: React.FC<IStatusAccordionProps> = ({alarmSystem, formatDate}) => {
    const { localize } = React.useContext(LocaleContext);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const rows = alarmSystem?.deviations.disablements.map(dev => [
        formatDate(dev.payload.timestamp),
        dev.payload.text || "-",
    ]);

    return (
        <StatusAccordion
            icon={<DisablementIcon />}
            numberOfDeviations={alarmSystem?.deviations.disablements.length}
            loading={!!!alarmSystem}
            title={localize("status.disablement")}
        >
            {rows && (isLargeScreen ? (
                <DeviationStatusTable
                    headers={disablementHeaders}
                    rows={rows}
                />
            ) : rows.map((row, rowIndex) => (
                <DeviationStatusStack headers={disablementHeaders} key={rowIndex} data={row} />
            )))}
        </StatusAccordion>
    );
};

export default DisablementStatusAccordion;