import React from "react"
import { Alert, AlertColor, AlertTitle, Snackbar, SnackbarOrigin } from "@mui/material";


type TSnack = {
    title?: string;
    message: string;
    severity: AlertColor;
    anchorOrigin: SnackbarOrigin;
    autoHide: boolean;
    onClick?: () => void;
}

const defaultAnchor = { vertical: 'top', horizontal: 'center' } as SnackbarOrigin;

const defaultSnack = {
    message: "",
    severity: "success",
    anchorOrigin: defaultAnchor
} as TSnack;

interface ISnackBarContextState {
    openSnack: (message: string, severity: AlertColor, title?: string, onClick?: () => void, autoHide?: boolean, anchorOrigin?: SnackbarOrigin) => void;
}

export const SnackBarContext = React.createContext<ISnackBarContextState>({} as ISnackBarContextState);


const SnackBarProvider: React.FC<{children?: React.ReactNode}> = ({children}) => {
    const [currentSnack, setSnack] = React.useState<TSnack>(defaultSnack);
    const [open, setOpen] = React.useState(false);

    const openSnack = React.useCallback((
        message: string,
        severity: AlertColor,
        title?: string,
        onClick?: () => void,
        autoHide: boolean = true,
        anchorOrigin: SnackbarOrigin = defaultAnchor
    ): void => {
        setSnack({...currentSnack, message, severity, anchorOrigin, title, onClick, autoHide});
        setOpen(true);
    }, [currentSnack]);

    return (
        <SnackBarContext.Provider value={{ openSnack }}>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={currentSnack.autoHide ? 6000 : null}
                anchorOrigin={currentSnack.anchorOrigin}
                sx={{minWidth: "300px"}}
            >
                <Alert
                    onClick={currentSnack.onClick}
                    variant="outlined"
                    sx={{minWidth: "300px", backgroundColor: (theme) => theme.palette.background.default}}
                    severity={currentSnack.severity}
                    onClose={() => setOpen(false)}
                >
                    {currentSnack.title && (<AlertTitle>{currentSnack.title}</AlertTitle>)}
                    {currentSnack.message}
                </Alert>
            </Snackbar>
            {children}
        </SnackBarContext.Provider>
    )
}

export default SnackBarProvider;