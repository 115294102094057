import { Button, DialogTitle, Grid, Typography } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { LocaleContext } from "contexts/LocaleContext";
import React, { MouseEventHandler } from "react";
import { useHasPermission } from "hooks/useHasPermission";
import { SnackBarContext } from "contexts/SnackBarContext";
import { useQueryClient } from "@tanstack/react-query";
import { BaseDialog } from "components/StyledComponents/Dialog";
import CreateJournalEntryForm from "./forms/CreateJournalEntryForm";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import GetAppIcon from '@mui/icons-material/GetApp';
import DownloadJournalLog from "./forms/DownloadJournalLog";


const JournalTableFotterRow: React.FC<{ alarmSystemId: string | undefined, totalCount: number, handleUpdateQuery: MouseEventHandler<HTMLButtonElement>, order: string, orderBy: string }> = ({ alarmSystemId, totalCount, handleUpdateQuery, order, orderBy }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const queryClient = useQueryClient();
    const [showPopup, setShowPopup] = React.useState(false);

    const handleClick = () => {
        setShowPopup(!showPopup);
    };
    const { hasPermission } = useHasPermission("localoffice");
    const [CreateJournalEntryOpen, setCreateJournalEntryOpen] = React.useState(false);
    const onCloseJournalEntry = () => setCreateJournalEntryOpen(false);


    const onCreateJournalEntrySuccess = (): void => {
        setCreateJournalEntryOpen(false);
        openSnack(localize("journal.journal-entry-created-successfully"), "success")
        queryClient.invalidateQueries({ queryKey: [`journal-${alarmSystemId}`, order, orderBy] })
    };

    const onCreateJournalEntryFailure = (): void => {
        openSnack(localize("journal.journal-entry-failed-to-create"), "error")
    };


    return (<>
        <Grid container>
            <Grid item xs={4}>
                {hasPermission("journal", "add_journal") && (
                    <Button
                        variant="contained"
                        sx={{
                            marginTop: "1.0em",
                            marginRight: "8px"
                        }}
                        onClick={() => setCreateJournalEntryOpen(true)}
                    >
                        {localize("journal.create-journal-entry")}
                        <CreateOutlinedIcon fontSize="small" />
                    </Button>
                )}
                <Button onClick={() => handleClick()}
                    variant="contained"
                    sx={{
                        marginTop: "1.0em",
                        marginRight: "8px"
                    }}>
                    <Typography variant="button">
                        {localize("journal.download")}
                    </Typography>
                    <GetAppIcon fontSize="small" />
                </Button>
                <BaseDialog open={CreateJournalEntryOpen} onClose={onCloseJournalEntry}>
                    <DialogTitle color="primary">
                        {localize("journal.create-journal-entry")}
                    </DialogTitle>
                    <CreateJournalEntryForm
                        onSuccess={onCreateJournalEntrySuccess}
                        onError={onCreateJournalEntryFailure}
                        alarmSystemId={alarmSystemId}
                        buttons={[
                            <Button variant="outlined" onClick={onCloseJournalEntry}>
                                {localize("generics.cancel")}
                            </Button>,
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                {localize("generics.confirm")}
                            </Button>
                        ]
                        }
                    />
                </BaseDialog>
                <Button
                    variant="contained"
                    onClick={handleUpdateQuery}
                    sx={{
                        marginTop: "1.0em"
                    }}>
                    <Typography variant="button">
                        {localize("journal.update")}
                    </Typography>
                    <RefreshIcon fontSize="small" />
                </Button>
            </Grid>
            <Grid item xs={7}>
                <Typography marginTop="1.0em" align="right">
                    {localize("journal.total-items")} {totalCount}
                </Typography>
            </Grid>
        </Grid>
        {showPopup && <DownloadJournalLog totalCount={totalCount} alarmSystemId={alarmSystemId} open={true} onClose={() => setShowPopup(false)} order={order} orderBy={orderBy} />}
    </>);
}

export default JournalTableFotterRow;