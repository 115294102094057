import { Sync } from "@mui/icons-material";
import { CircularProgress, Fab, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { BaseDialog } from "components/StyledComponents/Dialog";
import { AutoRefreshIcon } from "components/svg/AutoRefreshIcon";
import useNotificationsServiceWorker from "hooks/useNotificationSW";
import React from "react";
import AcceptNotificationsDialog from "./AcceptNotificationsDialog";


interface IRefetchButton {
    isRefetching: boolean;
    refetchAlarms: () => void;
}

const RefreshButton: React.FC<IRefetchButton> = ({ isRefetching, refetchAlarms }) => {
    const [acceptNotificationDialog, setAcceptNotificationsDialog] = React.useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const { navigatorPermissionState } = useNotificationsServiceWorker();

    const onClick = async (): Promise<void> => {
        if (navigatorPermissionState === "prompt") {
            setAcceptNotificationsDialog(true);
        }
        refetchAlarms();
    };


    const onCloseDialog = () => setAcceptNotificationsDialog(false);

    const renderButtonContent = (): React.ReactNode => {
        return isRefetching ? <CircularProgress size="24px" color="inherit" /> : (
            navigatorPermissionState === "granted"
                ? <AutoRefreshIcon />
                : <Sync />
        );
    };

    const renderButton = (): React.ReactNode => {
        if (matches) {
            return (
                <Fab
                    color="primary"
                    size="medium"
                    onClick={onClick}
                    sx={{
                        position: "fixed",
                        bottom: "5em",
                        right: "16px"
                    }}>
                    {renderButtonContent()}
                </Fab>
            );
        }
        return (
            <IconButton color="primary" onClick={onClick}>
                {renderButtonContent()}
            </IconButton>
        );
    };


    return (
        <>
            {renderButton()}
            <BaseDialog open={acceptNotificationDialog} onClose={onCloseDialog}>
                <AcceptNotificationsDialog onClose={onCloseDialog} />
            </BaseDialog>
        </>
    )
};

export default RefreshButton;