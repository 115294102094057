import {
    useMediaQuery,
    useTheme
} from "@mui/material";
import { StatusAccordion } from "components/StatusAccordion";
import { DoorOpenIcon } from "components/svg/DeviationIcons";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import { DeviationStatusStack, DeviationStatusTable, IDeviationStatusHeader } from "components/status/DeviationInfo";
import { IStatusAccordionProps } from "interfaces/statusAccordionProps";

const doorOpenHeaders: IDeviationStatusHeader[] = [
    {
        locale: "status.time-title"
    },
    {
        locale: "status.control-unit-title",
        alignment: "right"
    },
    {
        locale: "status.fbp-title",
        alignment: "right"
    }
];


const DoorOpenStatusAccordion: React.FC<IStatusAccordionProps> = ({alarmSystem, formatDate}) => {
    const { localize } = React.useContext(LocaleContext);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const rows = alarmSystem?.deviations.door_open.map(dev => [
        formatDate(dev.payload.timestamp),
        dev.payload.data.parameters.unit?.toString() || "-",
        dev.payload.data.parameters.fbp?.toString() || "-"
    ]);

    return (
        <StatusAccordion
            icon={<DoorOpenIcon />}
            numberOfDeviations={alarmSystem?.deviations.door_open.length}
            loading={!!!alarmSystem}
            title={localize("status.door-open")}
        >
            {rows && (isLargeScreen ? (
                <DeviationStatusTable
                    headers={doorOpenHeaders}
                    rows={rows}
                />
            ) : rows.map((row, rowIndex) => (
                <DeviationStatusStack headers={doorOpenHeaders} key={rowIndex} data={row} />
            )))}
        </StatusAccordion>
    );
};

export default DoorOpenStatusAccordion;