import { ReadMore } from "@mui/icons-material";
import { Box, IconButton, List, ListItem, ListItemText, Skeleton } from "@mui/material";
import { keepPreviousData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { fetchUsers } from "adapters/userManagement";
import { BaseDialog } from "components/StyledComponents/Dialog";
import { IUser } from "interfaces/userManagement";
import React from "react";
import { useInView } from "react-intersection-observer";
import { isLastInArray } from "utils/arrays";
import GetUserDetails from "./GetUserDetails";
import theme from "styling/theme";
import InfiniteScrollList from "components/InfiniteScrollList";

interface IListUsersProps {
    context_object_id?: string | null;
}

const ListUsers: React.FC<IListUsersProps> = ({ context_object_id }) => {
    const { ref, inView } = useInView()
    const [selectedUserForDetails, setUserForDetails] = React.useState<IUser | null>(null);
    const queryClient = useQueryClient();

    const userQuery = useInfiniteQuery({
        queryKey: ["users", context_object_id],
        queryFn: async ({ pageParam }) => {
            const params = {
                page: pageParam,
                org_id: context_object_id
            };
            const res = await fetchUsers({ params: params })
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    React.useEffect(() => {
        if (inView) {
            userQuery.fetchNextPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    const handleClose = (): void => {
        setUserForDetails(null);
        queryClient.invalidateQueries({ queryKey: ["users"] });
    };

    const renderList = (): React.ReactNode => {

        return (
            <Box height={"34.8vh"}>
                <InfiniteScrollList
                    fetchNextPage={userQuery.fetchNextPage}
                    isFetchingNextPage={userQuery.isFetchingNextPage}
                    hasNextPage={!!userQuery.hasNextPage}
                    isLoading={userQuery.isLoading}
                >

                    {userQuery.data?.pages.map((group, i) => group.data.map((user, j) => (
                        <ListItem
                            key={user.id}
                            dense
                            disablePadding
                            ref={isLastInArray(userQuery.data?.pages, i) && isLastInArray(group.data, j) ? ref : null}
                            style={{
                                backgroundColor: selectedUserForDetails?.id === user.id ?
                                    theme.palette.background.paper : theme.palette.background.default,
                                width: "100%",
                                borderRadius: "6px"
                            }}
                        >
                            <ListItemText style={{ wordBreak: "break-all" }} primaryTypographyProps={{ sx: { paddingRight: '80px' }, variant: "body1" }}>
                                {user.email}
                            </ListItemText>
                            {(
                                <>
                                    <IconButton onClick={() => setUserForDetails(user)}>
                                        <ReadMore />
                                    </IconButton>
                                </>
                            )}
                        </ListItem>
                    )))}
                    {userQuery.isFetchingNextPage && ref != null && (
                        <>
                            <ListItem dense disablePadding key={1}>
                                <Skeleton width={"100%"} />
                            </ListItem>
                        </>
                    )}
                </InfiniteScrollList>
            </Box>
        )
    };

    return (
        <>
            <List disablePadding sx={{ maxWidth: "500px" }}>
                {renderList()}
            </List>
            {userQuery.isFetchingNextPage && (
                <Skeleton />
            )}
            <BaseDialog open={!!selectedUserForDetails} onClose={handleClose} maxWidth={"xs"} fullWidth>
                {selectedUserForDetails && <GetUserDetails user={selectedUserForDetails} onClose={handleClose} org_id={context_object_id} />}
            </BaseDialog>
        </>
    )
};

export default ListUsers;