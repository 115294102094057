import { Box, Grid, Link, Skeleton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchNotificationPreferences, fetchNotificationTriggers } from "adapters/notifications";
import NotificationPreference from "components/notifications/NotificationPreference";
import { LocaleContext } from "contexts/LocaleContext";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import { INotificationTriggerPreferencesMapped } from "interfaces/notifications";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const NotificationsView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { alarmSystemId, alarmSystem, isLoading: isLoadingSystem } = useGetAlarmSystem();

    const notificationTriggersQuery = useQuery({
        queryKey: ["notification-triggers"],
        queryFn: fetchNotificationTriggers
    });

    const notificationPreferencesQuery = useQuery({
        queryKey: [`notification-preferences-${alarmSystemId}`],
        queryFn: async () => fetchNotificationPreferences({ params: { action_object_object_id: alarmSystemId } }),
        enabled: !!alarmSystemId && !!notificationTriggersQuery.data,
    });

    const mappedNotificationPreferences = React.useMemo<INotificationTriggerPreferencesMapped[]>(() => {
        if (!notificationTriggersQuery.data || !notificationPreferencesQuery.data) {
            return [];
        }

        return notificationTriggersQuery.data?.data.map(trigger => {
            return {
                ...trigger,
                active_preferences: notificationPreferencesQuery.data.data.filter(pref => {
                    return pref.trigger === trigger.id
                })
            };
        });
    }, [notificationPreferencesQuery.data, notificationTriggersQuery.data]);

    const isLoading = notificationPreferencesQuery.isLoading || notificationPreferencesQuery.isLoading;

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" sx={{
                    marginBottom: "1.375em",
                    color: (theme) => theme.palette.primary.main,
                    display: { xs: "none", sm: "inherit" }
                }}>
                    {isLoadingSystem ? <Skeleton width="10rem" /> : `${alarmSystem?.name} / ${localize("navigation.settings")} / ${localize("navigation.notifications")}`}
                </Typography>
            </Box>
            <Grid container maxWidth="sm" spacing="1em">
                {isLoading ? (
                    <>
                        <NotificationPreference />
                        <NotificationPreference />
                        <NotificationPreference />
                        <NotificationPreference />
                    </>
                ) : mappedNotificationPreferences.map(preference => (
                    <NotificationPreference key={preference.id} preference={preference} />
                ))}
            </Grid>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.grey[600] }}>
                {localize("notifications.preferences-config-explanation1")}
                <Link component={RouterLink} to="/account">
                    {localize("notifications.preferences-config-explanation2")}
                </Link>
            </Typography>
        </>
    );
};

export default NotificationsView;