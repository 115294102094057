import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchProfile } from "adapters/auth";
import { createNotificationPreference, deleteNotificationPreference } from "adapters/notifications";
import { AxiosError, AxiosResponse } from "axios";
import { EBLSwitch } from "components/StyledComponents/Switch";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import { INotificationPreference, INotificationTriggerPreferencesMapped, TNotificationBackend } from "interfaces/notifications";
import React from "react";
import { useParams } from "react-router-dom";

interface INotificationPreferenceProps {
    preference?: INotificationTriggerPreferencesMapped;
}

const NotificationPreference: React.FC<INotificationPreferenceProps> = ({ preference }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);

    const params = useParams();
    const alarmSystemId = params.id;
    const queryClient = useQueryClient();

    const profileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: fetchProfile
    });

    const createNotificationPreferenceMutation = useMutation({
        mutationKey: ["create-preference"],
        mutationFn: createNotificationPreference,
        onMutate: async (pref: Omit<INotificationPreference, "id">) => {

            const addTemporaryPreference = (prev: AxiosResponse<INotificationPreference[]>, tempPref: INotificationPreference) => {
                return { ...prev, data: [...prev.data, tempPref] };
            };

            await queryClient.cancelQueries({ queryKey: [`notification-preferences-${alarmSystemId}`] });

            const previousNotificationPreferences = queryClient.getQueryData(
                [`notification-preferences-${alarmSystemId}`]
            ) as AxiosResponse<INotificationPreference[]>;

            queryClient.setQueryData<AxiosResponse<INotificationPreference[]>>(
                [`notification-preferences-${alarmSystemId}`],
                (old) => old ? addTemporaryPreference(old, { ...pref, id: "temporary-id" }) : old
            )
            return { previousNotificationPreferences }
        },
        onError: (error: AxiosError, variables, context) => {
            if (context?.previousNotificationPreferences) {
                queryClient.setQueryData([`notification-preferences-${alarmSystemId}`], context.previousNotificationPreferences)
            }
            if (error.response) {
                openSnack(error.response.statusText, "error");
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [`notification-preferences-${alarmSystemId}`] })
        },
    });

    const deleteNotificationPreferenceMutation = useMutation({
        mutationKey: ["delete-preference"],
        mutationFn: async (pref: INotificationPreference) => await deleteNotificationPreference(pref.id),
        onMutate: async (pref: INotificationPreference) => {
            const deleteActivePreference = (prev: AxiosResponse<INotificationPreference[]>) => {
                return { ...prev, data: prev.data.filter(x => x.id !== pref.id) };
            };

            await queryClient.cancelQueries({ queryKey: [`notification-preferences-${alarmSystemId}`] });
            const previousNotificationPreferences = queryClient.getQueryData([`notification-preferences-${alarmSystemId}`]) as AxiosResponse<INotificationPreference[]>;

            queryClient.setQueryData<AxiosResponse<INotificationPreference[]>>(
                [`notification-preferences-${alarmSystemId}`],
                (old) => old ? deleteActivePreference(old) : old
            );
            return { previousNotificationPreferences }
        },
        onError: (err: AxiosError, variables, context) => {
            if (context?.previousNotificationPreferences) {
                queryClient.setQueryData([`notification-preferences-${alarmSystemId}`], context.previousNotificationPreferences)
            }
            if (err.response) {
                openSnack(err.response.statusText, "error");
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [`notification-preferences-${alarmSystemId}`] })
        },
    }
    );

    const onChangeSelected = (trigger: INotificationTriggerPreferencesMapped, value: boolean, backend: TNotificationBackend): void => {
        if (value) {
            createNotificationPreferenceMutation.mutate({
                trigger: trigger.id,
                backend: backend,
                action_object_content_type: "firealarmsystem",
                action_object_object_id: alarmSystemId
            });
        } else {
            const pref = trigger.active_preferences.find(pref => pref.backend === backend);
            if (pref) {
                deleteNotificationPreferenceMutation.mutate(pref);
            }
        }
    };


    return (
        <>
            <Grid item xs={12} lg={6}>
                <Typography variant="h4">
                    {preference ? preference.display_name : <Skeleton />}
                </Typography>
            </Grid>
            <Grid mb="1em" item xs={12} lg={6}>
                <Stack direction="row" alignItems="center">
                    <Typography variant="subtitle1" width="50px">
                        {preference ? localize("notifications.email") : <Skeleton />}
                    </Typography>
                    {preference ? (
                        <EBLSwitch
                            checked={preference.active_preferences.some(pref => pref.backend === "email")}
                            onChange={(event) => onChangeSelected(preference, event.target.checked, "email")}
                        />
                    ) : (
                        <Skeleton width="40px" />
                    )}
                    <Typography variant="body1" minWidth="100px" sx={{ display: { xs: "none", md: "inherit" } }}>
                        {(profileQuery.isLoading || !preference) ? <Skeleton /> : profileQuery.data?.data.email}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Typography variant="subtitle1" width="50px">
                        {preference ? localize("notifications.sms") : <Skeleton />}
                    </Typography>
                    {preference ? (
                        <EBLSwitch
                            checked={preference.active_preferences.some(pref => pref.backend === "sms")}
                            onChange={(event) => onChangeSelected(preference, event.target.checked, "sms")}
                        />
                    ) : (
                        <Skeleton width="40px" />
                    )}
                    <Typography variant="body1" minWidth="100px" sx={{ display: { xs: "none", md: "inherit" } }}>
                        {(profileQuery.isLoading || !preference) ? <Skeleton /> : profileQuery.data?.data.phone}
                    </Typography>
                </Stack>
            </Grid>
        </>
    )
};

export default NotificationPreference;