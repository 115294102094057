import { Button, CircularProgress, FormControl, FormHelperText, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { confirmResetPassword } from "adapters/auth";
import PasswordField from "components/inputFields/PasswordField";
import { NoAuthCard } from "components/StyledComponents/NoAuthCompontents";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import useFormValidation from "hooks/useFormValidation";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";


const SetNewPasswordView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const navigate = useNavigate();
    const params = useParams();
    const { validatePassword } = useFormValidation();

    const [form, setFormFields] = React.useState({
        password: "",
        rePassword: ""
    });

    const confirmResetPasswordMutation = useMutation({
        mutationFn: async () => {
            const id = params.id;
            const token = params.token;
            if (token && id) {
                return await confirmResetPassword({
                    "id": id,
                    "token": token,
                    "password": form.password
                });
            }
            throw new Error("Token/id not found in url");
        },
        onSuccess: () => {
            openSnack(localize("set-password.reset-success"), "success");
            navigate("/");
        },
        onError: () => {
            openSnack(localize("set-password.reset-error"), "error");
        }
    });


    const onChange = (field: string, value: string): void => {
        setFormFields({ ...form, [field]: value });
    };

    const onSubmit = async (event: React.SyntheticEvent): Promise<void> => {
        event.preventDefault();
        confirmResetPasswordMutation.mutate();
    };

    const isNotValidTag = (password: string): string => {
        if (password) {
            if (password.length < 8) {
                return "password.not-long-enough";
            } else if (/^[0-9]*$/.test(password)) {
                return "password.only-numbers";
            }
        }
        return "";
    };

    const passwordIsValid = validatePassword(form.password);
    const rePasswordIsValid = validatePassword(form.rePassword);
    const isNotThemSame = (!!form.rePassword && !!form.password) && (form.rePassword !== form.password);
    return (
        <NoAuthCard sx={{ maxWidth: "520px" }}>
            <Typography variant="h4" color="primary" marginBottom="36px">{localize("set-password.title")}</Typography>
            <form noValidate onSubmit={onSubmit}>
                <FormControl fullWidth>
                    <PasswordField
                        variant="outlined"
                        required
                        fullWidth
                        id="password"
                        error={!!form.password.length && !passwordIsValid}
                        label={localize("generics.password")}
                        helperText={!passwordIsValid && localize(isNotValidTag(form.password as string))}
                        onChange={e => onChange("password", e.target.value)}
                        value={form.password}
                    />
                    <PasswordField
                        variant="outlined"
                        required
                        fullWidth
                        id="rePassword"
                        error={!!form.rePassword.length && !rePasswordIsValid}
                        label={localize("generics.re-password")}
                        helperText={!rePasswordIsValid && localize(isNotValidTag(form.rePassword as string))}
                        onChange={e => onChange("rePassword", e.target.value)}
                        value={form.rePassword}
                    />
                    {isNotThemSame && (
                        <FormHelperText error id="password-no-match">
                            {localize("password.does-not-match")}
                        </FormHelperText>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={confirmResetPasswordMutation.isPending || !passwordIsValid || !rePasswordIsValid}
                        endIcon={confirmResetPasswordMutation.isPending && <CircularProgress size={"1rem"} />}
                    >
                        {localize("set-password.change-password")}
                    </Button>
                </FormControl>
            </form>
        </NoAuthCard>
    );
};

export default SetNewPasswordView;