import { Divider, Stack, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { createFireAlarmSystemContactPersons, fetchFireAlarmSystemContactPersons } from "adapters/fireAlarmSystem";
import { LocaleContext } from "contexts/LocaleContext";
import { EBLTextField } from "components/StyledComponents/TextField";
import { SnackBarContext } from "contexts/SnackBarContext";
import { FormButtonWrapper } from "components/StyledComponents/Form";
import useFormValidation from "hooks/useFormValidation";

interface ICreateContacPersonForm {
    alarmSystemId: string;
    onSuccess: () => void;
    buttons: React.ReactNode;
}

interface ContactPersonParams {
    email: string,
    name: string,
    role: string,
    phone: string
}

const CreateContactPersonForm: React.FC<ICreateContacPersonForm> = ({ alarmSystemId, onSuccess, buttons }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const { validateEmail } = useFormValidation();
    const alarSystemContactPersonQuery = useQuery({
        queryKey: [`fire-alarm-contact-persons-${alarmSystemId}`],
        queryFn: async () => await fetchFireAlarmSystemContactPersons(alarmSystemId)
    });
    const [newContact, setNewContact] = React.useState({
        email: "",
        name: "",
        role: "",
        phone: "",
        fire_alarm_system: alarmSystemId
    });
    const theme = useTheme();

    const createContactMutation = useMutation({
        mutationKey: ["create-fire-alarm-contact"],
        mutationFn: createFireAlarmSystemContactPersons,
        onSuccess: () => {
            alarSystemContactPersonQuery.refetch();
            setNewContact({ ...newContact, email: "", phone: "", name: "", role: "" });
            onSuccess();
        },
        onError: () => {
            openSnack(localize("error.generic"), "error");
        }
    });

    const onPopoverFormSubmit = async (event: React.SyntheticEvent): Promise<void> => {
        event.preventDefault();
        createContactMutation.mutate(newContact);
    };

    const getBackgroundColor = (fieldName: keyof ContactPersonParams) => {
        const fieldValue = newContact[fieldName];
        return fieldValue && fieldValue.length > 0 ? '#E8F0FE' : theme.palette.background.default;
    };

    const emailIsInvalid = !!newContact.email.length && !validateEmail(newContact.email);
    const roleIsValid = newContact.role !== undefined && newContact.role.length < 50;
    const nameIsValid = newContact.name !== undefined && newContact.name.length < 100;
    const emailLengthIsValid = newContact.email !== undefined && newContact.email.length < 100;
    const phoneIsValid = newContact.phone !== undefined && newContact.phone.length < 15;


    return (
        <form onSubmit={onPopoverFormSubmit}>
            <Box padding={"1em"} bgcolor={(theme) => theme.palette.background.default}>
                <Stack>
                    <EBLTextField
                        variant="outlined"
                        label={localize("generics.role")}
                        value={newContact.role}
                        onChange={e => setNewContact({ ...newContact, role: e.target.value })}
                        inputProps={{ maxLength: 50 }}
                        helperText={!roleIsValid && localize("text-validation.50")}
                        sx={{ backgroundColor: getBackgroundColor('role') }}
                    />
                    <EBLTextField
                        variant="outlined"
                        label={localize("configuration.name-label")}
                        value={newContact.name}
                        onChange={e => setNewContact({ ...newContact, name: e.target.value })}
                        inputProps={{ maxLength: 100 }}
                        helperText={!nameIsValid && localize("text-validation.100")}
                        sx={{ backgroundColor: getBackgroundColor('name') }}
                    />
                    <EBLTextField
                        variant="outlined"
                        label={localize("generics.email")}
                        value={newContact.email}
                        error={emailIsInvalid}
                        onChange={e => setNewContact({ ...newContact, email: e.target.value })}
                        inputProps={{ maxLength: 100 }}
                        helperText={!emailLengthIsValid && localize("text-validation.100")}
                        sx={{ backgroundColor: getBackgroundColor('email') }}
                    />
                    <EBLTextField
                        variant="outlined"
                        label={localize("generics.phone")}
                        value={newContact.phone}
                        onChange={e => setNewContact({ ...newContact, phone: e.target.value })}
                        inputProps={{ maxLength: 15 }}
                        helperText={!phoneIsValid && localize("text-validation.15")}
                        sx={{ backgroundColor: getBackgroundColor('phone') }}
                    />
                </Stack>
            </Box>
            <Divider />
            <FormButtonWrapper>
                {buttons}
            </FormButtonWrapper>
        </form>
    )

};

export default CreateContactPersonForm;