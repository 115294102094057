import { Button, CircularProgress, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useIsMutating, useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteFireAlarmSystem, disconnectGateway } from "adapters/fireAlarmSystem";
import { BaseDialog, BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import { useHasPermission } from "hooks/useHasPermission";
import { IFireAlarmSystem } from "interfaces/fireAlarmSystem";
import React from "react";
import { useNavigate } from "react-router-dom";
import CreateGatewayCredentialsForm from "./forms/CreateGatewayCredentialsForm";
import CreateGatewayForm from "./forms/CreateGatewayForm";

const AlarmSystemActions: React.FC<{ fireAlarmSystem: IFireAlarmSystem }> = ({ fireAlarmSystem }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission("localoffice");
    const queryClient = useQueryClient();

    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [confirmDisconnectOpen, setConfirmDisconnectOpen] = React.useState(false);
    const [configureGatewayOpen, setConfigureGatewayOpen] = React.useState(false);
    const [configureCredentialsOpen, setConfigureCredentialsOpen] = React.useState(false);
    const isCreatingGateway = useIsMutating({ mutationKey: ["create-gateway"] });

    const activeGateway = fireAlarmSystem.gateways.find(gw => gw.status !== "alarm_system_totally_disconnected");


    const deleteAlarmSystemMutation = useMutation({
        mutationKey: ["delete-fire-alarm-system"],
        mutationFn: deleteFireAlarmSystem,
        onSuccess: () => {
            onCloseDelete();
            openSnack(`${fireAlarmSystem.name} ${localize("configuration.delete-system-success")}`, "success")
            queryClient.invalidateQueries({
                queryKey: ['fireAlarmSystems'],
                refetchType: 'all',
            });
            queryClient.invalidateQueries({ queryKey: ["fire-alarm-system"] })
            navigate("/");
        }
    });

    const disconnectGatewayMutation = useMutation({
        mutationKey: ["disconnect-fire-alarm-system"],
        mutationFn: disconnectGateway,
        onSuccess: () => {
            onCloseDisconnect();
            queryClient.invalidateQueries({ queryKey: ["fire-alarm-system"] })
        }
    });

    const onDisconnectGateway = (): void => {
        if (activeGateway) {
            disconnectGatewayMutation.mutate(activeGateway.id)
        }
    };

    const onCreateGatewaySuccess = (): void => {
        setConfigureGatewayOpen(false);
        openSnack(localize("configuration.configure-system-success"), "success")
        queryClient.invalidateQueries({ queryKey: ["fire-alarm-system"] })
    };

    const onCreateGatewayFailure = (): void => {
        openSnack(localize("configuration.configure-system-error"), "error")
    };

    const onCloseDisconnect = () => setConfirmDisconnectOpen(false);
    const onCloseDelete = () => setConfirmDeleteOpen(false);
    const onCloseGatewayConfiguration = () => setConfigureGatewayOpen(false);
    const onCloseSetupCredentials = () => setConfigureCredentialsOpen(false);

    return (
        <>
            <Stack direction="row" gap="0.5em">
                {(hasPermission("gateway", "change_gateway") && activeGateway) && (
                    <Button
                        variant="contained"
                        onClick={() => setConfirmDisconnectOpen(true)}
                    >
                        {localize("configuration.disconnect-system")}
                    </Button>
                )}
                {(hasPermission("gateway", "add_gateway") && !activeGateway) && (
                    <Button variant="contained" onClick={() => setConfigureGatewayOpen(true)}>
                        {localize("configuration.configure-connection")}
                    </Button>
                )}
                {hasPermission("firealarmsystem", "delete_firealarmsystem") && (
                    <Button variant="contained" onClick={() => setConfirmDeleteOpen(true)}>
                        {localize("configuration.delete-system")}
                    </Button>
                )}
                {(hasPermission("gateway", "change_gateway") && activeGateway) && (
                    <Button variant="contained" color="warning" onClick={() => setConfigureCredentialsOpen(true)}>
                        {localize("configuration.setup-credentials")}
                    </Button>
                )}
            </Stack>
            <BaseDialog open={confirmDisconnectOpen} onClose={onCloseDisconnect}>
                <DialogTitle color="primary">
                    {localize("configuration.disconnect-system")}
                </DialogTitle>
                <DialogContent
                    sx={{
                        xs: {
                            width: "250px"
                        },
                        sm: {
                            width: "370px"
                        }
                    }}>
                    <Typography gutterBottom>
                        {localize("configuration.disconnect-system-content")}
                    </Typography>
                </DialogContent>
                <BaseDialogActions>
                    <Button variant="outlined" onClick={onCloseDisconnect}>
                        {localize("generics.cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={disconnectGatewayMutation.isPending}
                        endIcon={disconnectGatewayMutation.isPending && <CircularProgress size={"1rem"} />}
                        onClick={onDisconnectGateway}>
                        {localize("generics.confirm")}
                    </Button>
                </BaseDialogActions>
            </BaseDialog>
            <BaseDialog open={confirmDeleteOpen} onClose={onCloseDelete}>
                <DialogTitle color="primary">
                    {localize("configuration.delete-system")}
                </DialogTitle>
                <DialogContent
                    sx={{
                        xs: {
                            width: "250px"
                        },
                        sm: {
                            width: "370px"
                        }
                    }}>
                    <Typography gutterBottom>
                        {localize("configuration.delete-system-content")}
                    </Typography>
                </DialogContent>
                <BaseDialogActions>
                    <Button variant="outlined" onClick={onCloseDelete}>
                        {localize("generics.cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={deleteAlarmSystemMutation.isPending}
                        endIcon={deleteAlarmSystemMutation.isPending && <CircularProgress size={"1rem"} />}
                        onClick={() => deleteAlarmSystemMutation.mutate(fireAlarmSystem.id)}>
                        {localize("generics.confirm")}
                    </Button>
                </BaseDialogActions>
            </BaseDialog>
            <BaseDialog open={configureGatewayOpen} onClose={onCloseDelete}>
                <DialogTitle color="primary">
                    {localize("configuration.configure-connection")}
                </DialogTitle>
                <CreateGatewayForm
                    onSuccess={onCreateGatewaySuccess}
                    onError={onCreateGatewayFailure}
                    alarmSystemId={fireAlarmSystem.id}
                    buttons={[
                        <Button variant="outlined" onClick={onCloseGatewayConfiguration}>
                            {localize("generics.cancel")}
                        </Button>,
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!!isCreatingGateway}
                            endIcon={!!isCreatingGateway && <CircularProgress size={"1rem"} />}

                        >
                            {localize("generics.confirm")}
                        </Button>
                    ]
                    }
                />
            </BaseDialog>
            {activeGateway && (
                <BaseDialog open={configureCredentialsOpen} onClose={onCloseSetupCredentials}>
                    <DialogTitle color="primary">
                        {localize("configuration.setup-credentials")}
                    </DialogTitle>
                    <CreateGatewayCredentialsForm
                        onClose={onCloseSetupCredentials}
                        gatewayId={activeGateway?.id}
                    />
                </BaseDialog>
            )}
        </>
    )
};

export default AlarmSystemActions;