import { Button, DialogContent, DialogTitle } from "@mui/material";
import { BaseDialogActions } from "components/StyledComponents/Dialog";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";


interface IOrganizationDialogContent {
    onClose: () => void;
    title: React.ReactNode;
    children: React.ReactNode;
}

const OrganizationDialogContent: React.FC<IOrganizationDialogContent> = ({onClose, title, children}) => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <>
            <DialogTitle color="primary">
                {title}
            </DialogTitle>
            <DialogContent sx={{maxHeight: "300px"}}> 
                {children}
            </DialogContent>
            <BaseDialogActions>
                <Button variant="outlined" onClick={onClose}>
                    {localize("generics.close")}
                </Button>
            </BaseDialogActions>
        </>
    )
};

export default OrganizationDialogContent;