import {
    Typography
} from "@mui/material";
import {
    SwapHoriz
} from '@mui/icons-material';
import { StatusAccordion } from "components/StatusAccordion";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import { IStatusAccordionProps } from "interfaces/statusAccordionProps";


const CommunicationErrorStatusAccordion: React.FC<IStatusAccordionProps> = ({ alarmSystem, formatDate }) => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <StatusAccordion
            icon={<SwapHoriz />}
            numberOfDeviations={alarmSystem?.deviations.communication_error.length}
            loading={!!!alarmSystem}
            title={localize("status.communication-error")}
        >
            {alarmSystem?.deviations.communication_error.map((error, index) => (
                <Typography ml="2em" key={index}>
                    {error.payload.deviation_key === 9999999 ? localize("status.communication-error-gw-cloud-since") :
                        localize("status.communication-error-gw-control-since")}  {formatDate(error.datetime)}
                </Typography>
            ))}
        </StatusAccordion>
    );
};

export default CommunicationErrorStatusAccordion;