import { Send } from "@mui/icons-material";
import {
    Button,
    Divider,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
} from "@mui/material";
import { keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createInvite, fetchLocalOffice, fetchRoles } from "adapters/userManagement";
import { LocaleContext } from "contexts/LocaleContext";
import { OrganizationContext } from "contexts/OrganizationContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import useFormValidation from "hooks/useFormValidation";
import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";


const CreateInvitationForm: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { currentOrganizationId } = React.useContext(OrganizationContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const { validateEmail } = useFormValidation();

    const [newUserFields, setNewUserFields] = React.useState({
        email: "",
        roleId: "",
        localOfficeId: ""
    });

    const queryClient = useQueryClient();
    const roleQuery = useQuery({
        queryKey: ["roles", currentOrganizationId],
        queryFn: fetchRoles
    });

    const localOfficeQuery = useInfiniteQuery({
        queryKey: ["localOffice", newUserFields.roleId],
        queryFn: async ({ pageParam }) => {
            const params = { 'page': pageParam, "can_invite_for_role": newUserFields.roleId, org_id: currentOrganizationId };
            const response = await fetchLocalOffice({ params });
            return {
                data: response.data.results,
                nextPage: response.data.next ? pageParam + 1 : undefined,
            };
        },
        enabled: !!newUserFields.roleId,
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    const { inView } = useInView();

    React.useEffect(() => {
        if (inView) {
            localOfficeQuery.fetchNextPage()
        }
    }, [inView, localOfficeQuery]);


    const inviteMutation = useMutation({
        mutationFn: createInvite,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["invites"] });
            queryClient.invalidateQueries({ queryKey: ["users"] });

            if (data && data.data && data.data) {
                let msg = (data.data).toString()
                console.log((data.data).toString())
                openSnack(msg, "success")
            }
        },
        onError: () => {
            openSnack(localize("error.generic"), "error");
        }
    });

    const onSubmit = (event: React.SyntheticEvent): void => {
        event.preventDefault();
        inviteMutation.mutate({
            email: newUserFields.email,
            role: newUserFields.roleId,
            context_object_id: newUserFields.localOfficeId || currentOrganizationId || "",
            model: newUserFields.localOfficeId ? "organization.LocalOffice" : "organization.Organization"
        });
    };

    const validEmail = validateEmail(newUserFields.email);
    const checkSuperUserRole = roleQuery.data?.data.results.some((role) => role.id === newUserFields.roleId && role.name === "super_user_customer");
    const canSubmit = validEmail && !!newUserFields.roleId && (checkSuperUserRole ? true : !!newUserFields.localOfficeId);
    const emailFormError = !!newUserFields.email && !validEmail;

    const lastMenuItemRef = useRef<HTMLLIElement | null>(null);

    const setLastMenuItemRef = (node: HTMLLIElement | null) => {
        if (node) {
            lastMenuItemRef.current = node;
            // Check if the last menu item is visible and trigger fetchNextPage
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        localOfficeQuery.fetchNextPage();
                    }
                },
                { threshold: 1 }
            );

            observer.observe(node);
        }
    };


    return (
        <Stack component={"form"} onSubmit={onSubmit} mt="2em" spacing="2em">
            <FormControl variant="outlined" error={emailFormError}>
                <InputLabel sx={{ left: "-14px", top: "-0.5rem" }} shrink htmlFor="email-input">
                    {localize("generics.email")}
                </InputLabel>
                <OutlinedInput
                    id="email-input"
                    size="small"
                    placeholder={localize("generics.email-placeholder")}
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => setNewUserFields({
                        ...newUserFields,
                        email: e.target.value
                    })} />
                {emailFormError && (
                    <FormHelperText id="emailInput">{localize("generics.invalid-email")}</FormHelperText>
                )}
            </FormControl>
            <FormControl variant="outlined">
                <InputLabel sx={{ left: "-14px", top: "-0.5rem" }} shrink htmlFor="email-input">
                    {localize("generics.role")}
                </InputLabel>
                <Select
                    disabled={!validEmail}
                    displayEmpty
                    onChange={(e) => setNewUserFields({
                        ...newUserFields,
                        roleId: e.target.value,
                        localOfficeId: ""
                    })}
                    value={newUserFields.roleId}
                    input={<OutlinedInput size="small" />}
                >
                    <MenuItem key={1} value="" disabled>
                        <em>{localize("user-management.role-placeholder")}</em>
                    </MenuItem>
                    {roleQuery.data?.data.results.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                            {role.display_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="outlined">
                <InputLabel sx={{ left: "-14px", top: "-0.5rem" }} shrink htmlFor="email-input">
                    {localize("generics.local-office")}
                </InputLabel>
                <Select
                    disabled={checkSuperUserRole || !newUserFields.roleId}
                    displayEmpty
                    onChange={(e) => setNewUserFields({
                        ...newUserFields,
                        localOfficeId: e.target.value
                    })}
                    value={newUserFields.localOfficeId}
                    input={<OutlinedInput size="small" />}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                paddingRight: "5px",
                                maxHeight: '20vh',
                            }
                        },
                    }}

                >
                    <MenuItem key={"1"} value="" disabled>
                        <em>{localize("generics.local-office-placeholder")}</em>
                    </MenuItem >
                    {localOfficeQuery.data?.pages.map((group) => group.data.map((office, j) => (
                        <MenuItem
                            ref={j === group.data.length - 1 ? setLastMenuItemRef : null}
                            key={office.id}
                            value={office.id}
                        >
                            {office.name}
                        </MenuItem>

                    )))}
                </Select>
            </FormControl>
            <Button
                sx={{ alignSelf: { md: "flex-end" } }}
                variant="contained"
                endIcon={<Send />}
                type="submit"
                disabled={roleQuery.status !== "success" || !canSubmit}
            >
                {localize("user-management.send")}
            </Button>
            <Divider />
        </Stack >
    )

};

export default CreateInvitationForm;