import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { IClientConfiguration, fetchClientConfiguration } from "adapters/auth";

const useClientConfig = (): UseQueryResult<AxiosResponse<IClientConfiguration, any>, unknown> =>  {
    const clientConfig = useQuery({
        queryKey: ["clientConfiguration"],
        queryFn: fetchClientConfiguration,
        staleTime: 4000
    });

    return clientConfig;
};

export default useClientConfig;