import { Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { acceptInvitation } from "adapters/auth";
import { AxiosError } from "axios";
import SetupTOTP from "components/authenticate/SetupTOTP";
import VerifyTOTPForm from "components/authenticate/VerifyOtpForm";
import PasswordField from "components/inputFields/PasswordField";
import { NoAuthCard } from "components/StyledComponents/NoAuthCompontents";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import useAuthenticate from "hooks/useAuthenticate";
import useFormValidation from "hooks/useFormValidation";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getFieldErrorMessage } from "utils/getFieldErrorMessage";

interface AcceptInvitationParams {
    email: string,
    first_name: string,
    last_name: string
}


const AcceptInvitation: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const navigate = useNavigate();
    const params = useParams();
    const { authenticateOTPUser, isLoading } = useAuthenticate();
    let [searchParams, setSearchParams] = useSearchParams();
    const {
        validatePassword,
        validateEmail,
        validatePhoneNumber,
        passwordIsNotValidTag
    } = useFormValidation();
    const [configUrl, setConfigUrl] = React.useState("");

    const [form, setFormFields] = React.useState({
        email: "",
        password: "",
        rePassword: "",
        first_name: "",
        last_name: "",
        phone: ""
    });
    const theme = useTheme();

    React.useEffect(() => {
        const isConfirmed = searchParams.get("confirmed");
        if (isConfirmed && !form.email) {
            navigate("/");
        }
    }, [form.email, navigate, searchParams]);

    const onInvitationError = (e: AxiosError): void => {
        const fieldError = getFieldErrorMessage(e)
        if (fieldError) {
            openSnack(`${fieldError.message}`, "error");
        }
        else {
            openSnack(localize(`invitation.creation-error`) + `: ${e.response?.data}`, "error");
        }
    }

    const acceptInvitationMutation = useMutation({
        mutationFn: async () => {
            const token = params.token;
            if (token) {
                await new Promise(resolve => setTimeout(resolve, 1000))
                return await acceptInvitation(token, form)
            }
            throw new Error("Token not found in url");
        },
        onSuccess: (res) => {
            const configUrl = res.data.config_url;
            setConfigUrl(configUrl);
            openSnack(localize("invitation.account-created"), "success");
            setSearchParams({ "confirmed": "true" })
        },
        onError: (error: AxiosError) => {
            onInvitationError(error)
        }
    });


    const onChange = (field: string, value: string): void => {
        setFormFields({ ...form, [field]: value });
    };

    const onSubmit = async (event: React.SyntheticEvent): Promise<void> => {
        event.preventDefault();
        acceptInvitationMutation.mutate();
    };

    const onCancel = () => navigate("/");

    const renderInvitaiton = (): React.ReactNode => {
        if (configUrl) {
            return (
                <SetupTOTP
                    onCancel={onCancel}
                    configUrl={configUrl}
                    verifyForm={
                        <VerifyTOTPForm
                            onCancel={onCancel}
                            onVerify={(token) => authenticateOTPUser(form.email, form.password, token)}
                            loading={isLoading}
                        />
                    }
                />
            );
        }
        const emailIsValid = validateEmail(form.email);
        const passwordIsValid = validatePassword(form.password);
        const rePasswordIsValid = validatePassword(form.rePassword) && form.rePassword === form.password;
        const phoneIsValid = validatePhoneNumber(form.phone);
        const emailLengthIsValid = form.email !== undefined && form.email.length < 100;
        const firstNameIsValid = form.first_name !== undefined && form.first_name.length < 100;
        const lastNameIsValid = form.last_name !== undefined && form.last_name.length < 100;

        const getBackgroundColor = (fieldName: keyof AcceptInvitationParams) => {
            const fieldValue = form[fieldName];
            return fieldValue && fieldValue.length > 0 ? '#E8F0FE' : theme.palette.background.default;
        };

        return (
            <>
                <Typography variant="h4" color="primary" mt={'1rem'}>{localize("invitation.title")}</Typography>
                <form noValidate onSubmit={onSubmit}>
                    <EBLTextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label={localize("generics.email")}
                        autoFocus
                        inputProps={{ maxLength: 100 }}
                        error={!emailIsValid}
                        helperText={!emailLengthIsValid && localize("text-validation.100")}
                        value={form.email}
                        onChange={e => onChange("email", e.target.value)}
                        sx={{ backgroundColor: getBackgroundColor('email') }}
                    />
                    <EBLTextField
                        variant="outlined"
                        required
                        fullWidth
                        label={localize("generics.first-name")}
                        type="text"
                        id="name"
                        value={form.first_name}
                        onChange={e => onChange("first_name", e.target.value)}
                        inputProps={{ maxLength: 100 }}
                        helperText={!firstNameIsValid && localize("text-validation.100")}
                        sx={{ backgroundColor: getBackgroundColor('first_name') }}
                    />
                    <EBLTextField
                        variant="outlined"
                        required
                        fullWidth
                        label={localize("generics.last-name")}
                        type="text"
                        id="surname"
                        value={form.last_name}
                        onChange={e => onChange("last_name", e.target.value)}
                        inputProps={{ maxLength: 100 }}
                        helperText={!lastNameIsValid && localize("text-validation.100")}
                        sx={{ backgroundColor: getBackgroundColor('last_name') }}
                    />
                    <EBLTextField
                        variant="outlined"
                        required
                        fullWidth
                        label={localize("generics.phone")}
                        type="phone"
                        id="phone"
                        value={form.phone}
                        helperText={localize("generics.phone-helper")}
                        error={!!form.phone && !phoneIsValid}
                        onChange={e => onChange("phone", e.target.value)}
                        inputProps={{ maxLength: 17 }}
                    />
                    <PasswordField
                        variant="outlined"
                        required
                        fullWidth
                        id="password"
                        error={!!form.password && !passwordIsValid}
                        label={localize("generics.password")}
                        helperText={!passwordIsValid && localize(passwordIsNotValidTag(form.password as string))}
                        onChange={e => onChange("password", e.target.value)}
                        value={form.password}
                    />
                    <PasswordField
                        variant="outlined"
                        required
                        fullWidth
                        id="rePassword"
                        error={!!form.rePassword && !rePasswordIsValid}
                        label={localize("generics.re-password")}
                        helperText={!rePasswordIsValid && localize(passwordIsNotValidTag(form.rePassword as string, form.password as string))}
                        onChange={e => onChange("rePassword", e.target.value)}
                        value={form.rePassword}
                    />
                    <Button
                        type="submit"
                        sx={{ float: "right", marginTop: "28px" }}
                        variant="contained"
                        color="primary"
                        disabled={acceptInvitationMutation.isPending || !emailIsValid || !passwordIsValid || !rePasswordIsValid}
                        endIcon={acceptInvitationMutation.isPending && <CircularProgress size={"1rem"} />}
                    >
                        {localize("invitation.create-account")}
                    </Button>
                </form>
            </>
        )
    };
    return (
        <NoAuthCard sx={{ maxWidth: "520px" }}>
            {renderInvitaiton()}
        </NoAuthCard>
    );
};

export default AcceptInvitation;