import { Send } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Divider,
    MenuItem,
    Stack
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createOrganization, fetchRegions } from "adapters/organizations";
import { createInvite, fetchRoles } from "adapters/userManagement";
import { EBLTextField } from "components/StyledComponents/TextField";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import { SnackBarContext } from "contexts/SnackBarContext";
import useFormValidation from "hooks/useFormValidation";


const CreateOrganizationForm: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const queryClient = useQueryClient();
    const [selectedRole, setRole] = React.useState("");
    const [selectedRegion, setRegion] = React.useState("");
    const { openSnack } = React.useContext(SnackBarContext);
    const { validateEmail } = useFormValidation();

    const onCreateOrganizationFailure = (): void => {
        openSnack(localize("organization-management.organization-already-exists"), "error")
    };
    const [newOrganization, setNewOrganizationFields] = React.useState({
        name: "",
        orgNumber: "",
        email: ""
    });


    const regionsQuery = useQuery({
        queryKey: ["regions"],
        queryFn: fetchRegions
    });

    React.useEffect(() => {
        if (regionsQuery.data) {

            if (regionsQuery.data.data.results.length > 0) {
                setRegion(regionsQuery.data.data.results[0].id);
            }
        }
    }, [regionsQuery.data, regionsQuery?.data?.data.results])

    const roleQuery = useQuery({
        queryKey: ["roles", "organization"],
        queryFn: async () => {
            const params = { context_content_type: 'organization' };
            const queryResult = await fetchRoles({ params: params })

            if (queryResult.data.results.length > 0) {
                setRole(queryResult.data.results[0].id)
            }
            return queryResult
        }
    });

    const inviteMutation = useMutation({
        mutationFn: createInvite,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["invites"] });
            queryClient.invalidateQueries({ queryKey: ["users"] });

            if (data && data.data && data.data) {
                let msg = (data.data).toString()
                console.log((data.data).toString())
                openSnack(msg, "success")
            }
        },
        onError: () => {
            openSnack(localize("error.generic"), "error");
        }
    });

    const organizationMutation = useMutation({
        mutationFn: createOrganization,
        onSuccess: (newOrgResponse) => {
            queryClient.invalidateQueries({ queryKey: ["organizations-pending"] });
            inviteMutation.mutate({
                email: newOrganization.email,
                role: selectedRole,
                context_object_id: newOrgResponse.data.id,
                model: "organization.Organization"
            });
        },
        onError: onCreateOrganizationFailure
    });

    const onSubmit = (event: React.SyntheticEvent): void => {
        event.preventDefault();
        organizationMutation.mutate({
            name: newOrganization.name,
            org_nr: newOrganization.orgNumber,
            region: selectedRegion
        });
    };

    const validEmail = validateEmail(newOrganization.email);
    const nameIsValid = newOrganization.name !== undefined && newOrganization.name.length < 50;
    const organizationNumberIsValid = newOrganization.orgNumber !== undefined && newOrganization.orgNumber.length < 30;

    const canSubmit = validEmail;
    const emailFormError = !!newOrganization.email && !validEmail;

    return (
        <Stack component={"form"} onSubmit={onSubmit} mt="2em" spacing="2em">
            <EBLTextField
                variant="outlined"
                label={localize("organization-management.name")}
                placeholder={localize("organization-management.name-placeholder")}
                id="name"
                value={newOrganization.name}
                inputProps={{ maxLength: 50 }}
                helperText={!nameIsValid && localize("text-validation.50")}
                onChange={e => setNewOrganizationFields({ ...newOrganization, name: e.target.value })}
            />
            <EBLTextField
                variant="outlined"
                label={localize("organization-management.organization-number")}
                placeholder={localize("organization-management.organization-number-placeholder")}
                id="orgNumber"
                value={newOrganization.orgNumber}
                onChange={e => setNewOrganizationFields({ ...newOrganization, orgNumber: e.target.value })}
                inputProps={{ maxLength: 30 }}
                helperText={!organizationNumberIsValid && localize("text-validation.30")}
            />
            {(regionsQuery.data) && (
                <EBLTextField
                    variant="outlined"
                    label={localize("organization-management.region")}
                    id="name"
                    select
                    value={selectedRegion}
                    onChange={e => setRegion(e.target.value)}
                >
                    {regionsQuery.data?.data.results.map(region => (
                        <MenuItem key={region.id} value={region.id}>
                            {region.name}
                        </MenuItem>
                    ))}
                </EBLTextField>
            )}
            {(roleQuery.data) && (
                <EBLTextField
                    variant="outlined"
                    label={localize("generics.role")}
                    id="role"
                    select
                    value={selectedRole}
                    onChange={e => setRole(e.target.value)}
                >
                    {roleQuery.data?.data.results.map(role => (
                        <MenuItem key={role.id} value={role.id}>
                            {role.display_name}
                        </MenuItem>
                    ))}
                </EBLTextField>
            )}
            <EBLTextField
                variant="outlined"
                label={localize("generics.email")}
                placeholder={localize("generics.email-placeholder")}
                id="email"
                error={emailFormError}
                value={newOrganization.email}
                inputProps={{ maxLength: 100 }}
                helperText={emailFormError && localize("generics.invalid-email")}
                onChange={e => setNewOrganizationFields({ ...newOrganization, email: e.target.value })}
            />
            <Button
                sx={{ alignSelf: { md: "flex-end" } }}
                variant="contained"
                endIcon={!!organizationMutation.isPending ? <CircularProgress size={"20px"} color="secondary" /> : <Send />}
                type="submit"
                disabled={!canSubmit}
            >
                {localize("organization-management.send")}
            </Button>
            <Divider />
        </Stack>
    )

};

export default CreateOrganizationForm;