import { LocaleContext } from "contexts/LocaleContext"
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import React, { useMemo } from "react"
import { Box, Skeleton, styled, Tab, Tabs, Theme, Typography, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";


const TabsWithBorder = styled(Tabs)
    (({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.secondary.main}`
    })
    );


const MobileTabs: React.FC<{ children: React.ReactNode, routes: { label: string, route: string }[] }> = ({ children, routes }) => {
    const { localize } = React.useContext(LocaleContext)
    const { alarmSystem, isLoading } = useGetAlarmSystem();
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'));
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const selectedRoute = useMemo(() => {
        if (pathname) {
            return pathname.split("/")[2];
        }
        return "null";
    }, [pathname]);


    const onNavigate = (event: React.SyntheticEvent, route: string): void => {
        const alarmSystemId = sessionStorage.getItem("alarm_system_id");
        const localOfficeId = sessionStorage.getItem("local_office_id");

        navigate({
            pathname: `/${localOfficeId}/${route}/${alarmSystemId}`
        });
    };


    if (!isSm) {
        return <>{children}</>;
    }

    return (
        <>
            <Typography variant="h6" sx={{
                color: (theme) => theme.palette.primary.main,
                display: { xs: "inherit", sm: "inherit" }
            }}>
                {isLoading ? <Skeleton width="10rem" /> : `${alarmSystem?.name}`}
            </Typography>

            <Box>
                <TabsWithBorder variant="scrollable"
                    value={selectedRoute} onChange={onNavigate} >
                    {routes.map((route, index) => (
                        <Tab key={index} value={route.route} label={localize(route.label)} />
                    ))}
                </TabsWithBorder>

                <Box>
                    {children}
                </Box>
            </Box>

        </>
    );
};

export default MobileTabs;