import { Box, styled } from "@mui/material";


export const StyledEBLCloudLogoBlack = styled(Box)
    (({theme}) => ({
        background: theme.palette.background.default,
        width: "99.8%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    })
    );


export const StyledOrganizationGardient = styled("div")
    (({theme}) => ({
        background: 'linear-gradient(90deg, rgba(0,59,104,1) 0%, rgba(106,170,228,1) 100%)',
        color: theme.palette.background.default,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        padding: '.5rem 0'
    })
    );