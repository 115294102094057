import axios, { AxiosError } from "axios";

export const getFieldErrorMessage = (error: AxiosError): { message: string } | null => {
    if (axios.isAxiosError(error)) {
        const responseData = error.response?.data as Record<string, string[]>;
        if (responseData) {
            // Check if the response has any error fields
            for (const key in responseData) {
                if (Array.isArray(responseData[key]) && responseData[key].length > 0) {
                    // If the field has errors, return the message
                    return { message: responseData[key][0] };
                }
            }
        }
    }
    return null;
};